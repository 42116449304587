import { IonButton, useIonToast } from "@ionic/react";
import { alertCircleOutline, bookmark, bookmarkOutline } from "ionicons/icons";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { UserType } from "../../../models/usertype";
import UserService from "../../../services/user.service";
import useAuthService from "../../../hooks/useAuthService";
import IDXButtonIcon from "../IDXButtonIcon/IDXButtonIcon";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { useAnalytics } from '../../../AppAnalyticsProvider';

interface ContainerProps {
    isAuthed: boolean;
    publicId: string;
    trickId: string;
    userType: string;
}

const TrickSaveComponent: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [trickSaved, setTrickSaved] = React.useState(false);
    const [present] = useIonToast();
    const history = useHistory();
    const { login } = useAuthService();
    const { trackEvent } = useAnalytics();


    useEffect(() => {
    }, [props.isAuthed, props.publicId, props.trickId]);

    useEffect(() => {
        if (props.isAuthed && props.userType == UserType.USER) {
            // doing this check for security reasons so that we don't allow others to edit others tricks
            if (props.publicId != "" && props.trickId != "" && props.trickId != undefined && props.publicId != undefined) {
                getTrickSaved(props.trickId, props.publicId);
            }
        }
    }, [props.isAuthed, props.publicId, props.trickId]);

    const presentToastLoginHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            position: 'top',
            icon: alertCircleOutline,
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    const presentToastInfoHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            position: 'top',
            icon: alertCircleOutline,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel'
                }
            ]
        });
    };


    const getTrickSaved = async (trick_id: string, user_id: string) => {
        const response = await UserService.getUserTrickSaved(trick_id, user_id);
        setTrickSaved(response);
    }

    const onTrickSave = async () => {
        if (props.isAuthed) {
            await Haptics.impact({ style: ImpactStyle.Light });
            const newSaveState = !trickSaved;
            setTrickSaved(newSaveState);

            trackEvent('trick_save_toggle', {
                trick_id: props.trickId,
                user_id: props.publicId,
                action: newSaveState ? 'save' : 'unsave'
            });

            await UserService.saveTrick(props.trickId, props.publicId, newSaveState);
            await getTrickSaved(props.trickId, props.publicId);
        } else {
            trackEvent('trick_save_auth_prompt', {
                trick_id: props.trickId
            });
            await presentToastLoginHandler("You must be logged in to save a trick");
        }
    }

    return (
        <>
            <IonButton fill="clear" color="dark" size="small" onClick={() => onTrickSave()}>
                <IDXButtonIcon size="text-2xl" icon={trickSaved ? bookmark : bookmarkOutline} />
            </IonButton>
        </>
    );
};

export default TrickSaveComponent;