import React, { useCallback, useEffect, useState, useRef, useContext, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import {
  isYouTubeProvider,
  MediaProviderAdapter,
  MediaPlayer,
  MediaProvider,
  Track,
  Poster,
  type MediaPlayerInstance,
} from '@vidstack/react';
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { decimalToAspectRatio } from "../../../services/utils";
import "./AutoVideoPlayer.css";
import CustomSettingsMenu from "../CustomSettingsMenu/CustomSettingsMenu";
import { useAppState } from "../../../AppListenerProvider";
import { VideoProvider } from "../VideoContextManager/VideoContextManager";
import FullscreenPlayControl from "../FullscreenPlayControl/FullscreenPlayControl";
import YoutubePlayerControl from "../YoutubePlayerControl/YoutubePlayerControl";
import CustomMuteButton from "../CustomMuteButton/CustomMuteButton";
import { AppContext } from "../../../AppStateProvider";
import { useVolume } from "../../../VolumeProvider";
import { useAnalytics } from '../../../AppAnalyticsProvider';

interface ContainerProps {
  src: string;
  thumbnail?: string;
  subtitles?: string;
  isCapacitor: boolean;
  isHorizontal: boolean;
  isExternal: boolean;
  isYoutube: boolean;
  isVideoRestricted: boolean;
  isScrolling: boolean;
  scrollSpeed: number;
  aspectRatio: number;
}

const getThresholdForVideo = () => {
  // ... your existing logic based on clientWidth ...
  if (document.documentElement.clientWidth < 400) return .9;
  if (document.documentElement.clientWidth < 640) return .925;
  if (document.documentElement.clientWidth < 768) return .97;
  if (document.documentElement.clientWidth < 1024) return .95;
  if (document.documentElement.clientWidth < 1280) return .925;
  if (document.documentElement.clientWidth < 1536) return .825;
  if (document.documentElement.clientWidth >= 1536) return .85;
  return .85;
};

const playingScrollSpeedMax = 0.95; // pixels per millisecond
const PRELOAD_THRESHOLD = 0.05; // NEW: Threshold to start preloading

const AutoVideoPlayer: React.FC<ContainerProps> = (props) => {
  const { isNative, platform } = useAppState();
  const { state, dispatch } = useContext(AppContext);
  const { trackEvent } = useAnalytics();

  const [src, setSrc] = useState<string>(props.src);

  const [playerWidth, setPlayerWidth] = useState("100%");
  const [isReady, setIsReady] = useState(false);
  const [shouldBePlaying, setShouldBePlaying] = useState(false);
  const playerRef = useRef<MediaPlayerInstance>(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const aspectRatioFraction = decimalToAspectRatio(props.aspectRatio);
  const { isMuted, setMuted } = useVolume();
  const threshold = useMemo(() => getThresholdForVideo(), []); // Memoize initial calculation

  // Add these state variables

  // Primary observer with your existing threshold for detecting when video should play
  const { ref: inViewRef, inView: mainInView } = useInView({
    threshold: threshold, // Your high threshold (~85%)
  });

  // Secondary observer with threshold 0 to detect when element is completely out of view
  const { ref: completelyOutOfViewRef, inView: isAnyPartVisible } = useInView({
    threshold: 0, // Will be true as long as any part of the element is visible
  });

  // Combine your refs to apply both to the same element
  const combinedRef = useCallback(
    (node: any) => {
      inViewRef(node);
      completelyOutOfViewRef(node);
    },
    [inViewRef, completelyOutOfViewRef]
  );

  // Determine if video should be played based on the main visibility threshold
  const shouldPlay = useMemo(() => {
    return mainInView && (!props.isScrolling || props.scrollSpeed < playingScrollSpeedMax);
  }, [mainInView, props.isScrolling, props.scrollSpeed, playingScrollSpeedMax]);


  const loadingClass = props.isExternal ? "" : !isReady ?
    "animate-[pulse_4.5s_ease-in-out_infinite] opacity-50" :
    "opacity-100 transition-opacity duration-300";

  // Start loading video when it's preloaded
  useEffect(() => {
    if (shouldPlay && playerRef.current) {
      console.log("Video in preload range, starting to load");
      setSrc(props.src);
      playerRef.current?.startLoading();
    } else if (!isAnyPartVisible && playerRef.current) {
      // Unload when completely out of view to save resources
      console.log("Video completely out of view, unloading");
      playerRef.current.pause();
      setSrc("");
    }
  }, [mainInView, isAnyPartVisible, props.isScrolling, props.scrollSpeed]);

  // Update playing state based on visibility
  useEffect(() => {
    setShouldBePlaying(shouldPlay);

    if (shouldPlay && isReady) {
      const handleAnyInteraction = (e: Event) => {

        const mediaPlayerElement = playerRef.current?.el;
        if (mediaPlayerElement?.contains(e.target as Node)) {
          console.log("Interaction detected within video player, ignoring");
          return;
        }

        if (playerRef.current && playerRef.current.paused) {
          console.log("User interaction detected, attempting to play video");

          playerRef.current.play()
            .then(() => {
              console.log("Successfully played video after user interaction");
              // Only remove listeners if we succeed
              document.removeEventListener('click', handleAnyInteraction);
              document.removeEventListener('touchstart', handleAnyInteraction);
              document.removeEventListener('scroll', handleAnyInteraction);
            })
            .catch(e => {
              console.error("Play failed even after user interaction:", e);
              // Keep listeners if play fails
            });
        }
      };

      // Add listeners proactively for iOS
      document.addEventListener('click', handleAnyInteraction);
      document.addEventListener('touchstart', handleAnyInteraction);
      document.addEventListener('scroll', handleAnyInteraction, { passive: true });

      // Also try to play immediately
      if (playerRef.current?.paused) {
        console.log("Should be playing but paused, attempting to play");
        playerRef.current.play().catch(err => {
          console.log("Auto-play attempt failed:", err);
        });
      }

      // Return cleanup function
      return () => {
        document.removeEventListener('click', handleAnyInteraction);
        document.removeEventListener('touchstart', handleAnyInteraction);
        document.removeEventListener('scroll', handleAnyInteraction);
      };
    }
  }, [shouldPlay, isReady]);

  const updatePlayerWidth = useCallback(() => {
    const width = props.isHorizontal || props.isExternal ? "100%" : getWidthForVerticalVideo();
    setPlayerWidth(width);
  }, [props.isHorizontal, props.isExternal]);

  useEffect(() => {
    updatePlayerWidth();
    window.addEventListener("resize", updatePlayerWidth);
    return () => {
      window.removeEventListener("resize", updatePlayerWidth);
    };
  }, [updatePlayerWidth]);

  const getWidthForVerticalVideo = () => {
    const width = document.documentElement.clientWidth;
    if (width < 400) return "65%";
    if (width < 640) return "50%";
    if (width < 768) return "55%";
    if (width < 1024) return "50%";
    if (width < 1280) return "45%";
    if (width < 1536) return "40%";
    return "40%";
  }

  // When video is ready to play
  const handleCanPlay = () => {
    console.log("Video can play now");
    setIsReady(true);
  };

  const handleError = (event: any) => {
    console.error("Vidstack Player Error:", event.detail);
  }

  if (!props.src || threshold === undefined) {
    return null;
  }

  const handleMuteClick = () => {
    setMuted(!isMuted);
  };

  function onProviderChange(provider: MediaProviderAdapter | null) {
    if (!provider) {
      return;
    }

    if (isYouTubeProvider(provider)) {
      provider.referrerPolicy = 'no-referrer-when-downgrade';
      provider.iframe.referrerPolicy = 'no-referrer-when-downgrade';
    }
  }

  const handleMediaClick = () => {
    console.log("Media clicked");
    if (!hasInteracted) {
      setHasInteracted(true);
    }
  }

  return (
    <div className={"flex flex-col items-center"} ref={combinedRef}>
      <VideoProvider playerRef={playerRef}>
        <MediaPlayer
          ref={playerRef}
          src={src}
          poster={props.thumbnail}
          aspectRatio={aspectRatioFraction}
          playsInline={true}
          playsinline
          {...(platform == "android" && { crossOrigin: "" })}
          loop
          paused={!shouldBePlaying}
          onCanPlay={handleCanPlay}
          onError={handleError}
          load="custom"
          posterLoad="eager"
          style={{ width: playerWidth }}
          onProviderChange={onProviderChange}
          className={loadingClass}
          onClick={handleMediaClick}
          controlsDelay={hasInteracted ? 1250 : 0}
          muted={isMuted}
        >
          <MediaProvider>
            {props.thumbnail && !props.isExternal && (
              <Poster
                className={"absolute inset-0 block h-full w-full rounded-md opacity-0 transition-opacity data-[visible]:opacity-100 [&>img]:h-full [&>img]:w-full [&>img]:object-cover"}
                src={props.thumbnail}
              />
            )}
          </MediaProvider>
          <FullscreenPlayControl hasInteracted={hasInteracted} />
          <YoutubePlayerControl src={props.src} thumbnail={props.thumbnail} isExternal={props.isExternal} isNative={isNative} />
          <DefaultVideoLayout
            smallLayoutWhen={props.isExternal ? false : true}
            icons={defaultLayoutIcons}
            slots={{
              smallLayout: {
                playButton: null,
                airPlayButton: <CustomSettingsMenu hasInteracted={hasInteracted} />,
                bufferingIndicator: null,
                muteButton: props.isExternal ? <CustomMuteButton hasInteracted={hasInteracted} mutedVolume={isMuted} handleMuteButtonClick={handleMuteClick} /> : null,
                settingsMenu: props.isExternal ? null : <CustomMuteButton hasInteracted={hasInteracted} mutedVolume={isMuted} handleMuteButtonClick={handleMuteClick} />,
              },
              largeLayout: {
                settingsMenu: <CustomSettingsMenu hasInteracted={hasInteracted} />,
                bufferingIndicator: null,
                muteButton: <CustomMuteButton hasInteracted={hasInteracted} mutedVolume={isMuted} handleMuteButtonClick={handleMuteClick} />,
              },
            }}
          />
        </MediaPlayer>
      </VideoProvider>
    </div>
  );
};

export default AutoVideoPlayer;