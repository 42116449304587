import React from "react";
import { Browser } from '@capacitor/browser';
import { useAppState } from "../../../AppListenerProvider";
import { chatbubbleEllipsesOutline } from "ionicons/icons";
import { useIonToast } from "@ionic/react";
import { AppLauncher } from "@capacitor/app-launcher";

const WelcomeHelp: React.FC = () => {
    const { isNative } = useAppState();
    const [present] = useIonToast();

    const presentToast = async (message: string, duration: number, icon: string) => {
        await present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    const handleTextSupport = async () => {
        const supportNumber = "+18556292475";
        const message = `Hey Ecliptic Support, I can't login. Here is my Ecliptic username and other details: `;

        if (isNative) {
            const url = `sms:${supportNumber}`;
            try {
                await AppLauncher.openUrl({ url });
            } catch (browserError) {
                console.error('Error opening browser:', browserError);
            }
        } else {
            await presentToast(
                'Text us at ' + supportNumber,
                4000,
                chatbubbleEllipsesOutline
            );
        }
    };

    return (
        <div className="text-sm text-white text-center px-2 py-4">
            <span onClick={handleTextSupport} className="cursor-pointer hover:underline">
                Text us at 1-855-629-2475
            </span>
            {" "}or DM us on IG @ ecliptic.day if you have trouble signing up or logging in.
        </div>
    );
}

export default WelcomeHelp;