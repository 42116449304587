import React, { useEffect, useState } from "react";
import IDXChip from "../IDXChip/IDXChip";
import { UserTrick } from "../../../models/user-trick";
import { UserTrickSearch } from "../../../models/user-trick-search";
import { UserTrickSearchMetadata } from "../../../models/userTrickSearchMetadata";
import { alertCircleOutline } from "ionicons/icons";
import { useIonToast } from "@ionic/react";
import useAuthService from "../../../hooks/useAuthService";
import { VideoUserTag } from "../../../models/taggedUser";

interface ContainerProps {
    tags?: string[];
    handleTagClick: (tag: string) => void;
    handleUserTagClick: (tag: string) => void;
    trick: UserTrick | UserTrickSearch | UserTrickSearchMetadata;
    taggedUsers: VideoUserTag[];
    isAuthed?: boolean;
}

const VideoTagDisplay: React.FC<ContainerProps> = (props) => {
    const [present] = useIonToast();
    const { login } = useAuthService();

    const [updatedDisplayTags, setUpdatedDisplayTags] = useState<string[]>([]);

    const presentToastLoginHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            icon: alertCircleOutline,
            position: 'top',
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    useEffect(() => {
        if (props.tags) {
            // Analyze and modify the tags array here
            const updatedTags = props.tags.map((tag) => {
                // Define the pattern to match "Line" followed by digits
                return tag;
            });

            // Call a function to update the modified tags as needed
            // For example: props.updateTags(updatedTags);
            setUpdatedDisplayTags(updatedTags);
        }
    }, [props.tags]);

    if (props.tags) {
        return (
            <div className="flex flex-row flex-nowrap font-bold text-xl overflow-x-auto gap-x-1 mx-2 video-tag-display">
                {/* Tagged Users */}
                {props.taggedUsers && props.taggedUsers.map((userTag, index) => (
                    <IDXChip
                        key={`user-${index}`}
                        bgColor="hover:bg-neon-pink/95 click:bg-neon-pink/95"
                        borderColor="border-2 border-neon-pink"
                        textColor="text-white"
                        text={`@${userTag.video_user_tags_username}`}
                        onClickChip={props.isAuthed && props.handleUserTagClick ?
                            () => props.handleUserTagClick!(userTag.video_user_tags_username) :
                            () => presentToastLoginHandler(`View ${userTag.video_user_tags_username}'s profile by logging in!`)}
                    />
                ))}
                {/* Existing Tags */}
                {updatedDisplayTags.map((tag, index) => (<IDXChip key={index} bgColor={
                    props.trick.sport === tag ? 'hover:bg-highlight-alt/95 click:bg-highlight-alt/95' : props.trick.clip_type === tag ?
                        'hover:bg-highlight/95 click:bg-highlight/95' : props.trick.terrain === tag ?
                            'hover:bg-highlight-secondary/95 click:bg-highlight-secondary/95' : props.trick.location === tag ?
                                'hover:bg-highlight-secondary/95 click:bg-highlight-secondary/95' :
                                props.trick.trick_name === tag ? 'hover:bg-highlight/95 click:bg-highlight/95'
                                    : 'bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50'}
                    borderColor={props.trick.sport === tag ? 'border-2 border-highlight-alt' :
                        props.trick.clip_type === tag ? 'border-2 border-highlight' :
                            props.trick.terrain === tag ? 'border-2 border-highlight-secondary' :
                                props.trick.location === tag ? 'border-2 border-highlight-secondary' :
                                    props.trick.trick_name === tag ? 'border-2 border-highlight' :
                                        'border-2 border-primary-secondary'}
                    textColor={'text-white'}
                    text={tag}
                    onClickChip={props.isAuthed ? props.handleTagClick :
                        (tag) => presentToastLoginHandler(`Search for ${tag} by logging in!`)}></IDXChip>))}
            </div>
        );
    } else {
        return null;
    }
};

export default VideoTagDisplay;