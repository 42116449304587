import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "./AppStateProvider";
import { useAppState } from "./AppListenerProvider";
import { useAuth } from "./AuthProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { UserType } from "./models/usertype";
import AuthService from "./services/auth.service";
import { LOG_LEVEL, Purchases } from "@revenuecat/purchases-capacitor";
import PreferencesService from "./services/preferences.service";
import { User } from "./models/user";
import { v4 as uuidv4 } from "uuid";
import VideoCacheService from "./services/videoCache.service";
import { fileDirectories, protectedRoutes } from "./services/utils";
import { Directory } from "@capacitor/filesystem";
import { MutedStatus } from "./models/mutedStatus";
import { IonTabsCustomEvent } from "@ionic/core/dist/types/components";
import { IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, setupIonicReact } from "@ionic/react";
import BrandEventInfo from "./pages/BrandEventInfo";
import TrickVideo from "./pages/TrickVideo";
import Claim from "./pages/Claim";
import CrewClaim from "./pages/CrewClaim";
import Filter from "./pages/Filter";
import Shop from "./pages/Shop";
import PurchaseSuccessPage from "./pages/PurchaseSuccessPage";
import Settings from "./pages/Settings";
import AuthFlow from "./pages/AuthFlow";
import LoadingSplash from "./pages/LoadingSplash";
import { useDeepLink } from "./DeepLinkProvider";
import ExplorePage from "./pages/ExplorePage";
import MapPage from "./pages/Map";
import Upload from "./pages/Upload";
import CrewUpload from "./pages/CrewUpload";
import Community from "./pages/Community";
import Profile from "./pages/Profile";
import Crew from "./pages/Crew";
import { homeSharp, addCircle, bonfire, person, map, mapSharp, addCircleSharp, bonfireSharp, personSharp, homeOutline, home, mapOutline, addCircleOutline, bonfireOutline, personOutline } from "ionicons/icons";
import { usePushNotifications } from "./hooks/usePushNotifications";
import { useDetermineOnboarding } from "./hooks/useDetermineOnboarding";
import Welcome from "./components/ComponentsOnboarding/Welcome/Welcome";
import WelcomeLogin from "./components/ComponentsOnboarding/WelcomeLogin/WelcomeLogin";
import WelcomeSignUp from "./components/ComponentsOnboarding/WelcomeSignUp/WelcomeSignUp";
import { useHistory, useLocation, Route, Redirect, RouteComponentProps } from "react-router";
import customBackAnimation from "./components/ComponentsGestures/NavigationAnimation/customBackAnimation";
import { useTabBarVisibility } from "./hooks/useTabBarVisibility";
import { useCustomSwipeBack } from "./hooks/useCustomSwipeBack";
import ErrorBoundary from "./components/ComponentsUI/ErrorBoundary/ErrorBoundary";
import useAuthService from "./hooks/useAuthService";
import WelcomeForgotPassword from "./components/ComponentsOnboarding/WelcomeForgotPassword/WelcomeForgotPassword";
import AuthHandler from "./components/ComponentsOnboarding/AuthHandler/AuthHandler";
import WelcomeResetPassword from "./components/ComponentsOnboarding/WelcomeResetPassword/WelcomeResetPassword";
import UploadSuccess from "./components/ComponentsUpload/UploadSuccess/UploadSuccess";

setupIonicReact({
    swipeBackEnabled: false,
});

const AppMain: React.FC = () => {
    const history = useHistory();
    const location = useLocation();

    const { state, dispatch } = useContext(AppContext);
    const { isActive, isNative, platform } = useAppState();
    const { user, isLoading, isAuthenticated, firebaseApp, auth } = useAuth();
    const { setAuthVariables } = useAuthService();
    const { getAccessTokenSilently } = useAuth0();
    const { checkPushNotificationPermission } = usePushNotifications();
    const { isOnboarding, isDeterminingOnboarding, getOnboardingRedirect } = useDetermineOnboarding();
    const { shouldShowTabBar, shouldDisableSwipeBack, currentTab } = useTabBarVisibility();

    const [userType, setUserType] = React.useState<string>(UserType.USER); // TODO: MAKE THIS A STATE VARIABLE
    const authService = AuthService({ user, isLoading, isAuthenticated, firebaseApp, auth }, {
        isActive,
        isNative,
        platform
    });
    const [key, setKey] = useState(0);

    // SWIPE BACK LOGIC
    const routerOutletRef = useRef<HTMLIonRouterOutletElement>(null);
    useCustomSwipeBack(routerOutletRef);

    useEffect(() => {
        document.body.classList.toggle('disable-swipe-back', shouldDisableSwipeBack);
        return () => {
            document.body.classList.remove('disable-swipe-back');
        };
    }, [shouldDisableSwipeBack]);

    // Force re-render when isOnboarding changes
    useEffect(() => {
        setKey(prevKey => prevKey + 1);
    }, [isOnboarding]);

    // PAYMENTS LOGIC
    const initializeRevenueCat = async () => {
        await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });

        if (platform === 'ios') {
            console.log("Initializing for Apple - Revenue Cat");
            await Purchases.configure({ apiKey: 'appl_dTgMFKeUzMoMYIzYwbMzDmRnXvQ' })
        } else if (platform === 'android') {
            console.log("Initializing for Android - Revenue Cat");
            await Purchases.configure({ apiKey: 'goog_DcfrRmocYTiAaotDMpCpPBQicsW' })
        }
    }

    // AUTH LOGIC
    const setAuthVariablesHandler = async () => {
        const userType = await setAuthVariables();
        setUserType(userType);
    }

    useEffect(() => {
        if (!isLoading) {
            console.log("IS LOADING IS FALSE - Getting/Setting User Data");
            if (isAuthenticated && !isOnboarding) {
                setAuthVariablesHandler();
            }
        }

    }, [isAuthenticated, isNative, user, isLoading, isOnboarding]);

    const getAccessTokenSilentlyForAuth0 = async () => {
        const value = await PreferencesService.getAccessToken();
        if (value) {
            try {
                console.log(`Getting token through this url + ${process.env.REACT_APP_AUTH_API}`);

                const actionTokenOptions = !isNative ? {
                    audience: `${process.env.REACT_APP_AUTH_API}`,
                    scope: "read:current_user"
                }
                    : {
                        audience: `${process.env.REACT_APP_AUTH_API}`,
                        scope: "read:current_user",
                        ignoreCache: true,
                        timeoutInSeconds: 10
                    }

                const accessToken = await getAccessTokenSilently(actionTokenOptions);

                console.log("Setting user auth0 access token");
                await PreferencesService.setAccessToken(accessToken);
                return accessToken;

            } catch (e: any) {
                console.log("ERROR ON APP getting the access token");
                console.log(e);
            }
        } else {
            return null;
        }

    }

    useEffect(() => {
        const getAuth0TokenSetUpPurchases = async () => {
            console.log("Setting user state");

            const sessionId = uuidv4();
            await PreferencesService.setUserSessionId(sessionId);

            if (isNative) {
                await initializeRevenueCat();
            }

            const isAuth0Token = await getAccessTokenSilentlyForAuth0();

            if (isAuth0Token) {
                console.log("Found auth0 token, now logging in with firebase");
                try {
                    const res = await authService.fetchCustomTokenAndSignIn();
                } catch (e: any) {
                    console.log("ERROR ON APP getting the access token");
                    console.log(e);
                }
            }
        }

        if (!isLoading) {
            console.log("IS LOADING IS FALSE - Getting Auth0 Token (if present) + Setting Up Purchases");
            getAuth0TokenSetUpPurchases();
        }
    }, [isNative, isLoading]);

    useEffect(() => {
        if (isNative) {
            // code for clearing cache
            console.log("CLEARING CACHE")
            // code for clearing cache
            VideoCacheService.clearCache(24, fileDirectories, Directory.Cache);
        }
    }, [isNative]);

    useEffect(() => {
        const initAppFunctionality = async () => {
            if (!isActive) {
                console.log("App is in the background");
            }

            if (isActive) {
                console.log("App is in the foreground");
                if (isNative) {
                    await checkPushNotificationPermission();
                }
            }
        }

        initAppFunctionality();
    }, [isNative, isActive]);

    // AUTH ROUTING STRATEGY
    // Do not do any redirects based on conditional state - all conditional state logic should be in the component
    // If need to navigate to a different page based on conditional state, then do it in the component
    // If this navigation is to another tab, then call that tab in that component and history.go(0) if things don't work as expected

    const handleTabWillChange = (e: IonTabsCustomEvent<{ tab: string }>) => {
        console.log('Tab will change', e.detail.tab);
        if (e.detail.tab === 'home') {
            console.log('Clicked on home tab');
            history.push('/home');
        } else if (e.detail.tab === 'upload') {
            if (userType === UserType.BRAND) {
                console.log('Clicked on crew upload tab');
                history.push('/crew-upload');
            } else {
                console.log('Clicked on upload tab');
                history.push('/upload');
            }
        } else if (e.detail.tab === 'profile') {
            console.log('Clicked on profile tab');
            history.push(`/profile/${state.user.username}`);
        } else if (e.detail.tab === 'community') {
            console.log('Clicked on notifications tab');
            history.push('/community');
        } else if (e.detail.tab === 'map') {
            console.log('Clicked on map tab');
            history.push('/map');
        }
    };

    if (isDeterminingOnboarding) {
        return <LoadingSplash />;
    }

    //

    // <Route path="/profile/:username" component={Profile} exact={true} />
    // <Route path="/profile" component={Profile} exact={true} />

    const renderRoutes = () => (
        <IonRouterOutlet animation={isNative ? customBackAnimation : undefined} animated={isNative} key={key} ref={routerOutletRef}>
            {/* Onboarding Routes */}
            <Route path="/flow" render={() => isOnboarding ? <Welcome /> : <Redirect to="/home" />} exact />
            <Route path="/flow/signup" render={() => isOnboarding ? <WelcomeSignUp /> : <Redirect to="/home" />} exact />
            <Route path="/flow/login" render={() => isOnboarding ? <WelcomeLogin /> : <Redirect to="/home" />} exact />
            <Route path="/auth/forgot-password" render={() => isOnboarding ? <WelcomeForgotPassword /> : <Redirect to="/home" />} exact />
            <Route path="/auth/reset-password" render={() => isOnboarding ? <WelcomeResetPassword /> : <Redirect to="/home" />} exact />
            <Route path="/admin/__/auth/handler" render={() => <AuthHandler />} exact />

            {/* Main App Routes */}
            <Route path="/home" render={() => !isOnboarding ? <ExplorePage /> : <Redirect to={getOnboardingRedirect()} />} exact />
            <Route path="/map" render={() => !isOnboarding ? <MapPage /> : <Redirect to={getOnboardingRedirect()} />} exact />
            <Route path="/upload" render={() => !isOnboarding ? <Upload /> : <Redirect to={getOnboardingRedirect()} />} exact />
            <Route path="/crew-upload" render={() => !isOnboarding ? <CrewUpload /> : <Redirect to={getOnboardingRedirect()} />} exact />
            <Route path="/community" render={() => !isOnboarding ? <Community /> : <Redirect to={getOnboardingRedirect()} />} exact />
            <Route
                path="/profile/:username"
                render={(props) =>
                    <Profile {...props} />
                }
                exact
            />
            <Route
                path="/profile"
                render={(props) =>
                    !isOnboarding
                        ? <Profile {...props} />
                        : <Redirect to={getOnboardingRedirect()} />
                }
                exact
            />

            {/* Shared Routes */}
            <Route path="/clip-upload/:user_id/:id" render={(props) => !isOnboarding ? <UploadSuccess {...props} /> : <Redirect to={getOnboardingRedirect()} />} exact />
            <Route
                path="/clip/:user_id/:id"
                render={(props) =>
                    <TrickVideo {...props} />
                }
                exact
            />
            <Route path="/claim" render={() => !isOnboarding ? <Claim /> : <Redirect to={getOnboardingRedirect()} />} exact />
            <Route path="/claim/crews" render={() => !isOnboarding ? <CrewClaim /> : <Redirect to={getOnboardingRedirect()} />} exact />
            <Route
                path="/search/:query"
                render={(props) =>
                    !isOnboarding
                        ? <Filter {...props} />
                        : <Redirect to={getOnboardingRedirect()} />
                } exact
            />
            <Route
                path="/search"
                render={(props) =>
                    !isOnboarding ? <Filter {...props} />
                        : <Redirect to={getOnboardingRedirect()} />}
                exact
            />
            <Route path="/shop" render={() => !isOnboarding ? <Shop /> : <Redirect to={getOnboardingRedirect()} />} exact />
            <Route path="/purchase" render={() => !isOnboarding ? <PurchaseSuccessPage /> : <Redirect to={getOnboardingRedirect()} />} exact />
            <Route path="/settings" render={() => !isOnboarding ? <Settings /> : <Redirect to={getOnboardingRedirect()} />} exact />
            <Route path="/loading" render={() => !isOnboarding ? <LoadingSplash /> : <Redirect to={getOnboardingRedirect()} />} exact />

            <Route path="/crews/:username" render={(props) => <Crew {...props} />} exact />
            <Route path="/event/:id" render={(props) => <BrandEventInfo {...props} />} exact />

            {/* Redirect Routes */}
            <Route
                path="/trick/:user_id/:id"
                render={({ match }) => (
                    <Redirect to={`/clip/${match.params.user_id}/${match.params.id}`} />
                )}
                exact
            />

            {/* Default Routes */}
            <Route exact path="/" render={() => <Redirect to={isOnboarding ? getOnboardingRedirect() : "/home"} />} />
            <Route render={() => <Redirect to={isOnboarding ? getOnboardingRedirect() : "/home"} />} />
        </IonRouterOutlet>
    );

    return (
        <ErrorBoundary>
            <IonTabs onIonTabsWillChange={handleTabWillChange}>
                {renderRoutes()}
                <IonTabBar slot="bottom" hidden={!shouldShowTabBar(location.pathname)}>
                    <IonTabButton tab="home" href={'/home'}>
                        <IonIcon icon={currentTab === '/home' ? home : homeOutline} />
                        <span>Feed</span>
                    </IonTabButton>
                    <IonTabButton tab="community" href={`/community`}>
                        <IonIcon icon={currentTab === '/community' ? bonfire : bonfireOutline} />
                        <span>Community</span>
                    </IonTabButton>
                    <IonTabButton tab="upload" href={userType !== UserType.BRAND ? '/upload' : '/crew-upload'}>
                        <IonIcon icon={currentTab === '/upload' || currentTab === '/crew-upload' ? addCircle : addCircleOutline} />
                        <span>Upload</span>
                    </IonTabButton>
                    <IonTabButton tab="map" href={'/map'}>
                        <IonIcon icon={currentTab === '/map' ? map : mapOutline} />
                        <span>Map</span>
                    </IonTabButton>
                    <IonTabButton tab="profile"
                        href={`/profile/${state.user.username}`}>
                        <IonIcon icon={currentTab === `/profile/${state.user.username}` ? person : personOutline} />
                        <span>You</span>
                    </IonTabButton>
                </IonTabBar>
            </IonTabs >
        </ErrorBoundary>
    );
};

{/*
Notes:

WE ONLY USE IMPORTS FROM REACT-ROUTER NOT REACT-ROUTER-DOM

Profile Route:
like instagram, if logged in, then go to your profile with username,
if not then assume it's another profile, and use that to get profile information.
Should get profile information from the username that is supplied
*/

    // Auth routes - No Tab Bar - USED AS A BACKUP -> Revert to Onboarding Flow if Log Out - Just Need to Revert Back To Login - DEPRECATED */
    // Route path="/flow/login" render={() => <AuthFlow loginInitial={true} />} exact={true} />
    //                 <Route path="/flow/login/username" render={() => <AuthFlow loginUsername={true} />} exact={true} />
    //                 <Route path="/flow/forgot-password" render={() => <AuthFlow forgotPassword={true} />} exact={true} />
    //                 <Route path="/flow/reset-password" render={() => <AuthFlow forgotPassword={true} />} exact={true} />
    //                 <Route path="/flow/signup" render={() => <AuthFlow signUpInitial={true} />} exact={true} />
    //                 <Route path="/flow/signup/email" render={() => <AuthFlow signUpEmail={true} />} exact={true} /> */
    //                 */
}

export default AppMain;