import axios from "axios";

const TricksService = {
    trick_url: process.env.REACT_APP_MCTWIST_TRICK_SERVER + "/trick",
    tricks_url: process.env.REACT_APP_MCTWIST_TRICK_SERVER + "/tricks",

    user_trick_url: process.env.REACT_APP_MCTWIST_USER_SERVER + "/user/trick",

    google_video_api_url: "https://www.googleapis.com/youtube/v3/videos",

    getUserTrick: async (trickId: string, userId: string) => {
        const params = {
            user_id: userId,
            trick_id: trickId
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick`, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserTrickSocialUrl: async (trickId: string, userId: string) => {
        const params = {
            user_id: userId,
            trick_id: trickId
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/social`, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserTricks: async (userId: string) => {
        const params = {
            infd_id: userId,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/tricks`, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getTrickVideoProperties: async (trickId: string) => {
        const params = {
            trick_id: trickId,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/video_properties`, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getTrickUserTagged: async (trickId: string) => {
        const params = {
            trick_id: trickId,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/tagged`, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getIsExternalVideoRestricted: async (videoId: string) => {
        const params = {
            id: videoId,
            part: "contentDetails",
            key: process.env.REACT_APP_GOOGLE_API_KEY
        }

        return await axios.get(TricksService.google_video_api_url, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getTrickInteractionsCount: async (trick_id: string) => {
        const params = {
            trick_id: trick_id
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/interactions/count`, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    }

};

export default TricksService;
