import {
    type MediaPlayerInstance,
    useMediaStore,
    Menu,
    useMediaState,
} from '@vidstack/react';
import { useVideoContext } from '../VideoContextManager/VideoContextManager';

interface CustomSettingsMenuProps {
    hasInteracted: boolean;
}

const CustomSettingsMenu: React.FC<CustomSettingsMenuProps> = ({ hasInteracted }) => {
    const { playerRef } = useVideoContext();

    const playbackRate = useMediaState('playbackRate', playerRef);
    const speeds = [1, 0.5, 0.25];

    if (!hasInteracted) {
        console.log('No interaction yet');
        return null;
    }

    const handleSpeedChange = () => {
        if (playerRef.current) {
            const currentIndex = speeds.indexOf(playbackRate);
            const nextIndex = (currentIndex + 1) % speeds.length;
            playerRef.current.playbackRate = speeds[nextIndex];
        }
    };


    const getSpeedIcon = (speed: number) => {
        return (
            <div className="relative w-8 h-8 flex items-center justify-center">
                <div className="absolute inset-0 border-2 border-white rounded-full"></div>
                <span className="text-white text-xs font-bold">
                    {speed === 1 ? '1x' : speed === .5 ? '0.5x' : speed.toFixed(2).slice(1) + 'x'}
                </span>
            </div>
        );
    };

    return (
        <Menu.Root>
            <Menu.Button
                onClick={handleSpeedChange}
                className="group ring-sky-400 relative inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-md outline-none ring-inset hover:bg-white/20 data-[focus]:ring-4"
                aria-label={`Playback Speed: ${playbackRate}x`}
            >
                {getSpeedIcon(playbackRate)}
            </Menu.Button>
        </Menu.Root>
    );
};

export default CustomSettingsMenu;