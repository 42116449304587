import React, { useEffect, useState } from "react";
import { useParams, useHistory, RouteComponentProps } from "react-router";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    IonButton, IonContent, IonImg, IonPage, IonRefresher, IonRefresherContent,
    IonSelect,
    IonSelectOption,
    RefresherEventDetail, useIonAlert, useIonModal
} from "@ionic/react";
import { format, parseISO } from 'date-fns';

import BrandDescriptionModal from "../components/ComponentsCrewProfile/BrandDescriptionModal/BrandDescriptionModal";
import EditDeleteEvent from "../components/ComponentsEvent/EditDeleteEvent/EditDeleteEvent";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import BrandEventInfoCard from "../components/ComponentsCrewProfile/BrandEventInfoCard/BrandEventInfoCard";
import IDXChip from "../components/ComponentsUI/IDXChip/IDXChip";
import EventPageLoading from "../components/ComponentsUI/EventPageLoading/EventPageLoading";

import { BrandEvent } from "../models/brandEvent";
import { UserTrickSearch } from "../models/user-trick-search";
import BrandsService from "../services/brand.service";
import { handleTagClickUtil } from "../services/utils";
import { useAuth } from "../AuthProvider";
import ProfileVirtualGrid from "../components/ComponentsProfile/ProfileVirtualGrid/ProfileVirtualGrid";
import EclipticSearchbar from "../components/ComponentsUI/EclipticSearchbar/EclipticSearchbar";
import { Board } from "../models/board";
import EventBoardResultsList from "../components/ComponentsBoard/EventBoardResultList/EventBoardResultList";
import { useBlur } from "../hooks/useBlur";
import { useAnalytics } from "../AppAnalyticsProvider";
import ToolbarEventBar from "../components/ComponentsUI/ToolbarEventBar/ToolbarEventBar";
import FullScreenPicture from "../components/ComponentsUI/FullScreenPicture/FullScreenPicture";
import { getAdjustedTiming, QueryTiming } from "../hooks/useQueryConfig";

interface EventParams {
    id?: string;
}

interface EventPageProps extends RouteComponentProps<EventParams> { }

const BrandEventInfo: React.FC<EventPageProps> = ({ match }) => {
    const { id } = useParams<{ id: string }>();
    const { trackEvent, trackScreen } = useAnalytics();

    const history = useHistory();
    const queryClient = useQueryClient();
    const { user, isAuthenticated } = useAuth();
    const { blurred } = useBlur();


    const [eventTricksFilter, setEventTricksFilter] = useState("");
    const [activeTab, setActiveTab] = useState('Event Bag'); // Remove the array destructuring and add setter
    const [presentAlert] = useIonAlert();

    const [selectedBoard, setSelectedBoard] = useState<Board | null>(null);
    const [sortType, setSortType] = useState<string>("Top");

    const [profilePictureFullScreen, setProfilePictureFullScreen] = useState(false);

    const { data: brandEvent, isLoading: isEventLoading, isError: isEventError, refetch: refetchEvent } = useQuery({
        queryKey: ['brandEvent', id],
        queryFn: () => BrandsService.getEventById(id),
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
    });

    const { data: brand, isLoading: isBrandLoading, refetch: refetchBrand } = useQuery({
        queryKey: ['brand', brandEvent?.brand_id],
        queryFn: () => BrandsService.getBrandById(brandEvent.brand_id),
        enabled: !!brandEvent,
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
    });

    // Get event boards
    const { data: brandBoards, isLoading: isEventBoardLoading, refetch: refetchBrandEventBoard } = useQuery({
        queryKey: ['brandEventBoard', id],
        queryFn: () => BrandsService.getEventBoards(id),
    });

    const { data: eventTricks, isLoading: isEventTricksLoading, refetch: refetchEventTricks } = useQuery({
        queryKey: ['eventTricks', id],
        queryFn: () => BrandsService.getEventTricks(
            id,
            brandEvent.start_date,
            brandEvent.end_date,
            brandEvent.event_tag,
            brandEvent.sports,
            brandEvent.features,
            brandEvent.event_trick_tags,
            brandEvent.event_tricks
        ),
        enabled: !!brandEvent,
        ...getAdjustedTiming(QueryTiming.HIGH_FREQUENCY),
    });

    const isEditBrand = isAuthenticated && brand?.auth_id === user.uid;

    const [presentEventDescriptionModal, dismissEventDescriptionModal] = useIonModal(BrandDescriptionModal, {
        onDismiss: (data: string, role: string) => dismissEventDescriptionModal(data, role),
        description: brandEvent?.description,
        title: brandEvent?.name
    });

    const handleTagClick = (tag: string) => {
        const newTag = handleTagClickUtil(tag);
        trackEvent('brand_event_tag_clicked', {
            event_id: id,
            tag: newTag
        });
        history.push('/search/' + newTag);
    };

    const onShopRedirect = () => {
        trackEvent('brand_event_shop_clicked', {
            event_id: id,
            brand_id: brand?.id
        });
        history.push('/shop');
    };

    const onRefreshBrandEvent = async (event: CustomEvent<RefresherEventDetail>) => {
        trackEvent('brand_event_refreshed', {
            event_id: id
        });
        await refetchEvent();
        await refetchBrand();
        await refetchEventTricks();
        await refetchBrandEventBoard();
        event.detail.complete();
    };

    const goToUploadTrick = (brandEvent: BrandEvent) => {
        if (isEditBrand) {
            trackEvent('brand_event_upload_error', {
                event_id: id,
                error_type: 'brand_upload_attempt'
            });
            presentAlert({
                header: `Error`,
                message: `You cannot upload a trick if you are a brand`,
                buttons: [{ text: 'Exit', role: 'cancel' }],
            });
        } else {
            trackEvent('brand_event_upload_started', {
                event_id: id,
                event_tag: brandEvent.event_tag
            });
            history.push(`/upload?tag=${brandEvent.event_tag.replace("#", '')}`);
        }
    };

    const onDismissEditEvent = async (data: string, role: string) => {
        trackEvent('brand_event_edit_dismissed', {
            event_id: id,
            role: role,
            success: role === 'success'
        });
        if (role === 'success') {
            await queryClient.invalidateQueries({ queryKey: ['brandEvent', id] });
        }
    };

    // Delete event mutation
    const deleteEventMutation = useMutation({
        mutationFn: (event: BrandEvent) => BrandsService.deleteBrandEvent(brand.id, event.id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['brandEvents', brand?.id] });
        }
    });

    const onClickTrick = (trick: UserTrickSearch) => {
        trackEvent('brand_event_trick_clicked', {
            event_id: id,
            trick_id: trick.trick_id,
            user_id: trick.user_id
        });
        history.push('/clip/' + trick.user_id + '/' + trick.trick_id);
    }

    const getColumnCountEvents = () => {
        const width = window.innerWidth;
        if (width >= 1280) return 6;
        if (width >= 768) return 4;
        if (width >= 640) return 3;
        return 2;
    };

    // Track screen view
    useEffect(() => {
        trackScreen('brand_event_info', 'BrandEventInfo');
        trackEvent('brand_event_viewed', {
            event_id: id,
            brand_id: brand?.id,
            brand_name: brand?.name,
            event_name: brandEvent?.name
        });
    }, []);

    // Track tab changes
    useEffect(() => {
        if (activeTab) {
            trackEvent('brand_event_tab_changed', {
                event_id: id,
                tab: activeTab
            });
        }
    }, [activeTab]);

    // Track board selection
    useEffect(() => {
        if (selectedBoard) {
            trackEvent('brand_event_board_selected', {
                event_id: id,
                board_id: selectedBoard.id,
                board_name: selectedBoard.name
            });
        }
    }, [selectedBoard]);

    // Track sort type changes
    useEffect(() => {
        if (sortType) {
            trackEvent('brand_event_sort_changed', {
                event_id: id,
                sort_type: sortType,
                board_name: selectedBoard?.name
            });
        }
    }, [sortType]);

    useEffect(() => {
        if (!isEventLoading && !isBrandLoading && !isEventTricksLoading) {
            window.prerenderReady = true;
        }
    }, [isEventLoading, isBrandLoading, isEventTricksLoading]);

    useEffect(() => {
        if (brandBoards?.length && !selectedBoard) {
            console.log("Setting selected board: ", brandBoards[0]);
            setSelectedBoard(brandBoards[0]);
            setSortType(brandBoards[0].sort[0]);
        }
    }, [brandBoards]);

    if (isEventLoading || isBrandLoading || isEventTricksLoading || isEventBoardLoading) {
        return (
            <IonPage>
                <CustomHelmet
                    title={brand?.username && brandEvent?.name ? `Ecliptic // ${brand.username} // ${brandEvent.name}` : "idx // View Event"}
                    description={brandEvent?.description || "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                    image={brandEvent?.cover_pic || "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                    url={`https://ecliptic.day/event/${id}`}
                />
                <ToolbarEventBar event={brandEvent} />
                <IonContent>
                    <EventPageLoading />
                </IonContent>
            </IonPage>
        );
    }

    if (isEventError) {
        return (
            <IonPage>
                <ToolbarEventBar event={brandEvent} />
                <IonContent>
                    <div className="flex flex-row justify-center items-center w-full mt-8">
                        <IonButton color="favorite" onClick={() => history.push('/community')}>
                            Event not found, return to the community page
                        </IonButton>
                    </div>
                </IonContent>
            </IonPage>
        );
    }

    return (
        <IonPage>
            <CustomHelmet
                title={brand?.username && brandEvent?.name ? `Ecliptic // ${brand.username} // ${brandEvent.name}` : "idx // View Event"}
                description={brandEvent?.description || "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                image={brandEvent?.cover_pic || "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                url={`https://ecliptic.day/event/${id}`}
            />
            <ToolbarEventBar event={brandEvent} />
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={onRefreshBrandEvent}>
                    <IonRefresherContent />
                </IonRefresher>
                {profilePictureFullScreen && (
                    <FullScreenPicture
                        src={brandEvent?.cover_pic || '/assets/photos/defaultProPic.png'}
                        onClose={() => setProfilePictureFullScreen(false)}
                    />
                )}
                <div>
                    <div className="flex flex-row justify-center items-center h-[200px] overflow-hidden bg-black">
                        <div className="w-full h-full">
                            <IonImg
                                src={brandEvent?.cover_pic}
                                alt={brandEvent?.name}
                                className="w-full h-full object-none"
                                onClick={() => setProfilePictureFullScreen(true)}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row mx-3 my-2 justify-between">
                        <div className="flex flex-col gap-y-1 items-start basis-3/4">
                            <div className="text-xl mx-1 font-bold">
                                {brandEvent?.name}
                            </div>
                            <div className="text-lg mx-1 font-bold" onClick={() => history.push(`/crews/${brand.username}`)}>
                                {brand?.name}
                            </div>
                            <div className="flex flex-row gap-2 text-lg mx-1 items-center">
                                {brandEvent?.start_date && brandEvent?.end_date ?
                                    `${format(parseISO(brandEvent?.start_date), 'MMM d, yyyy')} - ${format(parseISO(brandEvent?.end_date), 'MMM d, yyyy')}`
                                    : null}
                            </div>
                            <div className="text-xl flex flex-row gap-x-2">
                                {(brandEvent?.start_date && brandEvent?.end_date) &&
                                    (new Date(new Date().toDateString()).toISOString() >= brandEvent?.start_date &&
                                        new Date(new Date().toDateString()).toISOString() <= brandEvent?.end_date ?
                                        <IDXChip
                                            bgColor="bg-highlight-secondary/50 hover:bg-highlight-secondary/95 click:bg-highlight-secondary/95"
                                            borderColor="border-2 border-highlight-secondary"
                                            text="Live"
                                        /> :
                                        <IDXChip
                                            bgColor="bg-highlight-danger/50 hover:bg-highlight-danger/95 click:bg-highlight-danger/95"
                                            borderColor="border-2 border-highlight-danger"
                                            text="Closed"
                                        />
                                    )
                                }
                                {brandEvent?.location &&
                                    <IDXChip
                                        bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                        borderColor="border-2 border-highlight"
                                        text={brandEvent?.location}
                                    />
                                }
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-1 items-end basis-1/4">
                            <IonButton fill="solid" color="favorite" size="small"
                                onClick={() => goToUploadTrick(brandEvent)}>
                                Upload
                            </IonButton>
                            <IonButton fill="solid" color="favorite-secondary" size="small"
                                onClick={() => presentEventDescriptionModal()}>
                                Rules
                            </IonButton>
                            <div className="flex flex-row-reverse gap-x-2">
                                {isEditBrand &&
                                    <EditDeleteEvent
                                        onDismissEditEvent={onDismissEditEvent}
                                        deleteEvent={deleteEventMutation.mutate}
                                        brandEvent={brandEvent}
                                        brand={brand}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row mx-3 my-1">
                        <div className="flex flex-wrap gap-x-2 gap-y-2">
                            {brandEvent?.event_tag &&
                                <IDXChip
                                    bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                                    borderColor="border-2 border-primary-secondary"
                                    text={brandEvent?.event_tag}
                                />
                            }
                            {brandEvent?.sports?.map((tag: any, index: number) => (
                                <IDXChip
                                    key={index}
                                    bgColor="hover:bg-highlight-alt/95 click:bg-highlight-alt/95"
                                    borderColor="border-2 border-highlight-alt"
                                    text={tag}
                                    onClickChip={(tag) => handleTagClick(tag)}
                                />
                            ))}
                            {brandEvent?.features?.map((tag: any, index: number) => (
                                <IDXChip
                                    key={index}
                                    bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                    borderColor="border-2 border-highlight"
                                    onClickChip={(tag) => handleTagClick(tag)}
                                    text={tag}
                                />
                            ))}
                            {brandEvent?.event_trick_tags?.map((tag: any, index: number) => (
                                <IDXChip
                                    text={tag}
                                    key={index}
                                    bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                    borderColor="border-2 border-highlight"
                                    onClickChip={(tag) => handleTagClick(tag)}
                                />
                            ))}
                            {brandEvent?.event_tricks?.map((trick: string[], index: number) => (
                                <IDXChip
                                    key={index}
                                    text={trick.join(" ")}
                                    bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                    borderColor="border-2 border-highlight"
                                    onClickChip={(tag) => handleTagClick(tag)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-center space-x-4 border-b-2 border-gray-500 text-base font-bold">
                        <div className={activeTab === 'Event Bag' ? "border-b-4 border-[#5963A7]" : ""}>
                            <button
                                className={`py-2 px-2.5 text-xl ${activeTab === 'Event Bag' ? 'border-b-4 border-[#5963A7] text-white' : 'text-gray-400'}`}
                                onClick={() => setActiveTab('Event Bag')}>
                                Event Bag <span className="text-sm">{eventTricks?.length || 0}</span>
                            </button>
                        </div>
                        <div className={activeTab === 'Leaderboards' ? "border-b-4 border-[#5963A7]" : ""}>
                            <button
                                className={`py-2 px-2.5 text-xl ${activeTab === 'Leaderboards' ? 'border-b-4 border-[#5963A7] text-white' : 'text-gray-400'}`}
                                onClick={() => setActiveTab('Leaderboards')}>
                                Leaderboards
                            </button>
                        </div>
                    </div>
                    <div className="p-1">
                        {
                            activeTab === "Event Bag" &&
                            <>
                                <div className="flex flex-row justify-between items-center mb-0.5">
                                    <EclipticSearchbar onIonInput={(e) => setEventTricksFilter(e.detail.value)}
                                        showClearButton="focus"
                                        showCancelButton="focus"
                                        style={{ paddingTop: 0, paddingBottom: 0 }}
                                        id="searchText"
                                        inputmode="search"
                                        debounce={200}
                                        animated={true}></EclipticSearchbar>
                                </div>
                                <ProfileVirtualGrid<UserTrickSearch>
                                    items={eventTricks}
                                    averageHeight={1000}
                                    renderItem={
                                        (trick) =>
                                            <div>
                                                <BrandEventInfoCard trick={trick}></BrandEventInfoCard>
                                            </div>
                                    }
                                    getItemId={(trick) => trick.id}
                                    filter={(trick: UserTrickSearch) =>
                                        trick.trick_name.toLowerCase().includes(eventTricksFilter.toLowerCase()) ||
                                        (trick.location && trick.location.toLowerCase().includes(eventTricksFilter.toLowerCase()))
                                        || trick.username && trick.username.toLowerCase().includes(eventTricksFilter.toLowerCase())
                                    }
                                    onClick={onClickTrick}
                                    activeTab={activeTab}
                                    getColumnCount={getColumnCountEvents}
                                />
                            </>
                        }
                        {activeTab === "Leaderboards" && (
                            <div>
                                {
                                    brandBoards?.length === 0 ?
                                        <div className="flex flex-row font-bold text-xl justify-center items-center w-full mt-8">
                                            No boards found :( - Check back soon!
                                        </div>
                                        :
                                        <div>
                                            <div className="flex flex-row items-center justify-between my-4 mx-8">
                                                <div>
                                                    <IonSelect
                                                        className="w-full max-w-xs"
                                                        aria-label="Board"
                                                        color="theme-alternative"
                                                        interface="popover"
                                                        placeholder="Board"
                                                        value={selectedBoard?.name}
                                                        onIonChange={(e) => {
                                                            const board = brandBoards?.find((b: Board) => b.name === e.detail.value);
                                                            setSelectedBoard(board || null);
                                                        }}
                                                    >
                                                        {brandBoards?.map((board: Board) => (
                                                            <IonSelectOption key={board.id} value={board.name}>
                                                                {board.name}
                                                            </IonSelectOption>
                                                        ))}
                                                    </IonSelect>
                                                </div>

                                                {selectedBoard && (
                                                    <div>
                                                        <IonSelect
                                                            className="w-full max-w-xs"
                                                            aria-label="Sort"
                                                            color="theme-alternative"
                                                            interface="popover"
                                                            placeholder="Sort"
                                                            value={sortType}
                                                            onIonChange={(e) => setSortType(e.detail.value)}
                                                        >
                                                            {selectedBoard.sort.map((option) => (
                                                                <IonSelectOption key={option} value={option}>
                                                                    {option}
                                                                </IonSelectOption>
                                                            ))}
                                                        </IonSelect>
                                                    </div>
                                                )}
                                            </div>
                                            <EventBoardResultsList activeTab={activeTab} blurred={blurred} id={id} onShopRedirect={onShopRedirect} isEventBoardLoading={isEventBoardLoading} selectedBoard={selectedBoard} sortType={sortType} />
                                        </div>
                                }
                            </div>
                        )}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default BrandEventInfo;