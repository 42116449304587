import axios from "axios"
import { LocationInput } from "../models/locationInput";
import { UserTrick } from "../models/user-trick";
import { UserType } from "../models/usertype";
import { UserEnhancedDetails } from "../models/userEnhancedDetails";
import { SubscriptionType } from "../models/subscriptionType";
import PreferencesService from "./preferences.service";
import { FilmerInfo } from "../models/filmerInto";

const UserService = {
    getUserByUsername: async (username: string) => {
        const auth_id = await PreferencesService.getUserUid();

        const params = {
            auth_id: auth_id,
            username: username,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/retrieve/username`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    upsertUserProfileInformation: async (user_id: string, name: any, username: any, homeMountain: any,
        sports: string[], trickInterests: string[], instagram: any, tiktok: any,
        youtube: any, website: any, website_label: any, discord: any, twitter: any,
        push_notifications: any, setup: any) => {
        const fcm_token = await PreferencesService.getPushNotificationToken();

        const params = {
            infd_id: user_id,
            name: name,
            username: username,
            home_mountain: homeMountain,
            instagram: instagram,
            tiktok: tiktok,
            youtube: youtube,
            website: website,
            website_label: website_label,
            discord: discord,
            twitter: twitter,
            push_notifications: push_notifications,
            fcm_token: fcm_token,
            setup: setup
        };

        const data = {
            sports: sports,
            trick_interests: trickInterests
        }

        return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/info`, data, { params: params })
            .then((response) => {
                console.log("uploaded profile info");
                return response.data;
            }).catch((error) => {
                console.log("unable to upload profile info");
                console.error(error);
                return null;
            });
    },

    upsertUserProfileInformationOnboarding: async (
        user_id: string,
        name: string | null,
        username: string | null,
        birthday: string | null,
        sports: string[],
        level: number | null,
        terrain: string[],
        location_id: string | null,
        location_name: string | null
    ) => {
        // Ensure arrays are initialized even if empty
        const processedSports = Array.isArray(sports) ? sports : [];
        const processedTerrain = Array.isArray(terrain) ? terrain : [];

        const params = {
            infd_id: user_id,
            name: name || null,
            username: username || null,
            birthday: birthday || null,
            level: level || null,
            location_id: location_id || null,
            location_name: location_name || null
        };

        const data = {
            sports: processedSports.join(','),
            terrain: processedTerrain.join(',')
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/onboarding`, data, { params: params });
            console.log("uploaded onboarding profile info");
            return response.data;
        } catch (error) {
            console.log("unable to upload onboarding profile info");
            console.error(error);
            return null;
        }
    },

    uploadUserProfilePicture: async (user_id: string, data: FormData) => {
        const params = {
            infd_id: user_id
        };

        return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/picture`, data, { params: params })
            .then((response) => {
                console.log("uploaded profile picture");
                return response.data;
            }).catch((error) => {
                console.log("unable to upload profile picture");
                console.error(error);
                return null;
            });
    },

    updateUserProfilePictureByDefault: async (userId: string, profilePictureUrl: string) => {
        const params = {
            infd_id: userId,
            profile_pic_url: profilePictureUrl
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/profile_pic/default`,
                null,
                { params }
            );
            console.log("updated profile picture from URL");
            return response.data;
        } catch (error) {
            console.error("unable to update profile picture from URL");
            console.error(error);
            return null;
        }
    },

    getDefaultProfilePictures: async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/profile_pic/default`);
            console.log("updated profile picture from URL");
            return response.data;
        } catch (error) {
            console.error("unable to update profile picture from URL");
            console.error(error);
            return [];
        }
    },

    uploadUserTrick: async (infd_id: string, trick_name: string, tags: string[], film_date: string, location: string,
        sport: string, feature: string, caption: string, data: FormData, locationDetails: LocationInput,
        unlisted: boolean, clipType: string, filmerInfo: FilmerInfo) => {
        let params: any = {
            infd_id: infd_id,
            trick_name: trick_name,
            film_date: film_date,
            location: location,
            sport: sport,
            feature: feature,
            caption: caption,
            tags: tags.join(),
            unlisted: unlisted,
            clip_type: clipType,
            tagged_user_id: filmerInfo?.user_id
        };

        if (locationDetails) {
            params = {
                ...params,
                location_details: JSON.stringify(locationDetails)
            }
        }

        return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/upload`, data, { params: params })
            .then((response) => {
                console.log("uploaded user trick");
                return response.data;
            }).catch((error) => {
                console.log("unable to upload trick");
                console.error(error);
                return null;
            });
    },

    uploadUserTrickAsLink: async (infd_id: string, trick_name: string, tags: string[], film_date: string, location: string,
        sport: string, feature: string, caption: string, link: string, locationDetails: LocationInput,
        unlisted: boolean, linkLat: string, linkLng: string, linkAlt: string, creationDate: string,
        clipType: string, filmerInfo: FilmerInfo) => {
        let params: any = {
            infd_id: infd_id,
            trick_name: trick_name,
            film_date: film_date,
            location: location,
            sport: sport,
            feature: feature,
            caption: caption,
            tags: tags.join(),
            link: link,
            unlisted: unlisted,
            clip_type: clipType,
            tagged_user_id: filmerInfo?.user_id
        };

        if (locationDetails) {
            params = {
                ...params,
                location_details: JSON.stringify(locationDetails)
            }
        }

        if (linkLat) {
            params = {
                ...params,
                lat: linkLat
            }
        }

        if (linkLng) {
            params = {
                ...params,
                long: linkLng
            }
        }

        if (linkAlt) {
            params = {
                ...params,
                altitude: linkAlt
            }
        }

        if (creationDate) {
            params = {
                ...params,
                creation_time: creationDate
            }
        }

        return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/upload/link`, null, { params: params })
            .then((response) => {
                console.log("uploaded user trick");
                return response.data;
            }).catch((error) => {
                console.log("unable to upload trick");
                console.error(error);
                return null;
            });
    },

    uploadUserTrickAsExternalLink: async (infd_id: string, trick_name: string, tags: string[], film_date: string, location: string,
        sport: string, feature: string, caption: string, link: string, locationDetails: LocationInput,
        unlisted: boolean, clipType: string, filmerInfo: FilmerInfo) => {
        let params: any = {
            infd_id: infd_id,
            trick_name: trick_name,
            film_date: film_date,
            location: location,
            sport: sport,
            feature: feature,
            caption: caption,
            tags: tags.join(),
            link: link,
            unlisted: unlisted,
            clip_type: clipType,
            tagged_user_id: filmerInfo?.user_id
        };

        return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/upload/external_link`, null, { params: params })
            .then((response) => {
                console.log("uploaded user trick");
                return response.data;
            }).catch((error) => {
                console.log("unable to upload trick");
                console.error(error);
                return null;
            });
    },

    downloadUserTrickAsBlob: async (url: string) => {
        return axios.get(url, { responseType: 'blob' })
            .then((response) => {
                console.log("downloaded compressed trick");
                return response.data;
            }).catch((error) => {
                console.log("unable to upload trick");
                console.error(error);
                return null;
            });
    },

    uploadUserTrickAssemble: async (infd_id: string, filename: string, file_ext: string) => {
        let params: any = {
            infd_id: infd_id,
            filename: filename,
            file_ext: file_ext
        };

        // Make sure I can avoid CORS errors
        console.log("Calling user/trick/upload/assemble");
        return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/upload/assemble`, null, { params: params })
            .then((response) => {
                console.log("uploaded user trick");
                return response.data;
            }).catch((error) => {
                console.log("unable to upload trick");
                console.error(error);
                return null;
            });
    },

    uploadTrickInChunks: async (data: FormData, infd_id: string, filename: string) => {
        let params: any = {
            infd_id: infd_id,
            filename: filename
        };

        // Make sure I can avoid CORS errors
        console.log("Calling user/trick/upload/chunk");
        return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/upload/chunk`, data, { params: params })
            .then((response) => {
                console.log("uploaded user chunk");
                return "success";
            }).catch((error) => {
                console.log("unable to user chunk");
                console.error(error);
                return "failed";
            });
    },

    updateUserTrick: async (userTrick: UserTrick, authId: string, locationDetails: LocationInput, filmerInfo: FilmerInfo) => {
        let params: any = {
            auth_id: authId,
            trick_id: userTrick.trick_id,
            trick_name: userTrick.trick_name,
            date: userTrick.date,
            date_uploaded: userTrick.date_uploaded,
            location: userTrick.location,
            sport: userTrick.sport,
            terrain: userTrick.terrain,
            tags: userTrick.tags.join("%"),
            description: userTrick.description,
            clip_type: userTrick.clip_type
        };

        if (locationDetails) {
            params = {
                ...params,
                location_details: JSON.stringify(locationDetails)
            }
        }

        if (filmerInfo) {
            params = {
                ...params,
                tagged_user_id: filmerInfo?.user_id
            }
        }

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/upsert`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getBiskRewardForUpload: async (user_id: string) => {
        const params = {
            infd_id: user_id
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/upload/bisk`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    postBiskRewardForUpload: async (user_id: string, reward: number, message: string) => {
        const params = {
            infd_id: user_id,
            reward: reward,
            message: message
        }

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/upload/bisk`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },


    getUserPublicById: async (user_id: string) => {
        const params = {
            user_id: user_id
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/retrieve/public`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserInteractionProfiles: async (users_interacted_with: string[], user_types: string[]) => {
        const params = {
            user_interaction_ids: users_interacted_with.join(","),
            user_types: user_types.join(",")
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/interactions`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserProfilePic: async (infd_id: string) => {
        const params = {
            infd_id: infd_id
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/retrieve/profile_pic`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserByAuthId: async (auth_id: string) => {
        const params = {
            auth_id: auth_id
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/retrieve`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getIsUserGod: async (auth_id: string) => {
        const params = {
            auth_id: auth_id
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/admin/god`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserInteractionsCount: async (user_id: string) => {
        const params = {
            id: user_id
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/interactions/count`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserTrickSaved: async (trickId: string, userId: string) => {
        const params = {
            trick_id: trickId,
            user_id: userId
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/save`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserTrickLikesCount: async (trickId: string) => {
        const params = {
            trick_id: trickId
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/likes/count`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserTrickLikes: async (trickId: string) => {
        const params = {
            trick_id: trickId
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/likes`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserSavedVideos: async (infd_id: string) => {
        const params = {
            infd_id: infd_id
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/saves`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserTaggedVideos: async (infd_id: string) => {
        const params = {
            infd_id: infd_id
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/tagged`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserTaggedForVideo: async (trickId: string) => {
        const params = {
            trick_id: trickId
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/tagged`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    saveTrick: async (trickId: string, userId: string, save: boolean) => {
        // post to users service
        const params = {
            trick_id: trickId,
            user_id: userId,
            save: save
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/save`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserTrickLike: async (trickId: string, auth_id: string) => {
        const params = {
            trick_id: trickId,
            user_id: auth_id
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/like`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    likeTrick: async (trickId: string, userId: string, like: boolean) => {
        // post to users service
        const params = {
            trick_id: trickId,
            user_id: userId,
            like: like
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/like`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getCommentCountForTrick: async (trickId: string) => {
        // post to users service
        const params = {
            trick_id: trickId,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/comments/count`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    commentTrick: async (trickId: string, userId: string, comment: string, action_id: string) => {
        // post to users service
        const params = {
            trick_id: trickId,
            user_id: userId,
            comment: comment,
            action_id: action_id
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/comments`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    likeComment: async (trickId: string, userId: string, commentId: string, like: boolean) => {
        // post to users service
        const params = {
            trick_id: trickId,
            user_id: userId,
            comment_id: commentId,
            like: like
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/comments/like`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    editComment: async (commentId: string, userId: string, comment: string) => {
        // post to users service
        const params = {
            action_id: commentId,
            user_id: userId,
            content: comment,
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/comments/edit`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    deleteComment: async (commentId: string, userId: string, trickId: string) => {
        // post to users service
        const params = {
            action_id: commentId,
            user_id: userId,
            trick_id: trickId
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/comments/delete`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    replyComment: async (trickId: string, userId: string, comment: string, action_id: string, parent_action_id: string) => {
        // post to users service
        const params = {
            trick_id: trickId,
            user_id: userId,
            comment: comment,
            action_id: action_id,
            parent_action_id: parent_action_id
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/comments/reply`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getCommentLikes: async (commentId: string) => {
        // post to users service
        const params = {
            comment_id: commentId,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/comments/like`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getCommentReplies: async (commentId: string, userId: string) => {
        // post to users service
        let params: any = {
            comment_id: commentId,
        };

        if (userId) {
            params = {
                ...params,
                user_id: userId
            }
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/comments/reply`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getTrickComments: async (trickId: string, userId: string) => {
        let params: any = {
            trick_id: trickId,
        };

        if (userId) {
            params = {
                ...params,
                user_id: userId
            }
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/trick/comments`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    deleteTrick: async (trickId: string, userId: string) => {
        const params = {
            trick_id: trickId,
            infd_id: userId,
        };

        const searchDeleteParams = {
            trick_id: trickId,
        }

        axios.post(`${process.env.REACT_APP_MCTWIST_SEARCH_SERVER}/delete/user/trick`, null, { params: searchDeleteParams })
            .then((response) => {
                console.log(response.data);
            }).catch((error) => {
                console.error(error);
                return null;
            });

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/delete`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    followUserV2: async (userId: string, followingId: string, follow: boolean) => {
        const params = {
            follower_id: userId,
            following_id: followingId,
            follow: follow
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/follow`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getFollowingTricks: async (userId: string, date: string, numberOfTricks: number) => {
        const params = {
            infd_id: userId,
            date: date,
            number_of_tricks: numberOfTricks
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/following/tricks`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserSuggestedTricks(userId: string, numberOfSuggestedTricks: number) {
        const params = {
            infd_id: userId,
            number_of_tricks: numberOfSuggestedTricks
        };

        return axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/metadata/tricks/suggested_tricks`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    deleteUser: async (id: string) => {
        let auth_id_param = id;
        if (!id) {
            const auth_id_storage = await PreferencesService.getUserUid();
            auth_id_param = auth_id_storage;
        }
        const userDeleteParams = {
            auth_id: auth_id_param,
        };

        axios.post(`${process.env.REACT_APP_MCTWIST_SEARCH_SERVER}/delete/user_profile`, null, { params: userDeleteParams })
            .then((response) => {
                console.log(response.data);
            }).catch((error) => {
                console.error(error);
                return null;
            });

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/delete`, null, { params: userDeleteParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    deleteUserV2: async (id: string) => {
        let auth_id_param = id;
        if (!id) {
            const auth_id_storage = await PreferencesService.getUserUid();
            auth_id_param = auth_id_storage;
        }
        const userDeleteParams = {
            auth_id: auth_id_param,
        };

        axios.post(`${process.env.REACT_APP_MCTWIST_SEARCH_SERVER}/delete/user_profile`, null, { params: userDeleteParams })
            .then((response) => {
                console.log(response.data);
            }).catch((error) => {
                console.error(error);
                return null;
            });

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/delete`, null, { params: userDeleteParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    updateUserPushNotifications: async (authId: string, push_notification_token: string) => {
        const params = {
            auth_id: authId,
            fcm_token: push_notification_token
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/notifications`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserType: async (auth_id: string) => {
        // Would need to change this function ->
        // maybe scan the user object and then the brand object for a list of potential user types
        const userType = await PreferencesService.getUserType();

        if (userType) {
            if (userType !== UserType.UNKNOWN) {
                return userType;
            }
        }

        const params = {
            auth_id: auth_id,
        };

        const response = await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/type`, { params: params })
        await PreferencesService.setUserType(response.data);

        return response.data;
    },

    createUser: async (uid: string, email: string, displayName: string) => {
        const params = {
            user_id: uid,
            email: email,
            name: displayName,
            group_id: '',
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/create/login`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    checkCreateUser: async (uid: string, email: string, displayName: string) => {
        const user = await UserService.getUserByAuthId(uid);
        console.log("USER DATA FOR LOGGED IN USER CHECK: ", user);
        if (!user) {
            console.log("User has been created");
            const user = await UserService.createUser(uid, email, displayName);
            return user;
        } else {
            console.log("USER ALREADY EXISTS");
            return true;
        }
    },

    createUserOnboardingPhone: async (uid: string, phone: string, name: string, username: string) => {
        const params = {
            user_id: uid,
            name: name,
            phone: phone,
            group_id: '',
            username: username
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/create/onboarding/phone`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    createUserOnboardingEmail: async (uid: string, email: string, name: string, username: string) => {
        const params = {
            user_id: uid,
            name: name,
            email: email,
            group_id: '',
            username: username
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/create/onboarding/email`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    setUser: async (update: Boolean, dispatch: any) => {
        try {
            // check if we already have the data in storage - trying to keep changing state
            console.log("Setting user");
            if (!update) {
                return;
            }

            const auth_id = await PreferencesService.getUserUid();

            if (!auth_id) {
                console.log("NO AUTH ID");
                return;
            }

            const params = {
                auth_id: auth_id,
            };

            // I think you would have to check in preferences whether the user has switched to "brand mode" or "user mode"
            // Then in the brand object, you would have a list of admins with their auth_ids
            // If the preferences is nothing or user mode, then you would just get the user object
            const user_type = await UserService.getUserType(auth_id);

            // If the user mode is a brand, however, the user is normal user and there is a cooresponding id with that user for that brand,
            // then we need to get the brand object via the brand service via the brand id

            // Furthermore, we need to have all likes and comments and everything go through the user id/the brand id and not the auth_id

            // Finally, the user must have an api token to do this

            if (user_type === UserType.USER) {
                await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/retrieve`, { params: params })
                    .then(async (response) => {
                        if (response.data) {
                            dispatch({ type: "setUser", payload: response.data });

                            // TODO: get all data in one call
                            try {
                                const userEnhancedDetails: UserEnhancedDetails = await UserService.getUserEnhancedDetails(response.data.id);
                                console.log(userEnhancedDetails);
                                dispatch({ type: "setBisk", payload: userEnhancedDetails.bisk });
                                dispatch({ type: "setIsSubscribed", payload: userEnhancedDetails.is_subscribed });
                                dispatch({
                                    type: "setSubscriptionLevel",
                                    payload: userEnhancedDetails.subscription_level
                                });
                                dispatch({ type: "setFollowing", payload: userEnhancedDetails.following_count });
                                dispatch({ type: "setFollowers", payload: userEnhancedDetails.followers_count });
                                dispatch({ type: "setBlockedUsers", payload: userEnhancedDetails.blocked_users });
                                dispatch({ type: "setBlockingUsers", payload: userEnhancedDetails.blocking_users });
                            } catch (error) {
                                console.log("Unable to get bisk");
                                dispatch({ type: "setBisk", payload: 0 });
                                dispatch({ type: "setIsSubscribed", payload: false });
                                dispatch({ type: "setSubscriptionLevel", payload: SubscriptionType.None });
                                dispatch({ type: "setFollowing", payload: 0 });
                                dispatch({ type: "setFollowers", payload: 0 });
                                dispatch({ type: "setBlockedUsers", payload: [] });
                                dispatch({ type: "setBlockingUsers", payload: [] });
                            }

                            return response.data;
                        }
                    }).catch((error) => {
                        console.error(error);
                        return;
                    });
            } else if (user_type === UserType.BRAND) {
                await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand`, { params: params })
                    .then((response) => {
                        if (response.data) {
                            dispatch({ type: "setUser", payload: response.data });
                            return response.data;
                        }
                    }).catch((error) => {
                        console.error(error);
                        return;
                    });
            } else {
                return;
            }
        } catch (error) {
            console.log(error);
        }
    },

    getTrendingTricks: async (numberOfTricks: number, score: string, hotSettings: string) => {

        const params = {
            score: score,
            number_of_tricks: numberOfTricks,
            period: hotSettings
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/metadata/tricks/trending`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getTrendingTricksMore: async (numberOfTricks: number, trick_id: string, hotSettings: string) => {

        const params = {
            number_of_tricks: numberOfTricks,
            trick_id: trick_id,
            period: hotSettings
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/metadata/tricks/trending/more`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getNewUserTricks: async (numberOfTricks: number, date: string) => {

        const params = {
            number_of_tricks: numberOfTricks,
            date: date
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/metadata/tricks/new/user`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getNewCompetitionTricks: async (numberOfTricks: number, date: string) => {

        const params = {
            number_of_tricks: numberOfTricks,
            date: date
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/metadata/tricks/new/competition`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getTricksFromIds(trick_ids: string[]) {
        const params = {
            trick_ids: trick_ids.join(',')
        };

        return axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/tricks/list`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    // DEPRECATED
    getUserTrickPreferences(infd_id: string) {
        return axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/trick/preferences`, { params: { infd_id: infd_id } })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserPreferences(infd_id: string) {
        return axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/preferences`, { params: { infd_id: infd_id } })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    upsertUserTrickPreferences(auth_id: string, trick_ids: string[], trick_id_positions: number[]) {
        const trick_ids_str = trick_ids.join(",");
        const trick_id_positions_str = trick_id_positions.join(",");

        const params = {
            auth_id: auth_id,
            trick_ids: trick_ids_str,
            trick_id_positions: trick_id_positions_str
        }

        return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/trick/preferences`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    upsertUserPreferences(auth_id: string, trick_ids: string[], trick_id_positions: number[], segment: string) {
        const trick_ids_str = trick_ids.join(",");
        const trick_id_positions_str = trick_id_positions.join(",");

        const params = {
            auth_id: auth_id,
            trick_ids: trick_ids_str,
            trick_id_positions: trick_id_positions_str,
            segment: segment
        }

        return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/preferences`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getFollowCount: async (user_id: string, followers: boolean) => {
        const params = {
            user_id: user_id,
            follow: followers
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/follow/count`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    checkIfUserFollowingOtherUser: async (user_id: string, following_id: string) => {
        const params = {
            follower_id: user_id,
            following_id: following_id
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v2/user/follow`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUsersFollowFull: async (user_id: string, role: string, page: number, page_size: number, followers: boolean) => {
        const params = {
            user_id: user_id,
            role: role,
            page: page,
            page_size: page_size,
            follow: followers
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/v3/users/follow/full`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserEnhancedDetails: async (user_id: string) => {
        const params = {
            id: user_id
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/details`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getBrandsFromIds: async (user_ids: string[]) => {
        const params = {
            ids: user_ids.join(',')
        };

        return axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brands`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    blockUser: async (blocker_id: string, blocked_id: string, block: boolean) => {
        const params = {
            blocker_id: blocker_id,
            blocked_id: blocked_id,
            block: block
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/block`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    checkIfBlockedUser: async (user_id: string, blocked_id: string) => {
        const params = {
            user_id: user_id,
            blocked_id: blocked_id
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/block`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    checkIfBlockingUser: async (user_id: string, blocker_id: string) => {
        const params = {
            user_id: user_id,
            blocker_id: blocker_id,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/blocked`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getBlockedUsers: async (user_id: string) => {
        const params = {
            user_id: user_id
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/blocks`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return [];
            });
    },

    postAuthToken: async (authToken: string, platform: string) => {
        const params = {
            incoming_token: authToken,
            token_provider: platform
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/auth/token_exchange`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return [];
            });
    },

    getRandomAssUsername: async () => {
        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/random_ass_username`)
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserLocationsOnboardingLocation: async (page: number, lat: number, long: number) => {
        const params = {
            page: page,
            lat: lat,
            long: long
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/location/onboarding`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserLocationsOnboarding: async (page: number) => {
        const params = {
            page: page
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/location/onboarding`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserLocationsOnboardingBase: async (query: string, page: number) => {
        const params = {
            page: page,
            query: query
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/location/base`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    searchForLocationViaGoogle: async (query: string, sessiontoken: string) => {
        const params = {
            query: query,
            sessionToken: sessiontoken
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/location/base/google`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return [];
            });
    },

    getUserDisplayProPreference: async (infdId: string) => {
        const params = {
            infd_id: infdId,
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/display_pro`, { params });
            return response.data;
        } catch (error) {
            console.error('Error fetching display_pro preference:', error);
            return null;
        }
    },

    updateUserDisplayProPreference: async (infdId: string, displayPro: boolean) => {
        const data = {
            infd_id: infdId,
            display_pro: displayPro,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/profile/display_pro`, null, { params: data });
            return response.data;
        } catch (error) {
            console.error('Error updating display_pro preference:', error);
            throw error;
        }
    },
};

export default UserService;
