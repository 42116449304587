import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../../AppStateProvider";
import UserService from "../../../services/user.service";
import { useAppState } from "../../../AppListenerProvider";
import PreferencesService from "../../../services/preferences.service";

const AppRedirect: React.FC<any> = () => {
    let history = useHistory();

    const { isActive, isNative } = useAppState(); // Using isActive from AppActiveProvider
    const { state, dispatch } = React.useContext(AppContext);

    useEffect(() => {
        const redirectIfActive = async () => {
            if (isActive && isNative) {
                const redirect_url = await PreferencesService.getPushNotificationRedirectUrl();
                if (redirect_url) {
                    await PreferencesService.removePushNotificationRedirectUrl();
                    await UserService.setUser(true, dispatch); // Update state
                    history.push(redirect_url);
                }
            }
        };

        redirectIfActive();
    }, [isActive, history, dispatch, isNative]);

    useEffect(() => {
        const handlePushNotification = async () => {
            const redirect_url = state.push_notification_redirect_url;
            if (redirect_url && redirect_url != "") {
                await UserService.setUser(true, dispatch); // state update
                dispatch({ type: "clearPushNotificationRedirectUrl", payload: null });
                console.log("TRIGGERING REDIRECT: " + redirect_url);
                history.push(redirect_url);
            }
        }

        if (isNative) {
            handlePushNotification();
        }
    }, [state.push_notification_redirect_url, history, isNative]);

    return null;
};

export default AppRedirect;
