import React, { useContext, useEffect } from "react";
import basicBitch from "../../../assets/icons/system/idXBasicBadge.svg";
import premiumHo from "../../../assets/icons/system/idXPremiumBadge.svg";
import one from "../../../assets/icons/streaks/1.svg";
import two from "../../../assets/icons/streaks/2.svg";
import three from "../../../assets/icons/streaks/3.svg";
import four from "../../../assets/icons/streaks/4.svg";
import five from "../../../assets/icons/streaks/5.svg";
import six from "../../../assets/icons/streaks/6.svg";
import seven from "../../../assets/icons/streaks/7.svg";
import eight from "../../../assets/icons/streaks/8.svg";
import nine from "../../../assets/icons/streaks/9.svg";
import ten from "../../../assets/icons/streaks/10.svg";
import eleven from "../../../assets/icons/streaks/11.svg";
import twelve from "../../../assets/icons/streaks/12.svg";
import time from "../../../assets/icons/streaks/time.svg";
import { IonIcon } from "@ionic/react";
import { SubscriptionType } from "../../../models/subscriptionType";
import { AppContext } from "../../../AppStateProvider";
import BiskService from "../../../services/bisk.service";
import { useQuery } from "@tanstack/react-query";
import { UserStreak } from "../../../models/userstreak";
import { getAdjustedTiming, QueryTiming } from "../../../hooks/useQueryConfig";

// Add this streak mapping right after the imports and before the interface
const getStreakIcon = (streakLength: number) => {
    if (streakLength <= 0) return null;
    if (streakLength > 12) streakLength = 12;

    const streakMap = {
        1: one,
        2: two,
        3: three,
        4: four,
        5: five,
        6: six,
        7: seven,
        8: eight,
        9: nine,
        10: ten,
        11: eleven,
        12: twelve
    };

    return streakMap[streakLength as keyof typeof streakMap];
};

interface ContainerProps {
    username: string,
    className: string,
    userId?: string,
    onUsernameClickHandler?: (username: string) => void,
    loggedInId?: string
    bypassState?: boolean
    isProfile?: boolean
    onStreakClick?: (streakData: UserStreak) => void
}

const UsernameDisplay: React.FC<ContainerProps> = ({
    username,
    className,
    userId,
    onUsernameClickHandler,
    loggedInId,
    bypassState = false,
    isProfile = false,
    onStreakClick
}) => {
    const { state } = useContext(AppContext);
    const [usernameDisplay, setUsernameDisplay] = React.useState<string>('');

    // Query for subscription data
    const { data: subscriptionData } = useQuery({
        queryKey: ['subscription', userId || username],
        queryFn: async () => {
            if (loggedInId && userId && loggedInId === userId && !bypassState) {
                return {
                    isSubscribed: state.isSubscribed,
                    subscriptionLevel: state.subscriptionLevel
                };
            }

            if (userId) {
                const isSubscribed = await BiskService.getIsSubscribedById(userId);
                const subscriptionLevel = isSubscribed
                    ? await BiskService.getSubscriptionLevelById(userId)
                    : SubscriptionType.None;
                return { isSubscribed, subscriptionLevel };
            }

            const isSubscribed = await BiskService.getIsSubscribedByUsername(username);
            const subscriptionLevel = isSubscribed
                ? await BiskService.getSubscriptionLevelByUsername(username)
                : SubscriptionType.None;
            return { isSubscribed, subscriptionLevel };
        },
        enabled: !!username,
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
    });

    // Add streak query
    const { data: streakData } = useQuery({
        queryKey: ['streak', userId],
        queryFn: async () => {
            if (!userId) return null;
            return await BiskService.getUserStreak(userId);
        },
        enabled: !!userId && isProfile,
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
    });

    useEffect(() => {
        if (username) {
            setUsernameDisplay(username);
        }
    }, [username]);

    const onUsernameClick = () => {
        if (!onUsernameClickHandler) return;
        onUsernameClickHandler(username)
    }

    return (
        <div className={className + " flex flex-row items-center"} onClick={onUsernameClick}>
            {usernameDisplay}
            {subscriptionData?.isSubscribed && (
                <IonIcon
                    src={subscriptionData.subscriptionLevel === SubscriptionType.Basic ? basicBitch : premiumHo}
                    className={"w-4 h-4 ml-2"}
                />
            )}
            {streakData?.streak_length > 0 && isProfile && (
                <IonIcon
                    src={getStreakIcon(streakData.streak_length)}
                    onClick={isProfile ? () => onStreakClick(streakData) : undefined}
                    className={"w-4 h-4 ml-2"}
                />
            )}
            {streakData?.is_expiring && isProfile && (
                <IonIcon src={time} className={"w-5 h-5 ml-2"} />
            )}
        </div>
    );
};

export default UsernameDisplay;