import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import {
    IonAccordion,
    IonAccordionGroup,
    IonAlert,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonTitle,
    IonToggle,
    IonToolbar,
    useIonModal,
    useIonToast
} from "@ionic/react";
import { InAppReview } from "@capacitor-community/in-app-review";
import { chatbubbleEllipsesOutline, chatbubbleOutline, chevronBackOutline, clipboardOutline, handLeft, logoDiscord, mailOpen, newspaper, pulse, shirt, star } from "ionicons/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ClipType } from "../models/clipType";
import { SportType } from "../models/sportType";
import SettingsService from "../services/settings.service";
import UserService from "../services/user.service";
import { ALL_CLIPS } from "../services/utils";
import { AppContext } from "../AppStateProvider";
import { Clipboard } from "@capacitor/clipboard";
import SubscribeButton from "../components/ComponentsPurchases/SubscribeButton/SubscribeButton";
import RestorePurchasesButton from "../components/ComponentsPurchases/RestorePurchasesButton/RestorePurchasesButton";
import LogoutButton from "../components/ComponentsLogin/LogoutButton/LogoutButton";
import useAuthService from "../hooks/useAuthService";
import { IonToggleCustomEvent, OverlayEventDetail, ToggleChangeEventDetail } from "@ionic/core/components";
import InfiniteDegrees from "../components/ComponentsPurchases/InfiniteDegrees/InfiniteDegrees";
import { useAuth } from "../AuthProvider";
import PreferencesService from "../services/preferences.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAdjustedTiming, QueryTiming } from "../hooks/useQueryConfig";
import { AppLauncher } from '@capacitor/app-launcher';

const Settings: React.FC = () => {
    const { dispatch, state } = useContext(AppContext);
    const { isAuthenticated, isLoading } = useAuth();
    const { login } = useAuthService();
    const history = useHistory();
    const location = useLocation();
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

    const isNative = Capacitor.isNativePlatform();
    const platform = Capacitor.getPlatform();
    const [isAppRateWebOpen, setIsAppRateWebOpen] = useState<boolean>(false);
    const [isAppFeedbackOpen, setIsAppFeedbackOpen] = useState<boolean>(false);
    const [isAppAnnoucementOpen, setIsAppAnnoucementOpen] = useState<boolean>(false);

    const [present] = useIonToast();
    const queryClient = useQueryClient();

    const [presentInfiniteDegreesCommunity, dismissInfiniteDegreesCommunity] = useIonModal(InfiniteDegrees, {
        onDismiss: (data: string, role: string) => dismissInfiniteDegreesCommunity(data, role),
    });

    const {
        data: displayProPreference, // Now just a boolean
        isLoading: isLoadingDisplayProPreference
    } = useQuery({
        queryKey: ['userDisplayProPreference', state.user.id],
        queryFn: () => UserService.getUserDisplayProPreference(state.user.id),
        enabled: !!state.user.id,
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
    });

    const { mutate: updateDisplayPro, isPending: isUpdating } = useMutation({
        mutationFn: (newValue: boolean) =>
            UserService.updateUserDisplayProPreference(state.user.id, newValue),
        onMutate: async (newValue) => {
            await queryClient.cancelQueries({
                queryKey: ['userDisplayProPreference', state.user.id]
            });

            // Store previous boolean value
            const previousValue = queryClient.getQueryData<boolean>(
                ['userDisplayProPreference', state.user.id]
            );

            // Set new boolean value directly
            queryClient.setQueryData(
                ['userDisplayProPreference', state.user.id],
                newValue
            );

            return { previousValue };
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: ['userDisplayProPreference', state.user.id]
                }),
                queryClient.invalidateQueries({
                    queryKey: ['tvTricks']
                })
            ]);
        },
        onError: (error, newValue, context) => {
            if (context?.previousValue !== undefined) {
                queryClient.setQueryData(
                    ['userDisplayProPreference', state.user.id],
                    context.previousValue
                );
            }
            console.error('Failed to update display preference:', error);
        }
    });

    // Updated to handle IonToggle event
    const handleDisplayProChange = (event: IonToggleCustomEvent<ToggleChangeEventDetail>) => {
        try {
            // Access the checked value from the event detail
            const newValue = event.detail.checked;
            console.log("Display Pro Preference changed to:", newValue);
            updateDisplayPro(newValue);
        } catch (error) {
            console.error('Failed to update display preference:', error);
        }
    };

    useEffect(() => {
        window.prerenderReady = true;
    }, []);

    const handleProgressWithPros = (event: any) => {
        // open Infinite Degrees Modal
        presentInfiniteDegreesCommunity({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    useEffect(() => {
        const getFeedSettings = async () => {
            const feedSettings = await PreferencesService.getFeedSettings();

            if (feedSettings) {
                const parsedFeedSettings = JSON.parse(feedSettings);
                setSelectedValues(parsedFeedSettings);
            } else {
                setSelectedValues([]);
                setFeedSettings([]);
            }
        }

        getFeedSettings();

        if (!accordionGroup.current) {
            return;
        }
    }, []);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!isAuthenticated) {
            history.push('/');
            history.go(0);
        }

    }, [isAuthenticated, isLoading, history]);

    const presentToast = async (message: string, duration: number, icon: string) => {
        await present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    const setFeedSettings = async (selectedValues: string[]) => {
        dispatch({ type: 'setFeedSettings', payload: selectedValues });
        await PreferencesService.setFeedSettings(JSON.stringify(selectedValues));
    }

    const isAllExceptAllSelected = (values: string[], selectedValue: string) => {
        const combinedValues = [...values, selectedValue];
        return (
            combinedValues.includes(ClipType.CLIP) &&
            combinedValues.includes(ClipType.LINE) &&
            combinedValues.includes(ClipType.EDIT) &&
            combinedValues.includes(SportType.SKIING) &&
            combinedValues.includes(SportType.SNOWBOARDING) &&
            !combinedValues.includes(ALL_CLIPS)
        );
    };

    const handleToggleChange = async (selectedValue: string): Promise<string[]> => {
        if (selectedValue === ALL_CLIPS) {
            setSelectedValues([]);
            await setFeedSettings([]);
            return [];
        } else {
            setSelectedValues((prevSelectedValues) => {
                if (prevSelectedValues.includes(ALL_CLIPS)) {
                    // Remove "All" from selectedValues
                    const updatedValues = prevSelectedValues.filter(
                        (value) => value !== ALL_CLIPS
                    );
                    setSelectedValues([...updatedValues, selectedValue]);
                    setFeedSettings([...updatedValues, selectedValue]);
                    return [...updatedValues, selectedValue];
                } else if (isAllExceptAllSelected(prevSelectedValues, selectedValue)) {
                    // Deselect all and select "All" if all options are currently selected
                    setSelectedValues([]);
                    setFeedSettings([]);
                    return [];
                } else {
                    // Toggle the selected value
                    if (prevSelectedValues.includes(selectedValue)) {
                        setSelectedValues(prevSelectedValues.filter((value) => value !== selectedValue))
                        setFeedSettings(prevSelectedValues.filter((value) => value !== selectedValue));
                        return prevSelectedValues.filter((value) => value !== selectedValue);
                    } else {
                        setFeedSettings([...prevSelectedValues, selectedValue])
                        setSelectedValues([...prevSelectedValues, selectedValue]);
                        return [...prevSelectedValues, selectedValue];
                    }
                }
            });
        }
    };

    const handleBack = () => {
        if (location.key) {
            console.log("Back button clicked");
            history.go(-1);
        } else {
            history.push("/home")
        }
    }

    const openidXAppStore = async () => {
        if (platform == 'ios') {
            await Browser.open({ url: 'https://apps.apple.com/us/app/ecliptic-ski-snowboard-app/id1607046376' });
        } else if (platform == 'android') {
            await Browser.open({ url: 'https://play.google.com/store/apps/details?id=xyz.infinitedegrees.ride' });
        } else {
            console.log("Platform not supported");
            await Browser.open({ url: 'https://instagram.com/ecliptic.day' });
        }
    }

    const calcDaysSinceLastPrompt = (timeSinceLastPrompt: string) => {
        const dateSinceLastPrompt = new Date(timeSinceLastPrompt);
        const currentDate = new Date();
        const timeDiff = currentDate.getTime() - dateSinceLastPrompt.getTime();
        const daysSinceLastPrompt = timeDiff / (1000 * 3600 * 24);
        return daysSinceLastPrompt;
    }

    const handleRateIdX = async () => {
        console.log("Rate Ecliptic button clicked");
        if (isNative) {
            // prompt + check to see if user has already clicked prompt
            const timeSinceLastPrompt = await SettingsService.getTimeSinceLastPrompt();
            if (timeSinceLastPrompt === null) {
                await SettingsService.setLastPromptTime();
                await InAppReview.requestReview();
            } else if (platform == 'ios') {
                const daysSinceLastPrompt = calcDaysSinceLastPrompt(timeSinceLastPrompt);
                if (daysSinceLastPrompt > 121) {
                    await SettingsService.setLastPromptTime();
                    await InAppReview.requestReview();
                } else {
                    await openidXAppStore();
                }
            } else if (platform == 'android') {
                const daysSinceLastPrompt = calcDaysSinceLastPrompt(timeSinceLastPrompt);
                if (daysSinceLastPrompt > 31) {
                    await SettingsService.setLastPromptTime();
                    await InAppReview.requestReview();
                } else {
                    await openidXAppStore();
                }
            } else {
                await openidXAppStore();
            }
        } else {
            setIsAppRateWebOpen(true);
            // open app store
            // Browser.open({ url: 'https://apps.apple.com/us/app/idx-pushing-progression/id1607046376' })
        }
    }

    const handleIdXFeedback = () => {
        setIsAppFeedbackOpen(true);
    }
    const handleidXAnnoucement = () => {
        setIsAppAnnoucementOpen(true);
    }

    const handleJoinDiscord = async () => {
        // open Discord
        await Browser.open({ url: 'https://discord.gg/u6VhZ6hMVE' });
    }

    const handleClub = async () => {
        // open Discord
        await Browser.open({ url: 'https://ecliptic.club', presentationStyle: "popover" });
    }

    const handleShop = async () => {
        // open Discord
        await Browser.open({ url: 'https://shop.ecliptic.club', presentationStyle: "popover" });
    }



    const handleShareIdX = async () => {
        console.log("Share IdX button clicked");
        const value = await PreferencesService.getUserUid();

        if (value === null) {
            if (isNative) {
                await Share.share({
                    title: 'Ecliptic',
                    text: 'Join me on Ecliptic, the best way to push your ski or snowboard progression, get recognized, share clips, and more',
                    url: 'https://ecliptic.day',
                    dialogTitle: 'Share Ecliptic'
                });
            } else {
                await Clipboard.write({
                    string: 'https://ecliptic.day'
                });
                await presentToast('Copied To Clipboard!', 1500, clipboardOutline);
            }
        } else {
            const user = await UserService.getUserByAuthId(value);
            const text = `Follow me on Ecliptic fam at ${user.username}, the best way to push your ski or snowboard progression, get recognized, share clips, and more`;
            if (isNative) {
                let url = "https://ecliptic.day"
                if (Capacitor.getPlatform() == 'ios') {
                    url = "https://apps.apple.com/us/app/idx-pushing-progression/id1607046376"
                } else if (Capacitor.getPlatform() == 'android') {
                    url = "https://play.google.com/store/apps/details?id=xyz.infinitedegrees.ride"
                } else {
                    url = "https://ecliptic.day"
                }

                await Share.share({
                    title: 'Ecliptic',
                    text: url,
                    url: 'https://ecliptic.day',
                    dialogTitle: 'Share Ecliptic'
                });
            } else {
                await Clipboard.write({
                    string: 'https://ecliptic.day'
                });
                await presentToast('Copied To Clipboard!', 1500, clipboardOutline);
            }
        }
    }

    const handleTextSupport = async () => {
        const supportNumber = ["+18556292475"]; // Replace with your actual support number
        const message = `Hey Ecliptic Support it's ${state.user.username} :0 I need help with: `;

        if (isNative) {
            // Fallback to default messaging app if SMS plugin fails
            const url = `sms:${supportNumber}`;
            try {
                await AppLauncher.openUrl({ url });
            } catch (browserError) {
                console.error('Error opening browser:', browserError);
            }
        } else {
            // Web platform - show toast with number
            await presentToast(
                'Text us at ' + supportNumber,
                4000,
                chatbubbleEllipsesOutline
            );
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar style={{ paddingTop: `calc(0.5rem + var(--ion-safe-area-top, 0))`, paddingBottom: "0.5rem" }}
                    mode="ios">
                    <IonButtons slot="start" onClick={handleBack}>
                        <IonButton color="theme-alternative">
                            <IonIcon slot="start" icon={chevronBackOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Settings</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonAccordionGroup ref={accordionGroup}>
                    <IonAccordion value="first">
                        <IonItem slot="header" color="light" className="bg-transparent">
                            <IonLabel>Feed</IonLabel>
                        </IonItem>
                        <div className="ion-padding" slot="content">
                            <IonItem>
                                <IonToggle
                                    checked={displayProPreference ?? true}
                                    disabled={isLoadingDisplayProPreference}
                                    onIonChange={handleDisplayProChange}
                                    color="favorite-secondary"
                                    justify="space-between">
                                    Display Pros
                                </IonToggle>
                            </IonItem>
                            <IonItem>
                                <IonToggle
                                    checked={selectedValues.length === 0}
                                    onIonChange={() => handleToggleChange(ALL_CLIPS)}
                                    color="favorite-secondary"
                                    justify="space-between">
                                    All
                                </IonToggle>
                            </IonItem>
                            <IonItem>
                                <IonToggle
                                    checked={selectedValues.includes(ClipType.CLIP)}
                                    onIonChange={() => handleToggleChange(ClipType.CLIP)}
                                    disabled={true}
                                    color="favorite-secondary"
                                    justify="space-between">
                                    Tricks
                                </IonToggle>
                            </IonItem>
                            <IonItem>
                                <IonToggle
                                    checked={selectedValues.includes(ClipType.LINE)}
                                    onIonChange={() => handleToggleChange(ClipType.LINE)}
                                    disabled={true}
                                    color="favorite-secondary"
                                    justify="space-between">
                                    Lines
                                </IonToggle>
                            </IonItem>
                            <IonItem>
                                <IonToggle
                                    checked={selectedValues.includes(ClipType.EDIT)}
                                    onIonChange={() => handleToggleChange(ClipType.EDIT)}
                                    disabled={true}
                                    color="favorite-secondary"
                                    justify="space-between">
                                    Edits
                                </IonToggle>
                            </IonItem>
                            <IonItem>
                                <IonToggle
                                    checked={selectedValues.includes(SportType.SKIING)}
                                    onIonChange={() => handleToggleChange(SportType.SKIING)}
                                    disabled={true}
                                    color="favorite-secondary"
                                    justify="space-between">
                                    Skiing
                                </IonToggle>
                            </IonItem>
                            <IonItem>
                                <IonToggle
                                    checked={selectedValues.includes(SportType.SNOWBOARDING)}
                                    onIonChange={() => handleToggleChange(SportType.SNOWBOARDING)}
                                    disabled={true}
                                    color="favorite-secondary"
                                    justify="space-between">
                                    Snowboarding
                                </IonToggle>
                            </IonItem>
                        </div>
                    </IonAccordion>
                </IonAccordionGroup>
                <IonItem button={true} onClick={() => handleShareIdX()}>
                    <IonLabel>Share with Homies</IonLabel>
                    <IonIcon color="theme-secondary" slot="end" icon={handLeft} size="default"></IonIcon>
                </IonItem>
                <IonItem button={true} onClick={() => handleRateIdX()}>
                    <IonLabel>Rate Ecliptic</IonLabel>
                    <IonIcon color="theme-secondary" slot="end" icon={star} size="default"></IonIcon>
                </IonItem>
                <IonAlert
                    subHeader="Rate Ecliptic With ❤️ :)"
                    mode="ios"
                    header="Rate Ecliptic"
                    isOpen={isAppRateWebOpen}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                                setIsAppRateWebOpen(false);
                            },
                        },
                        {
                            text: 'iOS',
                            role: 'confirm',
                            handler: () => {
                                setIsAppRateWebOpen(false);
                                Browser.open({
                                    url: 'https://apps.apple.com/us/app/ecliptic-ski-snowboard-app/id1607046376',
                                    windowName: '_blank'
                                });
                            },
                        },
                        {
                            text: 'Android',
                            role: 'confirm',
                            handler: () => {
                                setIsAppRateWebOpen(false);
                                Browser.open({
                                    url: 'https://play.google.com/store/apps/details?id=xyz.infinitedegrees.ride',
                                    windowName: '_blank'
                                });
                            },
                        },
                    ]}
                    onDidDismiss={({ detail }) => setIsAppRateWebOpen(false)}
                />
                <IonItem button={true} onClick={() => handleTextSupport()}>
                    <IonLabel>Text Ecliptic Support</IonLabel>
                    <IonIcon color="theme-secondary" slot="end" icon={chatbubbleEllipsesOutline} size="default"></IonIcon>
                </IonItem>
                <IonItem button={true} onClick={() => handleIdXFeedback()}>
                    <IonLabel>Email Ecliptic Support</IonLabel>
                    <IonIcon color="theme-alternate" slot="end" icon={pulse} size="default"></IonIcon>
                </IonItem>
                <IonAlert
                    subHeader="Help us help you shred. This immediately emails us your feedback, questions, info, or problems with Ecliptic so we can develop in house the best app for you. We want to hear it so be honest! Join our discord to chat with the community or text us for real time support!"
                    header="Email Ecliptic Support"
                    isOpen={isAppFeedbackOpen}
                    animated={true}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                                // @ts-ignore
                                document.getElementById('feedback-name').value = '';
                                // @ts-ignore
                                document.getElementById('feedback-comments').value = '';
                                setIsAppFeedbackOpen(false);
                            },
                        },
                        {
                            text: 'Submit',
                            role: 'confirm',
                            handler: async (alertData) => {
                                console.log(alertData);
                                await SettingsService.sendFeedback(alertData['name'], alertData['comments']);
                                // @ts-ignore
                                document.getElementById('feedback-name').value = '';
                                // @ts-ignore
                                document.getElementById('feedback-comments').value = '';
                                setIsAppFeedbackOpen(false);
                            },
                        },
                    ]}
                    inputs={[
                        {
                            placeholder: 'Name',
                            name: 'name',
                            value: '',
                            id: 'feedback-name'
                        },
                        {
                            type: 'textarea',
                            name: 'comments',
                            placeholder: 'Comments',
                            value: '',
                            id: 'feedback-comments'
                        },
                    ]}
                    onDidDismiss={({ detail }) => {
                        setIsAppFeedbackOpen(false);
                    }}
                />
                <IonItem button={true} onClick={() => handleidXAnnoucement()}>
                    <IonLabel>Submit an Announcement</IonLabel>
                    <IonIcon color="theme-alternate" slot="end" icon={mailOpen} size="default"></IonIcon>
                </IonItem>
                <IonAlert
                    subHeader="Let us know of a long form #filmbetter edit you dropped, a local rail jam, or whatever else you want shown to the Ecliptic community at the top. Give us links!"
                    header="Submit an Announcement"
                    isOpen={isAppAnnoucementOpen}
                    animated={true}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => {
                                // @ts-ignore
                                document.getElementById('idx-announcement-username').value = '';
                                // @ts-ignore
                                document.getElementById('idx-announcement-text').value = '';
                                setIsAppAnnoucementOpen(false);
                            },
                        },
                        {
                            text: 'Submit',
                            role: 'confirm',
                            handler: async (alertData) => {
                                console.log(alertData);
                                await SettingsService.sendFeedback(alertData['username'], alertData['announcement-comments']);
                                // @ts-ignore
                                document.getElementById('idx-announcement-username').value = '';
                                // @ts-ignore
                                document.getElementById('idx-announcement-text').value = '';
                                setIsAppAnnoucementOpen(false);
                            },
                        },
                    ]}
                    inputs={[
                        {
                            placeholder: 'Ecliptic Username',
                            name: 'username',
                            value: '',
                            id: 'idx-announcement-username'
                        },
                        {
                            type: 'textarea',
                            name: 'announcement-comments',
                            placeholder: 'Announcement',
                            value: '',
                            id: 'idx-announcement-text'
                        },
                    ]}
                    onDidDismiss={({ detail }) => {
                        setIsAppAnnoucementOpen(false);
                    }}
                />
                <IonItem button={true} onClick={() => handleJoinDiscord()}>
                    <IonLabel>Join Our Discord</IonLabel>
                    <IonIcon color="theme-alternate" slot="end" icon={logoDiscord} size="default"></IonIcon>
                </IonItem>
                <IonItem button={true} onClick={() => handleClub()}>
                    <IonLabel>Read The Ecliptic Club</IonLabel>
                    <IonIcon color="theme-alternate" slot="end" icon={newspaper} size="default"></IonIcon>
                </IonItem>
                <IonItem button={true} onClick={() => handleShop()}>
                    <IonLabel>Buy Our Merch</IonLabel>
                    <IonIcon color="theme-alternate" slot="end" icon={shirt} size="default"></IonIcon>
                </IonItem>
                <div className="flex flex-row gap-2 my-2 justify-center">
                    {/* Box 6: Downloaded Videos and Subscribe Button*/}
                    <SubscribeButton name="sub" display={true} id={state.user.id} isNative={isNative}></SubscribeButton>
                </div>
                {
                    isNative &&
                    <div className="flex flex-row gap-2 my-2 justify-center">
                        <RestorePurchasesButton name={""} display={true} id={state.user.id}
                            isNative={isNative}></RestorePurchasesButton>
                    </div>
                }
                {/*<div className="flex flex-row gap-2 my-2 justify-center">*/}
                {/*    <div className="w-6/12">*/}
                {/*        <IonButton*/}
                {/*            color="favorite"*/}
                {/*            shape="round"*/}
                {/*            expand="full"*/}
                {/*            onClick={(ev) => handleProgressWithPros(ev)}>*/}
                {/*            Join our Discord!*/}
                {/*        </IonButton>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {
                    isAuthenticated ?
                        <div className="flex flex-row justify-center my-2">
                            <LogoutButton display={isAuthenticated}></LogoutButton>
                        </div>
                        :
                        <div className="flex flex-row justify-center my-2">
                            <IonButton expand="full" color="favorite" shape="round" onClick={login}>Log In</IonButton>
                        </div>
                }

            </IonContent>
        </IonPage>
    );
};

export default Settings;