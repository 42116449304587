import React, {
    useRef,
    useEffect,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { BoardType } from '../../../models/boardType';
import BoardLoading from '../../ComponentsUI/BoardLoading/BoardLoading';
import BoardIDXInfoCard from '../BoardIDXInfoCard/BoardIDXInfoCard';
import { useAuth } from '../../../AuthProvider';
import { Board } from '../../../models/board';
import { useBoardEventResults } from '../../../hooks/useEventBoardResults';
import ShopRedirect from '../../ComponentsPurchases/ShopRedirect/ShopRedirect';
import EventBoardUserBiskCard from '../EventBoardUserBiskCard/EventBoardUserBiskCard';
import EventBoardUserOtherCard from '../EventBoardUserOtherCard/EventBoardUserOtherCard';
import EventBoardIDXInfoCard from '../EventBoardIDXInfoCard/EventBoardIDXInfoCard';

interface EventBoardResultsListProps {
    id: string; // event id
    blurred: boolean;
    onShopRedirect: () => void;
    isEventBoardLoading: boolean;
    selectedBoard: Board;
    sortType: string;
    activeTab: string;
}

const EventBoardResultsList: React.FC<EventBoardResultsListProps> = ({
    id,
    blurred,
    onShopRedirect,
    isEventBoardLoading,
    selectedBoard,
    sortType,
    activeTab,
}) => {
    const { isAuthenticated } = useAuth();
    const parentRef = useRef<HTMLDivElement>(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    // Get board results using your custom hook
    const {
        results: boardResults,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        error,
        fetchNextPage,
        refetch,
    } = useBoardEventResults({
        boardId: selectedBoard?.id,
        boardType: selectedBoard?.board_type,
        sort: sortType,
    });

    // Set up the virtualizer
    const virtualizerOptions = useMemo(
        () => ({
            count: hasNextPage ? boardResults.length + 1 : boardResults.length,
            getScrollElement: () => parentRef.current,
            estimateSize: () => 100,
            overscan: 10,
            // Attempt to preserve scroll if you return:
            scrollToFn: (offset: number) => {
                const scrollElement = parentRef.current;
                if (scrollElement) {
                    scrollElement.scrollTop = offset;
                }
            },
            initialOffset: scrollPosition,
            // The rect is not strictly necessary unless you have a reason:
            initialRect: { width: 0, height: 0 },
        }),
        [hasNextPage, boardResults.length, scrollPosition]
    );

    const rowVirtualizer = useVirtualizer(virtualizerOptions);

    // SCROLL HANDLING
    useEffect(() => {
        const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

        if (!lastItem) {
            return;
        }

        if (lastItem.index >= boardResults.length - 2 && !isFetchingNextPage && hasNextPage) {
            console.log('Fetching next page...', {
                isFetchingNextPage,
                hasNextPage,
                currentResultsLength: boardResults.length
            });
            fetchNextPage();
        } else {
            console.log('Skipping fetch...', {
                isFetchingNextPage,
                hasNextPage,
                currentResultsLength: boardResults.length
            });
        }
    }, [
        boardResults,
        fetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        rowVirtualizer.getVirtualItems(),
    ]);

    // Render function for each item
    const renderItem = useCallback(
        (index: number) => {
            const isLoaderRow = index > boardResults.length - 1;
            if (isLoaderRow) {
                return hasNextPage ? (
                    <div className="p-4 text-center">Loading more...</div>
                ) : null;
            }

            // The first item is never blurred
            if (index === 0) {
                if (selectedBoard?.board_type === BoardType.USER_BISK) {
                    return (
                        <EventBoardUserBiskCard
                            result={boardResults[0]}
                            blurred={false}
                            isAuthed={isAuthenticated}
                        />
                    );
                } else if (selectedBoard?.board_type === BoardType.USER_INFO || selectedBoard?.board_type === BoardType.USER_RANK) {
                    return <EventBoardUserOtherCard result={boardResults[0]} blurred={false} type={selectedBoard?.board_type} isAuthed={isAuthenticated} />;
                } else {
                    return <EventBoardIDXInfoCard result={boardResults[0]} blurred={false} />;
                }
            }

            // Potential shop redirect after the first item if needed
            if (index === 1 && blurred && selectedBoard && !selectedBoard.public) {
                return (
                    <ShopRedirect
                        title="Head to the Smoke Shack and Sign Up for the Tow Rope Pass to View Leaderboards!"
                        shopRedirect={onShopRedirect}
                    />
                );
            }

            // For the rest, apply blurred if board is not public
            const result = boardResults[index];
            const isBlurred = selectedBoard
                ? selectedBoard.public
                    ? false
                    : blurred
                : blurred;

            if (selectedBoard?.board_type === BoardType.USER_BISK) {
                return (
                    <EventBoardUserBiskCard
                        result={result}
                        blurred={isBlurred}
                        isAuthed={isAuthenticated}
                    />
                );
            } else if (selectedBoard?.board_type === BoardType.USER_INFO || selectedBoard?.board_type === BoardType.USER_RANK) {
                return <EventBoardUserOtherCard result={result} blurred={isBlurred} type={selectedBoard?.board_type} isAuthed={isAuthenticated} />;
            } else {
                return <EventBoardIDXInfoCard result={result} blurred={isBlurred} />;
            }
        },
        [boardResults, selectedBoard, blurred, isAuthenticated, hasNextPage, onShopRedirect]
    );

    // Pull-to-refresh or manual refresh if needed
    const handleRefresh = async (event: CustomEvent) => {
        await refetch();
        event.detail.complete();
    };

    // Show loading states
    if (status === 'pending' || isEventBoardLoading) return <BoardLoading />;
    if (status === 'error') return <div>Error: {(error as Error).message}</div>;

    // Return the entire virtualized list
    return (
        <>
            {/* NOTICE: We removed `key` from the container to avoid forced remount */}
            <div
                ref={parentRef}
                style={{ minHeight: '1px' }}
                className="h-full overflow-auto"
            >
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                        <div
                            key={virtualRow.index}
                            data-index={virtualRow.index}
                            ref={rowVirtualizer.measureElement}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                transform: `translateY(${virtualRow.start}px)`,
                                willChange: 'transform',
                            }}
                        >
                            {renderItem(virtualRow.index)}
                        </div>
                    ))}
                </div>
                {/* Show "Updating..." if background fetch is happening */}
                {isFetching && !isFetchingNextPage && (
                    <div className="text-center p-4">Updating...</div>
                )}
            </div>
        </>
    );
};

export default EventBoardResultsList;
