import { useCallback, useContext, useMemo } from "react";
import { useLocation } from "react-router";
import { AppContext } from "../AppStateProvider";
import { useIonRouter } from "@ionic/react";

export const useTabBarVisibility = () => {
  const location = useLocation();
  const router = useIonRouter();
  const { state } = useContext(AppContext);

  const tabBarRoutes = useMemo(() => {
    const routes = ['/home', '/map', '/upload', '/crew-upload', '/community'];
    if (state?.user?.username) {
      routes.push(`/profile/${state.user.username}`);
    }
    return routes;
  }, [state?.user?.username]);

  const shouldShowTabBar = useCallback((pathname: string) => {
    return tabBarRoutes.some(route => pathname.startsWith(route));
  }, [tabBarRoutes]);

  const isTabBarVisible = shouldShowTabBar(location.pathname);

  const shouldDisableSwipeBack = useCallback(() => {
    const mainRoutes = tabBarRoutes.some(route => router.routeInfo.pathname.startsWith(route));
    const additionalRoutes = ['/clip-upload', '/flow', '/flow/signup', '/flow/login', '/auth/forgot-password', '/auth/reset-password', '/admin/__/auth/handler', '/shop'].some(route =>
      router.routeInfo.pathname.startsWith(route)
    );

    return mainRoutes || additionalRoutes;
  }, [router.routeInfo.pathname, tabBarRoutes]);

  const getCurrentTab = useCallback((pathname: string) => {
    const exactMatch = tabBarRoutes.find(route => pathname === route);
    if (exactMatch) return exactMatch;
    
    // Handle nested routes by finding the base route that matches
    const matchingRoute = tabBarRoutes.find(route => pathname.startsWith(route));
    return matchingRoute || null;
  }, [tabBarRoutes]);

  return {
    shouldShowTabBar,
    isTabBarVisible,
    shouldDisableSwipeBack: shouldDisableSwipeBack(),
    currentTab: getCurrentTab(location.pathname)
  };
};