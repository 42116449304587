const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const SECOND = 1000;

/*
TODO: Could add some refetch on mount or polling to keep data fresh
*/

export const UsageThresholds = {
    ULTRA_HIGH_FREQUENCY: 1000,  // 1000+ transactions/day (~1 per minute)
    VERY_HIGH_FREQUENCY: 500,    // 500-999 transactions/day (~1 every 2-3 mins)
    HIGH_FREQUENCY: 100,         // 100-499 transactions/day (~1 every 15 mins)
    MEDIUM_HIGH_FREQUENCY: 25,   // 25-99 transactions/day
    MEDIUM_FREQUENCY: 10,        // 10-24 transactions/day
    LOW_FREQUENCY: 1,           // 1-9 transactions/day
} as const;

export const QueryTiming = {
    ULTRA_HIGH_FREQUENCY: {
        staleTime: 30 * SECOND,    // 30 seconds
        gcTime: 1 * MINUTE,        // 1 minute
    },
    VERY_HIGH_FREQUENCY: {
        staleTime: 1 * MINUTE,     // 1 minute
        gcTime: 2 * MINUTE,        // 2 minutes
    },
    HIGH_FREQUENCY: {
        staleTime: 2 * MINUTE,
        gcTime: 4 * MINUTE,
    },
    MEDIUM_HIGH_FREQUENCY: {
        staleTime: 10 * MINUTE,
        gcTime: 20 * MINUTE,
    },
    MEDIUM_FREQUENCY: {
        staleTime: 15 * MINUTE,
        gcTime: 30 * MINUTE,
    },
    LOW_FREQUENCY: {
        staleTime: 3 * HOUR,
        gcTime: 4 * HOUR,
    },
    REAL_TIME: {
        staleTime: 0,
        gcTime: 1 * MINUTE,
    },
} as const;

// Helper function to get timing based on transaction frequency
export const getQueryTiming = (transactionsPerDay: number) => {
    if (transactionsPerDay >= UsageThresholds.ULTRA_HIGH_FREQUENCY) {
        return QueryTiming.ULTRA_HIGH_FREQUENCY;
    } else if (transactionsPerDay >= UsageThresholds.VERY_HIGH_FREQUENCY) {
        return QueryTiming.VERY_HIGH_FREQUENCY;
    } else if (transactionsPerDay >= UsageThresholds.HIGH_FREQUENCY) {
        return QueryTiming.HIGH_FREQUENCY;
    } else if (transactionsPerDay >= UsageThresholds.MEDIUM_HIGH_FREQUENCY) {
        return QueryTiming.MEDIUM_HIGH_FREQUENCY;
    } else if (transactionsPerDay >= UsageThresholds.MEDIUM_FREQUENCY) {
        return QueryTiming.MEDIUM_FREQUENCY;
    } else {
        return QueryTiming.LOW_FREQUENCY;
    }
};

/*
In summary:
removeQueries: Don't Use
refetchQueries: Use when want to maintain the current state of the UI Component and just want to refetch the data instead of triggering a full re-render/loading state
invalidateQueries: For marking queries as stale and refetching due to side effects. -> call to affect other components -> makes RQ refetch that record next time it's accessed (Smart Refetching)
resetQueries: For clearing and resetting query data to its initial state. -> must call then refetch to get new data -> do in component where there is a loader or some shit like that
 -  (and nothing like reset), does it make sense to call both of them? If my mutation is in the same component as the query, is it ok to remove() and refetch() in the mutation's onSuccess? -> USE RESET
*/

// Helper to apply environment multiplier to timing
export const getAdjustedTiming = (timing: typeof QueryTiming[keyof typeof QueryTiming]) => {
    return {
        ...timing,
        staleTime: timing.staleTime,
        gcTime: timing.gcTime,
    };
};

// Example usage:
// const queryConfig = getEnvAdjustedTiming(getQueryTiming(transactionsPerDay));

export type QueryTimingKey = keyof typeof QueryTiming;
export type QueryTimingConfig = typeof QueryTiming[QueryTimingKey];