import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { IonAvatar, IonIcon, IonImg, IonSpinner, useIonToast } from "@ionic/react";
import UserService from "../../../services/user.service";
import { User } from "../../../models/user";
import { motion } from 'framer-motion';
import biskLogo from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import biskSvg from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import UsernameDisplay from "../../ComponentsUI/Username/UsernameDisplay";
import { AppContext } from "../../../AppStateProvider";
import { formatAmount, isUUID } from "../../../services/utils";
import useAuthService from "../../../hooks/useAuthService";
import BoardUserBiskCardSkeleton from "../BoardUserBiskCardSkeleton/BoardUserBiskCardSkeleton";
import { BoardEventResult } from "../../../models/eventBoardResults";
import { useAnalytics } from "../../../AppAnalyticsProvider";


interface ContainerProps {
    result: BoardEventResult;
    blurred?: boolean;
    isAuthed?: boolean;
}

const EventBoardUserBiskCard: React.FC<ContainerProps> = ({ result, blurred, isAuthed }) => {
    const history = useHistory();
    const { trackEvent } = useAnalytics();
    const { state } = useContext(AppContext);
    const { login } = useAuthService();

    const [originUsername, setOriginUsername] = useState<string>('');
    const [originProfilePic, setOriginProfilePic] = useState<string>('/assets/photos/defaultProPic.png');
    const [amount, setTransactionAmount] = useState<number>(0);
    const [isFlipped, setIsFlipped] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const [blurredClass, setBlurredClass] = useState<string>('blur-lg');
    const [present] = useIonToast();

    const getUserInfoOrigin = async (userId: string) => {
        try {
            let user: User = undefined;

            if (isUUID(userId)) {
                user = await UserService.getUserPublicById(userId);
            } else {
                user = await UserService.getUserByUsername(userId);
            }

            if (user.username === undefined) {
                setError(true);
                return;
            }
            setOriginUsername(user.username);
            if (user.profile.profile_pic !== null && user.profile.profile_pic !== undefined && user.profile.profile_pic !== '') {
                setOriginProfilePic(user.profile.profile_pic);
            }
        } catch (error) {
            console.log("Error: " + error);
            setError(true);
        }
    }

    useEffect(() => {
        // Function to reset state
        const resetState = () => {
            setOriginUsername('');
            setOriginProfilePic('/assets/photos/defaultProPic.png');
            setTransactionAmount(0);
        };

        // Reset the state
        resetState();

        if (result) {
            setTransactionAmount(result.event_board_value);
            getUserInfoOrigin(result.user_id);
        }
    }, [result, result.event_board_value, result.user_id]);

    useEffect(() => {
        if (blurred) {
            setBlurredClass('blur-lg');
        } else {
            setBlurredClass('');
        }
    }, [blurred]);

    const presentToastLoginHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            position: 'top',
            icon: biskSvg,
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    const onClickUsername = async (username: string) => {
        trackEvent('board_bisk_profile_click', {
            clicked_user_id: result.user_id,
            clicked_username: username,
            is_authed: isAuthed,
            bisk_amount: amount
        });

        if (!isAuthed) {
            await presentToastLoginHandler("You must be logged in to view profiles!");
            return;
        }
        history.push('/profile/' + username);
    };

    const onFlip = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        originUsername !== '' ?
            <div className={"flex flex-col border-b border-gray-900 " + blurredClass}>
                <div className="flex items-center justify-between px-4 py-3">
                    <div className="flex items-center space-x-3 cursor-pointer">
                        <IonAvatar slot="start" className="mx-0.5"
                            onClick={() => onClickUsername(originUsername)}>
                            <IonImg className="shrink w-12 h-12 flex-none rounded-full" src={originProfilePic}></IonImg>
                        </IonAvatar>
                        <div>
                            <UsernameDisplay className="font-semibold" username={result.username} userId={result.user_id}
                                onUsernameClickHandler={onClickUsername} loggedInId={state.user.id} />
                        </div>
                    </div>
                    <motion.div
                        className="flex flex-none items-center justify-center w-10 h-10 bg-highlight rounded-full cursor-pointer"
                        animate={{ rotateY: isFlipped ? 180 : 0 }}
                        transition={{ duration: 0.25 }}
                        onClick={onFlip}
                    >
                        {isFlipped ? (
                            <motion.div
                                style={{ rotateY: "180deg" }}
                            >
                                <IonIcon src={biskLogo} size="large" style={{ rotateY: '180deg' }}
                                    className="w-8 h-8 pt-2" />
                            </motion.div>
                        ) : (
                            <motion.span
                                className="text-dark text-xl font-semibold"
                            >
                                {formatAmount(amount)}
                            </motion.span>
                        )}
                    </motion.div>
                </div>
            </div> :
            <div className={"flex flex-col border-b border-gray-900 " + blurredClass}>
                <BoardUserBiskCardSkeleton />
            </div>
    )
};

export default EventBoardUserBiskCard;