import { IonButton, IonContent, IonPage, IonRefresher, IonRefresherContent, RefresherEventDetail, } from "@ionic/react";
import { format, parseISO } from 'date-fns';
import { arrowBack, } from "ionicons/icons";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import HomePageVideoPlayer from "../components/ComponentsVideo/HomePageVideo/HomePageVideo";
import ToolbarSearchBar from "../components/ComponentsUI/ToolbarSearchBar/ToolbarSearchBar";
import VideoTagDisplay from "../components/ComponentsUI/VideoTagDisplay/VideoTagDisplay";
import TrickLike from "../components/ComponentsUI/TrickLike/TrickLike";
import TrickMapLocation from "../components/ComponentsUI/TrickMapLocation/TrickMapLocation";
import { UserType } from "../models/usertype";
import TricksService from "../services/tricks.service";
import UserService from "../services/user.service";
import { handleTagClickUtil, splitString, } from "../services/utils";
import { AppContext } from "../AppStateProvider";
import TrickSaveComponent from "../components/ComponentsUI/TrickSaveComponent/TrickSaveComponent";
import TrickShare from "../components/ComponentsShare/TrickShare/TrickShare";
import TrickDownload from "../components/ComponentsUI/TrickDownload/TrickDownload";
import TrickCardExtraPopover from "../components/ComponentsUI/TrickCardExtraPopover/TrickCardExtraPopover";
import LocationService from "../services/location.service";
import BiskHome from "../components/ComponentsBisk/BiskHome/BiskHome";
import UsernameDisplay from "../components/ComponentsUI/Username/UsernameDisplay";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import IDXChip from "../components/ComponentsUI/IDXChip/IDXChip";
import ReportReason from "../components/ComponentsProfile/ReportReason/ReportReason";
import CommentDisplay from "../components/ComponentsComments/CommentDisplay/CommentDisplay";
import { useAuth } from "../AuthProvider";
import PreferencesService from "../services/preferences.service";
import { LoadSuggestedTricksStatus } from "../models/loadSuggestedTricksStatus";
import IDXButtonIcon from "../components/ComponentsUI/IDXButtonIcon/IDXButtonIcon";
import TrickVideoLoading from "../components/ComponentsUI/TrickVideoLoading/TrickVideoLoading";
import { useAppState } from "../AppListenerProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import InstagramShare from "../components/ComponentsShare/InstagramShare/InstagramShare";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { useAnalytics } from '../AppAnalyticsProvider';
import { getAdjustedTiming, QueryTiming } from "../hooks/useQueryConfig";


interface TrickVideoParams {
    user_id?: string;
    id?: string;
}

interface TrickVideoProps extends RouteComponentProps<TrickVideoParams> {
}

const TrickVideo: React.FC<TrickVideoProps> = ({ match }) => {
    // Must see if a user is logged in to see if they can like a trick, post a comment, save a trick, etc.
    // use state to get user profile information
    const { user_id, id } = match.params;

    const { state } = useContext(AppContext);
    const { isLoading: isAuthLoading } = useAuth();
    const { isNative } = useAppState();
    const history = useHistory();
    const location = useLocation();
    const queryClient = useQueryClient();
    const { trackEvent, trackScreen } = useAnalytics();

    const [editTrick, setEditTrick] = useState(false);
    const [isCached, setIsCached] = useState(false);
    const [userType, setUserType] = useState(UserType.UNKNOWN);
    const [formattedDate, setFormattedDate] = useState('');
    const [trickCommentsCount, setTrickCommentsCount] = useState(0);
    const [isAuthed, setIsAuthed] = useState<boolean | undefined>(undefined);
    const [reportOpen, setReportOpen] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [subscriptionLevel, setSubscriptionLevel] = useState('');

    const { data: trick, isLoading: isTrickLoading, refetch: refetchUserTrick } = useQuery({
        queryKey: ['userTrick', id, user_id],
        queryFn: () => TricksService.getUserTrick(id, user_id),
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
    });

    const { data: user } = useQuery({
        queryKey: ['user', user_id],
        queryFn: () => UserService.getUserPublicById(user_id),
        enabled: !!trick,
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
    });

    const { data: videoProperties } = useQuery({
        queryKey: ['videoProperties', id],
        queryFn: () => TricksService.getTrickVideoProperties(id),
        enabled: !!trick,
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
    });

    const { data: taggedUsers, refetch: refetchTaggedUsers } = useQuery({
        queryKey: ['taggedUsers', trick?.trick_id],
        queryFn: () => TricksService.getTrickUserTagged(id),
        enabled: !!trick,
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
    });

    const { data: trickMetadataStats, refetch: refetchTrickMetadataStats } = useQuery({
        queryKey: ['trickMetadataStats', id],
        queryFn: () => TricksService.getTrickInteractionsCount(id),
        enabled: !!trick,
        ...getAdjustedTiming(QueryTiming.MEDIUM_FREQUENCY),
    });

    const deleteTrickMutation = useMutation({
        mutationFn: () => UserService.deleteTrick(id, user_id),
        onSuccess: async () => {
            await Promise.all([
                // Invalidate suggested tricks preference
                PreferencesService.setLoadSuggestedTricks(LoadSuggestedTricksStatus.ONE),
                // Invalidate user profile query
                queryClient.invalidateQueries({ queryKey: ['userProfile', user_id] }),
                // Invalidate user tricks query
                queryClient.invalidateQueries({ queryKey: ['userTricks', user_id] }),
                // Invalidate user tricks query
                queryClient.invalidateQueries({ queryKey: ['streak', user_id] }),
                // Invalidate any other related queries
                queryClient.invalidateQueries({ queryKey: ['trickMetadataStats', id] }),
                queryClient.invalidateQueries({ queryKey: ['newTricks'] }),
            ]);
            history.push('/home');
        },
    });

    useEffect(() => {
        setIsSubscribed(state.isSubscribed);
        setSubscriptionLevel(state.subscriptionLevel);
    }, [state.isSubscribed, state.subscriptionLevel]);

    useEffect(() => {
        trackScreen('trick_video_page', 'trick_video_component');
    }, []);

    useEffect(() => {
        if (!isTrickLoading && trick && user && videoProperties && trickMetadataStats) {
            console.log("Prerender ready");
            window.prerenderReady = true;
            parseFilmDate(trick.date);
        }
    }, [trick, isTrickLoading, user, videoProperties, trickMetadataStats]);

    useEffect(() => {
        const checkUserAuth = async () => {
            const auth_id = await PreferencesService.getUserUid();
            setIsAuthed(!!auth_id);
            if (auth_id) {
                const userType = await UserService.getUserType(auth_id);
                setUserType(userType);
                if (userType === UserType.USER) {
                    const response = await UserService.getUserByAuthId(auth_id);
                    const isUserGod = (await UserService.getIsUserGod(auth_id)).result;
                    setEditTrick(response?.id === user_id || isUserGod);
                }
            }
        };

        if (!isAuthLoading) {
            checkUserAuth();
        }
    }, [isAuthLoading, user_id]);

    const handleBack = () => {
        trackEvent('trick_video_exit', {
            trick_id: id,
            user_id: user_id,
            exit_method: location.key ? 'back' : 'home'
        });
        location.key ? history.goBack() : history.push("/home");
    };

    const handleTagClick = async (tag: string) => {
        const newTag = handleTagClickUtil(tag);
        await Haptics.impact({ style: ImpactStyle.Light });
        trackEvent('trick_video_tag_click', {
            trick_id: id,
            tag: newTag,
            user_id: user_id
        });
        history.push('/search/' + newTag);
    };

    const handleUserTagClick = async (username: string) => {
        await Haptics.impact({ style: ImpactStyle.Light });
        trackEvent('trick_video_tag_click', {
            trick_id: id,
            tag: username,
            user_id: user_id
        });
        history.push('/profile/' + username);
    };

    const onClickPerson = (username: string) => {
        trackEvent('trick_video_profile_click', {
            trick_id: id,
            username,
            viewer_id: state?.user?.id
        });
        history.push("/profile/" + username);
    };

    const handleTrickLocationClick = async (locationInfo: string) => {
        trackEvent('trick_video_location_click', {
            trick_id: id,
            location: locationInfo,
            user_id: user_id
        });

        const res = await LocationService.getTrickLocation(id);
        if (res && Object.keys(res).length !== 0 && res.name !== undefined) {
            trackEvent('trick_video_location_success', {
                trick_id: id,
                location: res.name,
                coordinates: `${res.google_lat},${res.google_long}`
            });
            history.push(`/map?lat=${res.google_lat}&long=${res.google_long}`);
        } else {
            const placesRes = await LocationService.getLocationFromTextPlacesAPI(locationInfo);
            if (placesRes) {
                const { lat, lng } = placesRes.result.geometry.location;
                trackEvent('trick_video_location_search_success', {
                    trick_id: id,
                    location: locationInfo,
                    coordinates: `${lat},${lng}`
                });
                history.push(`/map?lat=${lat}&long=${lng}`);
            } else {
                trackEvent('trick_video_location_fallback', {
                    trick_id: id,
                    location: locationInfo
                });
                history.push(`/map?lat=40.74490596382429&long=-111.79303497769095`);
            }
        }
    };

    const parseFilmDate = (date: string) => {
        setFormattedDate(format(parseISO(date), 'MMM d, yyyy'));
    };

    const addTaggedUserToComments = (comment: string) => {
        const parsedComment = splitString(comment, /(\@[A-Za-z0-9_.-]+)/);
        return (
            <>
                {parsedComment.map((phrase, index) => {
                    if (phrase.at(0) === '@') {
                        return (
                            <span
                                className="text-indigo-300 cursor-pointer"
                                onClick={async () => {
                                    onClickPerson(phrase.substring(1));
                                }}
                                key={index}
                            >
                                {phrase}
                            </span>
                        );
                    } else {
                        return <span key={index}>{phrase}</span>;
                    }
                })}
            </>
        );
    }

    const onRefreshTrick = async (event: CustomEvent<RefresherEventDetail>) => {
        await refetchTaggedUsers();
        await refetchUserTrick();
        await refetchTrickMetadataStats();
        event.detail.complete();
    };

    useEffect(() => {
        if (trick && user) {
            trackEvent('trick_video_view', {
                trick_id: id,
                user_id: user_id,
                viewer_id: state?.user?.id,
                trick_name: trick.trick_name,
                is_external: videoProperties?.is_external,
                is_youtube: videoProperties?.is_youtube,
                is_vimeo: videoProperties?.is_vimeo
            });
        }
    }, [trick, user]);

    useEffect(() => {
        if (trick && videoProperties) {
            trackEvent('trick_video_metadata', {
                trick_id: id,
                aspect_ratio: videoProperties.aspect_ratio,
                is_vertical: !videoProperties.is_horizontal,
                is_external: videoProperties.is_external,
                is_youtube: videoProperties.is_youtube,
                is_vimeo: videoProperties.is_vimeo,
                is_airtime: videoProperties.is_airtime
            });
        }
    }, [trick, videoProperties]);

    useEffect(() => {
        if (trickMetadataStats) {
            trackEvent('trick_video_engagement_stats', {
                trick_id: id,
                votes: trickMetadataStats.votes,
                comments: trickCommentsCount,
                user_id: user_id
            });
        }
    }, [trickMetadataStats, trickCommentsCount]);

    if (isTrickLoading) {
        trackEvent('trick_video_loading', {
            trick_id: id,
            user_id: user_id
        });
        return (
            <IonPage>
                <CustomHelmet
                    title="Ecliptic // View Clip"
                    description="Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"
                    image="https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"
                    url={`https://ecliptic.day/clip/${user_id}/${id}`}
                />
                <ToolbarSearchBar />
                <IonContent>
                    <TrickVideoLoading />
                </IonContent>
            </IonPage>
        );
    }

    return (
        <IonPage className="overflow-x-auto">
            <CustomHelmet
                title={`Ecliptic // ${user?.username} // ${trick?.trick_name}`}
                description={`Watch ${user?.username}'s ${trick?.trick_name}`}
                image={trick?.thumbnail || "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                url={`https://ecliptic.day/clip/${user_id}/${id}`}
            />
            <ToolbarSearchBar />
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={onRefreshTrick}>
                    <IonRefresherContent />
                </IonRefresher>
                <div className="flex flex-col flex-nowrap gap-2">
                    <div className="flex flex-row justify-between mx-1 -mb-1">
                        <IonButton fill="default" size="small" onClick={handleBack}>
                            <IDXButtonIcon size="text-xl" icon={arrowBack} />
                        </IonButton>
                        <div>
                            <ReportReason
                                isOpenParent={reportOpen}
                                text={`User ID: ${state.user?.id} wishes to report ${trick?.user_id} with trick id ${trick?.trick_id}`}
                                usernameToReport={user?.username}
                                reportingUsername={state.user?.id}
                                objectIdToReport={trick?.trick_id}
                                reportingId={state.user?.id}
                                reportedId={trick?.user_id}
                                handleIsOpen={setReportOpen}
                            />
                            < TrickCardExtraPopover
                                isCapacitor={isNative}
                                trick={trick}
                                isAuthed={isAuthed}
                                userId={state.user?.auth_id}
                                publicId={state.user?.id}
                                trickId={trick?.trick_id}
                                userType={userType}
                                displayTrickShare={false}
                                displayTrickInstagramShare={true}
                                displayTrickDownload={false}
                                userOnDisplayUsername={state.user?.username}
                                size="small"
                                displayEditTrick={true}
                                editTrick={editTrick}
                                editTrickCallback={async () => {
                                    await Promise.all([
                                        queryClient.invalidateQueries({ queryKey: ['userTrick', id, user_id] }), // whatever it fucking works
                                        queryClient.invalidateQueries({ queryKey: ['taggedUsers', trick?.trick_id] })
                                    ]);
                                }
                                }
                                deleteTrickCallback={() => {
                                    deleteTrickMutation.mutate()
                                }
                                }
                                onReportTrick={() => setReportOpen(true)}
                            />
                        </div>
                    </div>
                    {trick && videoProperties && (
                        <div className="xs:h-full xs:w-full sm:w-10/12 sm:h-10/12 md:w-6/12 md:h-6/12 l:w-5/12 l:h-5/12 xl:w-6/12 xl:h-6/12 mx-auto">
                            <HomePageVideoPlayer
                                isCapacitor={isNative}
                                src={trick.video}
                                thumbnail={trick.thumbnail}
                                id={trick.trick_id}
                                isCached={isCached}
                                isHorizontal={!videoProperties.is_vertical}
                                isExternal={videoProperties.is_external}
                                aspectRatio={videoProperties.aspect_ratio}
                                isAirtime={videoProperties.is_airtime}
                                isVimeo={videoProperties.is_vimeo}
                                isYoutube={videoProperties.is_youtube}
                                isScrolling={false}
                                scrollSpeed={0}
                            />
                        </div>
                    )}
                    <div className="flex flex-row font-bold text-2xl mx-3">
                        {trick?.trick_name}
                    </div>
                    <div className="flex flex-row ml-4 space-x-4 items-center cursor-pointer">
                        <img
                            onClick={() => onClickPerson(user?.username)}
                            className="shrink w-12 h-12 rounded-full"
                            src={user?.profile?.profile_pic || '/assets/photos/defaultProPic.png'}
                            alt="User Avatar"
                        />
                        <div className="flex flex-col space-y-0.5">
                            <UsernameDisplay
                                username={user?.username || user?.name}
                                className="font-bold text-xl"
                                onUsernameClickHandler={onClickPerson}
                                userId={trick?.user_id}
                                loggedInId={state?.user?.id}
                            />
                            {trick?.location && (
                                <div
                                    className="text-zinc-300 text-xs"
                                    onClick={() => handleTrickLocationClick(trick.location)}
                                >
                                    {trick.location}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row font-bold text-base overflow-x-auto mx-4">
                        <div className="flex flex-col">
                            {trick?.description && (
                                <div>{addTaggedUserToComments(trick.description)}</div>
                            )}
                            <div>{formattedDate}</div>
                        </div>
                    </div>
                    <div className="flex flex-row font-bold text-3xl mx-2 justify-between overflow-x-auto">
                        <div className="flex flex-row font-bold text-3xl items-center gap-x-2">
                            <TrickLike
                                trickId={trick?.trick_id}
                                isAuthed={isAuthed}
                                publicId={state?.user?.id}
                                userType={userType}
                                count={trickMetadataStats?.votes}
                            />
                            <TrickMapLocation trick_id={trick?.trick_id} />
                            <TrickDownload
                                isCapacitor={isNative}
                                trickId={trick?.trick_id}
                                trick={trick}
                                isSubscribed={isSubscribed}
                                subscriptionLevel={subscriptionLevel}
                            />
                            <BiskHome senderId={state?.user?.id} isAuthed={isAuthed} recipientId={trick?.user_id}
                                trickId={trick?.trick_id} trickName={trick?.trick_name}
                                recipientUsername={user?.username} />
                            {isNative && <InstagramShare backgroundVideoURL={trick?.video} />}
                        </div>
                        <div className="flex flex-row font-bold text-3xl items-center gap-x-2">
                            <TrickShare isCapacitor={isNative} trickId={trick?.trick_id} trick={trick}
                                list={false}></TrickShare>
                            <TrickSaveComponent trickId={trick?.trick_id} isAuthed={isAuthed}
                                publicId={state?.user?.id} userType={userType} />
                        </div>
                    </div>
                    <VideoTagDisplay
                        taggedUsers={taggedUsers}
                        handleUserTagClick={handleUserTagClick}
                        trick={trick} tags={trick?.tags} handleTagClick={handleTagClick}
                        isAuthed={isAuthed} />
                    <div className="flex flex-row mx-5 gap-x-2 items-center">
                        <div>Comments</div>
                        <IDXChip bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                            borderColor="border border-primary-secondary" text={trickCommentsCount} />
                    </div>
                    <CommentDisplay trickId={trick?.trick_id} publicId={state?.user?.id} isAuthed={isAuthed}
                        onClickPerson={onClickPerson} onSetTrickCommentCount={setTrickCommentsCount} />
                </div>
            </IonContent>
        </IonPage>
    );
};

export default TrickVideo