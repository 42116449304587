import { OverlayEventDetail } from "@ionic/core/components";
import { IonButton, useIonModal, useIonToast } from "@ionic/react";
import { alertCircleOutline, heart, heartOutline } from "ionicons/icons";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import UserService from "../../../services/user.service";
import ViewTrickLikes from "../ViewTrickLikes/ViewTrickLikes";
import IDXChip from "../IDXChip/IDXChip";
import useAuthService from "../../../hooks/useAuthService";
import IDXButtonIcon from "../IDXButtonIcon/IDXButtonIcon";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { useAnalytics } from '../../../AppAnalyticsProvider';

interface ContainerProps {
    isAuthed: boolean;
    publicId: string;
    trickId: string;
    userType: string;
    count: number;
}

const TrickLike: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [trickLiked, setTrickLiked] = React.useState(false);
    const [trickLikesCount, setTrickLikesCount] = React.useState<number>(0);

    const history = useHistory();
    const { login } = useAuthService();
    const [present] = useIonToast();
    const { trackEvent } = useAnalytics();

    useEffect(() => {
    }, [props.isAuthed, props.publicId, props.trickId]);

    const onClickPersonLikes = (username: string) => {
        history.push("/profile/" + username);
    }

    const [presentLikes, dismissLikes] = useIonModal(ViewTrickLikes, {
        onDismiss: (data: string, role: string) => dismissLikes(data, role),
        trickId: props.trickId,
        onClickResult: onClickPersonLikes
    })

    useEffect(() => {
        if (props.isAuthed) {
            // doing this check for security reasons so that we don't allow others to edit others tricks
            if (props.publicId !== "" && props.trickId !== "" && props.trickId !== undefined && props.publicId !== undefined && props.trickId !== null && props.publicId !== null) {
                getUserLikedTrick(props.trickId, props.publicId);
            }
        }
    }, [props.isAuthed, props.publicId, props.trickId]);

    useEffect(() => {
        if (props.count !== null && props.count !== undefined) {
            setTrickLikesCount(props.count);
        }
    }, [props.count]);

    const getUserLikedTrick = async (trick_id: string, user_id: string) => {
        const response = await UserService.getUserTrickLike(trick_id, user_id);
        setTrickLiked(response);
    }

    const getTrickLikes = async (trick_id: string) => {
        const response: number = await UserService.getUserTrickLikesCount(trick_id);
        if (response) {
            setTrickLikesCount(response);
        }
    }

    const presentToastLoginHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            position: 'top',
            icon: alertCircleOutline,
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    const onTrickLike = async () => {
        if (props.isAuthed) {
            await Haptics.impact({ style: ImpactStyle.Light });
            const newLikeState = !trickLiked;
            setTrickLiked(newLikeState);
            setTrickLikesCount(newLikeState ? trickLikesCount + 1 : trickLikesCount - 1);
            
            trackEvent('trick_like_toggle', {
                trick_id: props.trickId,
                user_id: props.publicId,
                action: newLikeState ? 'like' : 'unlike',
                likes_count: trickLikesCount
            });

            await UserService.likeTrick(props.trickId, props.publicId, newLikeState);
            await getUserLikedTrick(props.trickId, props.publicId);
            await getTrickLikes(props.trickId);
        } else {
            trackEvent('trick_like_auth_prompt', {
                trick_id: props.trickId
            });
            await presentToastLoginHandler("You must be logged in to like a trick!")
        }
    }

    const openTrickLikesModal = async () => {
        if (props.isAuthed) {
            trackEvent('trick_likes_view', {
                trick_id: props.trickId,
                likes_count: trickLikesCount
            });
            presentLikes({
                onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                    trackEvent('trick_likes_close', {
                        trick_id: props.trickId
                    });
                },
            });
        } else {
            trackEvent('trick_likes_auth_prompt', {
                trick_id: props.trickId
            });
            await presentToastLoginHandler("You must be logged in to view likes!")
        }
    }

    return (
        <>
            <div className="flex flex-row items-center gap-x-1">
                <IonButton fill="clear" color="dark" size="small" onClick={() => onTrickLike()}>
                    <IDXButtonIcon size="text-2xl" icon={trickLiked ? heart : heartOutline} />
                </IonButton>
                <div>
                    <IDXChip bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                        onClickChip={(tag) => openTrickLikesModal()}
                        borderColor="font-bold border-2 border-primary-secondary" text={trickLikesCount} />
                </div>

            </div>
        </>
    );
};

export default TrickLike;
