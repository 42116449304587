import React, { useEffect, useState } from "react";
import { OnboardingStatus } from "../../../models/onboardingStatus";
import { useAppState } from "../../../AppListenerProvider";
import { useHistory } from "react-router";
import WelcomeSignUpEmail from "../WelcomeSignUpEmail/WelcomeSignUpEmail";
import { useSignUp } from "../SignUpProvider/SignUpProvider";
import BirthdayStep from "../BirthdayStep/BirthdayStep";
import NameInput from "../NameInput/NameInput";
import UsernameInput from "../UsernameInput/UsernameInput";
import SportSelection from "../SportSelection/SportSelection";
import SportSkillSelection from "../SportSkillTerrainSelection/SportSkillTerrainSelection";
import { SkillLevel } from "../../../models/skillLevel";
import { SkillLevels, Terrain } from "../../../models/signUpData";
import LocationPermissions from "../LocationPermissions/LocationPermissions";
import LocationSearch from "../LocationSearch/LocationSearch";
import { LocationOnboarding } from "../../../models/locationOnboarding";
import UserService from "../../../services/user.service";
import WelcomeSignUpAuth from "../WelcomeSignUpAuth/WelcomeSignUpAuth";
import { OnboardingStepConfig } from "../../../models/onboardingStepConfig";
import LocationService from "../../../services/location.service";
import { v4 as uuidv4 } from "uuid";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import LoadingSplash from "../../../pages/LoadingSplash";
import WelcomeEclipticLoadingProps from "../WelcomeEcliptic/WelcomeEclipticLoading";
import Welcome from "../Welcome/Welcome";
import WelcomeEclipticLoading from "../WelcomeEcliptic/WelcomeEclipticLoading";
import { useAnalytics } from "../../../AppAnalyticsProvider";

interface ContainerProps {
}

const WelcomeSignUp: React.FC<ContainerProps> = ({ }) => {
    const history = useHistory();
    const { trackEvent, trackScreen } = useAnalytics();
    const { isNative, platform } = useAppState();
    const { signUpData, updateSignUpData, step, setStep, resetSignUp } = useSignUp();
    const [defaultLocations, setDefaultLocations] = useState<LocationOnboarding[]>([]);
    const [googlePlacesSessionToken, setGooglePlacesSessionToken] = useState<string | null>(null);

    const stepConfigState: Record<string, OnboardingStepConfig> = {
        [OnboardingStatus.IN_PROGRESS_SIGNUP_INIT]: {
            titleText: '',
            showBackButton: false,
            showSkipButton: false,
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY]: {
            titleText: 'Enter your birthday 🥳',
            showSkipButton: false,
            showBackButton: true,
            onBack: async () => {
                await navigateBackToInitial();
            },
            onNext: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME);
            },
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME]: {
            titleText: "What's your name?",
            showSkipButton: false,
            showBackButton: true,
            onBack: () => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY),
            onNext: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_NAME)
            }
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_NAME]: {
            titleText: `${signUpData.firstName}, pick your username`,
            showSkipButton: false,
            showBackButton: true,
            onBack: () => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME),
            onNext: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS);
            }
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS]: {
            titleText: `${signUpData.username}, choose your sports`,
            showSkipButton: false,
            showBackButton: true,
            onBack: () => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_NAME),
            onNext: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN);
            }
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN]: {
            titleText: signUpData.sports.length > 0 ? signUpData.displaySkill ? `What's your ${signUpData.sports[signUpData.sportIndex].toLowerCase()} skill level?` : `What terrain are you hitting when ${signUpData.sports[signUpData.sportIndex].toLowerCase()}?` : signUpData.displaySkill ? `What's your skill level?` : `What terrain are you hitting?`,
            showSkipButton: true,
            showBackButton: true,
            onBack: async () => {
                await updateSignUpData({ sportIndex: 0, displaySkill: true, terrain: null, skills: null });
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS)
            },
            onSkip: async () => {
                if (signUpData.sports.length > 0) {
                    // Retrieve existing skills and terrain
                    const existingSkills = signUpData.skills || {};
                    const existingTerrain = signUpData.terrain || {};

                    // Update skill and terrain only for skipped sports
                    const newSkills = signUpData.sports.reduce((acc: SkillLevels, sport) => {
                        if (!existingSkills[sport]) {
                            acc[sport] = SkillLevel.FAN;
                        } else {
                            acc[sport] = existingSkills[sport];
                        }
                        return acc;
                    }, {});

                    const newTerrain = signUpData.sports.reduce((acc: Terrain, sport) => {
                        if (!existingTerrain[sport]) {
                            acc[sport] = [];
                        } else {
                            acc[sport] = existingTerrain[sport];
                        }
                        return acc;
                    }, {});

                    await updateSignUpData({
                        skills: { ...existingSkills, ...newSkills },
                        terrain: { ...existingTerrain, ...newTerrain }
                    });
                }
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT)
            },
            onNext: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT);
            }
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT]: {
            titleText: 'Tell us your home base',
            showSkipButton: true,
            showBackButton: true,
            onBack: async () => {
                await updateSignUpData({ displaySkill: true });
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN)
            },
            onSkip: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH);
            },
            onNext: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH);
            },
            onSkipDelay: 1000,
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH]: {
            titleText: 'Search for your base',
            showSkipButton: true,
            showBackButton: true,
            onBack: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT);
            },
            onSkip: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER);
            },
            onNext: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER);
            }
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER]: {
            titleText: 'Enter your phone number',
            showSkipButton: true,
            onSkipDelay: 2000,
            showBackButton: true,
            onBack: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH);
            },
            onNext: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH);
            },
            onSkip: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH);
            },
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH]: {
            titleText: 'Finish Signing Up for Ecliptic!',
            showBackButton: signUpData.phoneNumber ? false : true,
            showSkipButton: false,
            onNext: async () => {
                resetSignUp();
                history.push("/");
                await handleOnboardingStatus(OnboardingStatus.COMPLETED);
            }
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME]: {
            titleText: 'Provide an Email and Password',
            showBackButton: true,
            showSkipButton: false,
            onBack: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH)
            },
            onNext: async () => {
                resetSignUp();
                history.push("/");
                await handleOnboardingStatus(OnboardingStatus.COMPLETED);
            }
        },
    };

    const navigateBackToInitial = async () => {
        trackEvent('signup_abandoned', {
            last_step: step,
            completed_fields: Object.entries(signUpData).filter(([_, value]) => Boolean(value)).length,
            reason: 'back_to_initial'
        });

        await handleOnboardingStatus(OnboardingStatus.INIT);
        history.push('/flow');
    }

    const handleOnboardingStatus = async (status: string) => {
        const currentStepNumber = getStepNumber(step);
        const nextStepNumber = getStepNumber(status);

        trackEvent('signup_navigation', {
            from_step: step,
            to_step: status,
            direction: nextStepNumber > currentStepNumber ? 'forward' : 'backward',
            step_difference: Math.abs(nextStepNumber - currentStepNumber),
            completed_data: {
                birthday: Boolean(signUpData.birthday),
                name: Boolean(signUpData.name),
                username: Boolean(signUpData.username),
                sports: Boolean(signUpData.sports?.length),
                location: Boolean(signUpData.location),
                phone: Boolean(signUpData.phoneNumber)
            }
        });


        await setStep(status);
    }

    const getStepNumber = (step: string): number => {
        const stepOrder: Record<string, number> = {
            [OnboardingStatus.IN_PROGRESS_SIGNUP_INIT]: 1,
            [OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY]: 2,
            [OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME]: 3,
            [OnboardingStatus.IN_PROGRESS_SIGNUP_NAME]: 4,
            [OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS]: 5,
            [OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN]: 6,
            [OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT]: 7,
            [OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH]: 8,
            [OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER]: 9,
            [OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH]: 10,
            [OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME]: 11
        };
        return stepOrder[step] || 0;
    };

    // Track initial signup start
    useEffect(() => {
        trackScreen('signup_flow', 'SignupFlowScreen');
        trackEvent('signup_flow_started', {
            platform: platform,
            is_native: isNative
        });
    }, []);

    useEffect(() => {
        const currGooglePlacesToken = uuidv4();
        console.log("Setting Google Places Token: " + currGooglePlacesToken);
        setGooglePlacesSessionToken(currGooglePlacesToken);
    }, []);

    useEffect(() => {
        const initializeStep = async () => {
            if (step) {
                if (step.includes(OnboardingStatus.SIGNUP)) {
                    await handleOnboardingStatus(step);
                } else {
                    console.log("INVALID STATUS: GOING TO FLOW");
                    await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_INIT);
                }
            } else {
                await handleOnboardingStatus(OnboardingStatus.INIT);
                history.push("/flow");
            }
        };

        console.log("Loading Sign Up Flow App Page");
        initializeStep();
    }, []);  // Empty dependency array means this runs once on mount

    // Track step changes
    useEffect(() => {
        if (step) {
            trackEvent('signup_step_changed', {
                previous_step: step,
                current_step: step,
                step_number: getStepNumber(step),
                has_data: {
                    birthday: Boolean(signUpData.birthday),
                    name: Boolean(signUpData.name),
                    username: Boolean(signUpData.username),
                    sports: Boolean(signUpData.sports?.length),
                    location: Boolean(signUpData.location),
                    phone: Boolean(signUpData.phoneNumber)
                }
            });
        }
    }, [step]);

    useEffect(() => {
        const loadRelevantLocations = async () => {
            if (signUpData.latitude && signUpData.longitude && googlePlacesSessionToken) {
                const country = await LocationService.getCountryFromLocationAPI(signUpData.latitude, signUpData.longitude, googlePlacesSessionToken);
                if (country) {
                    await updateSignUpData({ countryData: country });
                }

                console.log("LOADING RELEVANT LOCATIONS");
                try {
                    const locations: LocationOnboarding[] = await UserService.getUserLocationsOnboardingLocation(1, signUpData.latitude, signUpData.longitude);
                    setDefaultLocations(locations);
                } catch (error) {
                    console.error("Error loading locations: ", error);
                    setDefaultLocations([]);
                }
            } else {
                console.log("LOADING DEFAULT LOCATIONS");
                try {
                    const locations: LocationOnboarding[] = await UserService.getUserLocationsOnboarding(1);
                    setDefaultLocations(locations);
                } catch (error) {
                    console.error("Error loading locations: ", error);
                    setDefaultLocations([]);
                }
            }
        }

        loadRelevantLocations();
    }, [signUpData.latitude, signUpData.longitude, googlePlacesSessionToken]);

    useEffect(() => {
        const redirectBasedOnStep = async () => {
            console.log("Step: " + step);
            if (isNative && step === OnboardingStatus.IN_PROGRESS_SIGNUP_INIT) {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY);
            }

            if (!isNative && step === OnboardingStatus.IN_PROGRESS_SIGNUP_INIT) {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY);
            }
        };

        redirectBasedOnStep();
    }, [step, isNative]);


    const renderStep = () => {
        // State Change Controlled In This Logic
        switch (step) {
            case OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY:
                console.log("Rendering Birthday Step");
                return (
                    <BirthdayStep
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY].showSkipButton}
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY].showBackButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY].titleText}
                        onNext={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY].onNext}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY].onBack} />
                );
            case OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME:
                return (
                    <NameInput
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME].showSkipButton}
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME].showBackButton}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME].onBack}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME].titleText}
                        onNext={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME].onNext} />
                );
            case OnboardingStatus.IN_PROGRESS_SIGNUP_NAME:
                return (
                    <UsernameInput
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_NAME].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_NAME].showSkipButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_NAME].titleText}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_NAME].onBack}
                        onNext={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_NAME].onNext}
                    />
                );
            case OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS:
                return (
                    <SportSelection
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS].showSkipButton}
                        title={`${signUpData.username}, choose your sports`}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS].onBack}
                        onNext={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS].onNext} />
                );
            case OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN:
                return (
                    <SportSkillSelection
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN].showSkipButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN].titleText}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN].onBack}
                        onSkip={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN].onSkip}
                        onNext={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN].onNext}
                    />
                )
            case OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT:
                return (
                    <LocationPermissions
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].showSkipButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].titleText}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].onBack}
                        onSkip={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].onSkip}
                        onSkipDelay={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].onSkipDelay}
                        onNext={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].onNext} />
                )
            case OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH:
                return (
                    <LocationSearch
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH].onBack}
                        defaultLocations={defaultLocations}
                        defaultIsLoading={defaultLocations.length === 0}
                        onNext={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH].onNext}
                        onSkip={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH].onSkip}
                        sessionToken={googlePlacesSessionToken} />
                )
            case OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER:
                return (
                    <PhoneNumberInput
                        signUp={true}
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].showSkipButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].titleText}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].onBack}
                        onSkip={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].onSkip}
                        onSkipDelay={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].onSkipDelay}
                        onNext={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].onNext} />
                )
            case OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH:
                return (
                    <WelcomeSignUpAuth
                        handleAuthedStep={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH].onNext}
                        handleEmailAuthStep={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME)}
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH].showSkipButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH].titleText}
                    />
                )
            case OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME:
                return (
                    <WelcomeSignUpEmail
                        handleSignUpGoBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME].onBack}
                        handleSignUpLogin={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME].onNext}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME].titleText}
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME].showSkipButton}
                    />
                );
            case OnboardingStatus.COMPLETED:
                return (
                    <LoadingSplash />
                );
            default:
                return <WelcomeEclipticLoading />;
        }
    }

    return renderStep();
};

export default WelcomeSignUp;