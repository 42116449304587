import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../AppStateProvider';
import { SubscriptionType } from '../models/subscriptionType';

interface UseBlurResult {
    blurred: boolean;
    blurredClass: string;
}

export const useBlur = (): UseBlurResult => {
    const [blurred, setBlurred] = useState<boolean>(undefined);
    const [blurredClass, setBlurredClass] = useState<string>("");
    const { state } = useContext(AppContext);

    useEffect(() => {
        if (state.isSubscribed) {
            if (state.subscriptionLevel === SubscriptionType.Premium) {
                setBlurred(false);
                setBlurredClass("");
            } else if (state.subscriptionLevel === SubscriptionType.Basic) {
                setBlurred(true);
                setBlurredClass("blur-lg");
            }
        } else {
            setBlurred(true);
            setBlurredClass("blur-lg");
        }
    }, [state.isSubscribed, state.subscriptionLevel]);

    return { blurred, blurredClass };
};