import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { useAuth } from "./AuthProvider";
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

interface AppAnalyticsContextType {
    trackScreen: (screenName: string, screenClass?: string) => Promise<void>;
    trackEvent: (name: string, params?: Record<string, any>) => Promise<void>;
    setUserProperty: (key: string, value: string) => Promise<void>;
    setSessionTimeout: (duration: number) => Promise<void>;
    enableAnalytics: (enabled: boolean) => Promise<void>;
    isAnalyticsEnabled: () => Promise<boolean>;
    resetAnalytics: () => Promise<void>;
}

const AppAnalyticsContext = createContext<AppAnalyticsContextType | undefined>(undefined);

export const AppAnalyticsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
    const { firebaseApp, user } = useAuth();

    useEffect(() => {
        if (firebaseApp) {
            setAnalytics(getAnalytics(firebaseApp));
        }
    }, [firebaseApp]);

    useEffect(() => {
        if (user?.uid) {
            FirebaseAnalytics.setUserId({ userId: user.uid });
        }
    }, [user]);

    const trackScreen = async (screenName: string, screenClass?: string) => {
        try {
            await FirebaseAnalytics.setCurrentScreen({
                screenName,
                screenClassOverride: screenClass
            });
        } catch (error) {
            console.error('Screen tracking error:', error);
        }
    };

    const trackEvent = async (name: string, params?: Record<string, any>) => {
        try {
            await FirebaseAnalytics.logEvent({ name, params });
        } catch (error) {
            console.error('Event tracking error:', error);
        }
    };

    const setUserProperty = async (key: string, value: string) => {
        try {
            await FirebaseAnalytics.setUserProperty({ key, value });
        } catch (error) {
            console.error('User property error:', error);
        }
    };

    const setSessionTimeout = async (duration: number) => {
        try {
            await FirebaseAnalytics.setSessionTimeoutDuration({ duration });
        } catch (error) {
            console.error('Session timeout error:', error);
        }
    };

    const enableAnalytics = async (enabled: boolean) => {
        try {
            await FirebaseAnalytics.setEnabled({ enabled });
        } catch (error) {
            console.error('Enable analytics error:', error);
        }
    };

    const isAnalyticsEnabled = async () => {
        try {
            const { enabled } = await FirebaseAnalytics.isEnabled();
            return enabled;
        } catch (error) {
            console.error('Check analytics enabled error:', error);
            return false;
        }
    };

    const resetAnalytics = async () => {
        try {
            await FirebaseAnalytics.resetAnalyticsData();
        } catch (error) {
            console.error('Reset analytics error:', error);
        }
    };

    return (
        <AppAnalyticsContext.Provider value={{
            trackScreen,
            trackEvent,
            setUserProperty,
            setSessionTimeout,
            enableAnalytics,
            isAnalyticsEnabled,
            resetAnalytics
        }}>
            {children}
        </AppAnalyticsContext.Provider>
    );
};

export const useAnalytics = () => {
    const context = useContext(AppAnalyticsContext);
    if (!context) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider');
    }
    return context;
};