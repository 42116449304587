// VolumeContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { VolumeButtons, VolumeButtonsOptions, VolumeButtonsResult } from '@capacitor-community/volume-buttons';
import { Capacitor } from '@capacitor/core';
import PreferencesService from './services/preferences.service';
import { AppContext } from './AppStateProvider';
import { Volumes } from '@ottimis/capacitor-volumes';
import { useAppState } from './AppListenerProvider';

interface VolumeContextType {
    isMuted: boolean;
    setMuted: (muted: boolean) => Promise<void>;
}

const VolumeContext = createContext<VolumeContextType | undefined>(undefined);

export const VolumeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isMuted, setIsMuted] = useState(true);
    const { isNative } = useAppState();

    const getMuted = async () => {
        try {
            // First check if we have a stored preference
            const muted = await PreferencesService.getMutedPreferences();

            if (isNative) {
                // Check actual device volume
                const { value } = await Volumes.getVolumeLevel();
                const shouldBeMuted = value === 0;

                // Update stored preferences to match actual volume state
                await PreferencesService.setMutedPreferences(shouldBeMuted ? 'true' : 'false');
                setIsMuted(shouldBeMuted);
            } else if (muted) {
                // Fall back to stored preference if not native
                const shouldBeMuted = muted === 'true';
                setIsMuted(shouldBeMuted);
            } else {
                // Default case
                setIsMuted(true);
                await PreferencesService.setMutedPreferences('true');
            }
        } catch (e) {
            console.log(e);
            setIsMuted(true);
            await PreferencesService.setMutedPreferences('true');
        }
    };

    // Initialize muted state from preferences
    useEffect(() => {
        if (isNative) {
            getMuted();
        }
    }, [isNative]);

    // Set up volume button listeners
    useEffect(() => {
        if (!isNative) return;

        const setupVolumeListener = async () => {
            const options: VolumeButtonsOptions = {};

            if (Capacitor.getPlatform() === 'ios') {
                options.disableSystemVolumeHandler = false;
            } else if (Capacitor.getPlatform() === 'android') {
                options.suppressVolumeIndicator = false;
            }

            const callback = async (result: VolumeButtonsResult) => {
                if (result.direction === 'up') {
                    await setMuted(false);
                } else {
                    const { value } = await Volumes.getVolumeLevel();
                    if (value === 0) {
                        await setMuted(true);
                    }
                }
            };

            try {
                await VolumeButtons.watchVolume(options, callback);
            } catch (error) {
                console.error('Error setting up volume buttons:', error);
            }
        };

        setupVolumeListener();

        return () => {
            if (isNative) {
                VolumeButtons.clearWatch().catch(console.error);
            }
        };
    }, [isNative]);

    const setMuted = async (newMutedState: boolean) => {
        setIsMuted(newMutedState);
        await PreferencesService.setMutedPreferences(newMutedState ? 'true' : 'false');
    };

    return (
        <VolumeContext.Provider value={{ isMuted, setMuted }}>
            {children}
        </VolumeContext.Provider>
    );
};

export const useVolume = () => {
    const context = useContext(VolumeContext);
    if (context === undefined) {
        throw new Error('useVolume must be used within a VolumeProvider');
    }
    return context;
};