import { Preferences } from "@capacitor/preferences";
import axios from "axios";
import { formEventTricks } from "./utils";

const BrandsService = {
  getBrandByAuthId: async (auth_id: string) => {
    const params = {
      auth_id: auth_id
    };

    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand`, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  getBrandById: async (brand_id: string) => {
    const params = {
      brand_id: brand_id
    };

    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand`, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  getBrandByUsername: async (username: string) => {
    const params = {
      username: username
    };

    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/username`, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  getAllBrands: async () => {
    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/all`)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  uploadBrandProfilePicture: async (brand_id: string, file: any) => {
    const params = {
      infd_id: brand_id
    };

    return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/profile/profile_pic`, file, { params: params })
      .then((response) => {
        console.log("uploaded profile picture");
        return response.data;
      }).catch((error) => {
        console.log("unable to upload profile picture");
        console.error(error);
        return null;
      });
  },

  uploadBrandCoverPicture: async (brand_id: string, file: any) => {
    const params = {
      infd_id: brand_id
    };

    return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/profile/cover_pic`, file, { params: params })
      .then((response) => {
        console.log("uploaded cover picture");
        return response.data;
      }).catch((error) => {
        console.log("unable to upload cover picture");
        console.error(error);
        return null;
      });
  },

  upsertBrandProfileInformation: async (user_id: string, name: any, username: any, homeMountain: any,
    sports: string[], trickInterests: string[], instagram: any, tiktok: any,
    youtube: any, website: any, website_label: any, discord: any, twitter: any,
    brand_description: string, contact: string, setup: string) => {
    const params = {
      infd_id: user_id,
      name: name,
      username: username,
      home_mountain: homeMountain,
      instagram: instagram,
      tiktok: tiktok,
      youtube: youtube,
      website: website,
      website_label: website_label,
      discord: discord,
      twitter: twitter,
      brand_description: brand_description,
      contact: contact,
      setup: setup
    };

    const data = {
      sports: sports,
      trick_interests: trickInterests
    }

    return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/profile/info`, data, { params: params })
      .then((response) => {
        console.log("uploaded profile info");
        return response.data;
      }).catch((error) => {
        console.log("unable to upload profile info");
        console.error(error);
        return null;
      });
  },

  followBrand: async (userId: string, followingId: string, follow: boolean) => {
    const params = {
      auth_id: userId,
      user_id: followingId,
      follow: follow
    };

    return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/follow`, null, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  followUser: async (userId: string, followingId: string, follow: boolean) => {
    const params = {
      auth_id: userId,
      user_id: followingId,
      follow: follow
    };

    return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/follow/user`, null, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  addRiderToBrand: async (brandId: string, userId: string, add: boolean) => {
    const params = {
      brand_id: brandId,
      user_id: userId,
      add: add
    };

    return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/rider`, null, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  upsertRidersToBrand: async (auth_id: string, userIds: string[]) => {
    let userIdParam = userIds.join(',');
    if (userIds.length === 0) {
      userIdParam = undefined;
    }

    const params = {
      auth_id: auth_id,
      user_ids: userIdParam,
    };

    return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/rider/all`, null, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  upsertPostForBrand(post_id: string, brand_id: string, title: string, description: string, link: string, file: any,
    post_pic_url: string) {
    let params: any = {
      brand_id: brand_id,
      title: title,
      description: description,
      link: link,
    };

    if (post_id) {
      params = {
        ...params,
        post_id: post_id
      }
    }

    if (post_pic_url) {
      params = {
        ...params,
        post_pic_url: post_pic_url
      }
    }

    return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/post`, file, { params: params })
      .then((response) => {
        console.log("uploaded post");
        return response.data;
      }).catch((error) => {
        console.log("unable to upload post");
        console.error(error);
        return null;
      });
  },

  deletePostForBrand(post_id: string, brand_id: string) {
    const params = {
      post_id: post_id,
      brand_id: brand_id
    };

    return axios.delete(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/post`, { params: params })
      .then((response) => {
        console.log("deleted post");
        return response.data;
      }).catch((error) => {
        console.log("unable to delete post");
        console.error(error);
        return null;
      });
  },

  upsertEventForBrand(event_id: string, brand_id: string, eventName: string, eventLocation: string, eventStartDate: string,
    eventEndDate: string, file: any, event_pic_url: string, eventTag: string, eventSports: string[],
    eventFeatures: string[], eventDescription: string, eventTrickTags: string[],
    eventTricks: string[][]) {
    const eventTrickTagsStrArray = formEventTricks(eventTricks);

    const params = {
      event_id: event_id,
      brand_id: brand_id,
      start_date: eventStartDate,
      end_date: eventEndDate,
      name: eventName,
      location: eventLocation,
      event_tag: eventTag,
      sports: eventSports.join(','),
      features: eventFeatures.join(','),
      description: eventDescription,
      event_tricks: eventTrickTagsStrArray.length > 0 ? eventTrickTagsStrArray.join('%') : undefined,
      event_trick_tags: eventTrickTags.length > 0 ? eventTrickTags.join(',') : undefined,
      event_pic_url: event_pic_url
    };

    return axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/event`, file, { params: params })
      .then((response) => {
        console.log("uploaded event");
        return response.data;
      }).catch((error) => {
        console.log("unable to upload event");
        console.error(error);
        return null;
      });
  },

  getEventTricks: async (eventId: string, eventStartDate: string, eventEndDate: string,
    eventTag: string, eventSports: string[], eventFeatures: string[],
    eventTrickTags: string[], eventTricks: string[][]) => {
    const eventTrickTagsStrArray = formEventTricks(eventTricks);

    const params = {
      event_id: eventId,
      start_date: eventStartDate,
      end_date: eventEndDate,
      event_tag: eventTag,
      sports: eventSports.join(','),
      features: eventFeatures.join(','),
      event_tricks: eventTrickTagsStrArray.length > 0 ? eventTrickTagsStrArray.join('%') : undefined,
      event_trick_tags: eventTrickTags.length > 0 ? eventTrickTags.join(',') : undefined,
    };

    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/event/tricks`, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  deleteBrandEvent: async (brand_id: string, event_id: string) => {
    const params = {
      event_id: event_id,
      brand_id: brand_id
    };

    return await axios.delete(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/event`, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  getBrandEvents: async (brandId: string) => {
    const params = {
      brand_id: brandId
    };

    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/events`, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  getBrandPosts: async (brandId: string) => {
    const params = {
      brand_id: brandId
    };

    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/brand/posts`, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  getAllEvents: async () => {
    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/events`)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  getEventById: async (eventId: string) => {
    const params = {
      event_id: eventId
    };

    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/event`, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  getEventBoards: async (eventId: string) => {
    const params = {
      event_id: eventId
    };

    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/event/boards`, { params: params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  getBoardEventResults: async (params: {
    board_id: string,
    board_type: string,
    sort: string,
    limit: number,
    page: number
  }) => {
    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/event/boards/results`, { params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },

  getBoardEventInfoResults: async (params: {
    board_id: string,
    sort: string,
    limit: number,
    page: number
  }) => {
    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/event/boards/results/info`, { params })
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  },
};

export default BrandsService;