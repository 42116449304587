import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { UserTrickSearchMetadata } from '../models/userTrickSearchMetadata';
import { filterTricksForBlockedIds, getDateNowUTCISO } from '../services/utils';
import UserService from '../services/user.service';
import { useAnalytics } from '../AppAnalyticsProvider';
import { getAdjustedTiming, QueryTiming } from './useQueryConfig';

const numberOfFollowTricksToRetrieve = 6;

interface FollowTricksPage {
    tricks: UserTrickSearchMetadata[];
    nextCursor: string | undefined;
    isLastPage: boolean;
}

const useFollowTricks = (
    userId: string,
    following: number,
    blockedUsers: string[],
    blockingUsers: string[],
    isAuthed: boolean,
    externalTricks?: UserTrickSearchMetadata[]
) => {
    const queryClient = useQueryClient();
    const { trackEvent } = useAnalytics();

    const deduplicateTricks = (tricks: UserTrickSearchMetadata[]): UserTrickSearchMetadata[] => {
        const seen = new Set<string>();
        return tricks.filter(trick => {
            if (seen.has(trick.trick_id)) {
                return false; // Skip duplicates
            }
            seen.add(trick.trick_id);
            return true; // Keep unique tricks
        });
    };

    const processFollowTricksForBlockedUsers = async (initialTricks: UserTrickSearchMetadata[]): Promise<UserTrickSearchMetadata[]> => {
        let tricks = initialTricks;
        let processedTricks: UserTrickSearchMetadata[] = [];

        while (tricks.length > 0) {
            processedTricks = filterTricksForBlockedIds(tricks, blockedUsers, blockingUsers);

            if (processedTricks.length > 0) {
                break;
            }

            tricks = await UserService.getFollowingTricks(
                userId,
                tricks[tricks.length - 1].date_uploaded,
                numberOfFollowTricksToRetrieve
            );

            if (tricks.length === 0) {
                break;
            }
        }

        return processedTricks;
    };

    const fetchFollowTricks = async ({ pageParam = getDateNowUTCISO() }: { pageParam?: string }): Promise<FollowTricksPage> => {
        trackEvent('follow_tricks_fetch', {
            user_id: userId,
            following_count: following,
            cursor: pageParam,
            batch_size: numberOfFollowTricksToRetrieve
        });

        let followTricks = await UserService.getFollowingTricks(userId, pageParam, numberOfFollowTricksToRetrieve);
        followTricks = await processFollowTricksForBlockedUsers(followTricks);
        followTricks = deduplicateTricks(followTricks);

        trackEvent('follow_tricks_fetch_success', {
            user_id: userId,
            cursor: pageParam,
            tricks_count: followTricks.length
        });

        return {
            tricks: followTricks,
            nextCursor: followTricks.length > 0 ? followTricks[followTricks.length - 1].date_uploaded : undefined,
            isLastPage: followTricks.length === 0
        };
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        error,
        refetch
    } = useInfiniteQuery({
        queryKey: ['followTricks', userId, following],
        queryFn: fetchFollowTricks,
        initialPageParam: getDateNowUTCISO(),
        getNextPageParam: (lastPage: FollowTricksPage) => lastPage.nextCursor,
        enabled: isAuthed && !externalTricks && !!userId && userId !== "",
        ...getAdjustedTiming(QueryTiming.MEDIUM_HIGH_FREQUENCY),
    });

    const tricks = deduplicateTricks(externalTricks || data?.pages.flatMap(page => page.tricks) || []);

    const getMoreFollowingTricks = () => {
        if (hasNextPage && !isFetchingNextPage) {
            trackEvent('follow_tricks_load_more', {
                user_id: userId,
                current_count: tricks.length
            });

            fetchNextPage();
        }
    };

    const invalidateQueries = async () => {
        trackEvent('follow_tricks_refresh', {
            user_id: userId,
            following_count: following
        });
        await queryClient.resetQueries({ queryKey: ['followTricks', userId, following] });
        return refetch();
    }

    return {
        tricks,
        isLoadingFollowTricks: isLoading,
        error,
        infiniteScrollDisabled: !hasNextPage,
        getMoreFollowingTricks,
        refreshTricks: invalidateQueries
    };
};

export default useFollowTricks;