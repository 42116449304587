import React, { useState, useEffect } from "react";
import {
    IonModal,
    IonContent,
    IonButtons,
    IonButton,
    IonIcon,
    IonSpinner,
    IonImg,
    IonFooter
} from "@ionic/react";
import { closeOutline, checkmarkOutline } from "ionicons/icons";
import UserService from "../../../services/user.service";
import { useQuery } from "@tanstack/react-query";
import IDXModalHeader from "../../ComponentsUI/IDXModalHeader/IDXModalHeader";
import { getAdjustedTiming, QueryTiming } from "../../../hooks/useQueryConfig";

interface DefaultProfilePicturesModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (url: string) => void;
    currentProfilePicture?: string;
}

const DefaultProfilePicturesModal: React.FC<DefaultProfilePicturesModalProps> = ({
    isOpen,
    onClose,
    onSelect,
    currentProfilePicture
}) => {
    const [selectedPicture, setSelectedPicture] = useState<string | null>(null);

    // Reset selected picture when modal opens/closes or current picture changes
    useEffect(() => {
        if (isOpen && currentProfilePicture) {
            setSelectedPicture(currentProfilePicture);
        } else if (!isOpen) {
            setSelectedPicture(null);
        }
    }, [isOpen, currentProfilePicture]);

    const { data: pictures = [], isLoading, error } = useQuery({
        queryKey: ['defaultProfilePictures'],
        queryFn: () => UserService.getDefaultProfilePictures(),
        enabled: isOpen,
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
        refetchOnWindowFocus: false
    });

    const handleImageSelect = (url: string) => {
        setSelectedPicture(url);
    };

    const handleSubmit = () => {
        if (selectedPicture && selectedPicture !== currentProfilePicture) {
            onSelect(selectedPicture);
        }
        onClose();
    };

    const handleClose = () => {
        onClose();
    };

    const isCurrentPicture = (url: string) => {
        return url === currentProfilePicture;
    };

    return (
        <IonModal isOpen={isOpen} onDidDismiss={handleClose} className="default-pfp-modal">
            <IDXModalHeader
                titleText="Choose Profile Picture"
                leftButtonText="Cancel"
                leftButtonAction={handleClose}
                rightButtonText="Submit"
                rightButtonAction={handleSubmit}
            />

            <IonContent className="ion-padding">
                {isLoading ? (
                    <div className="flex items-center justify-center h-full">
                        <IonSpinner />
                    </div>
                ) : error ? (
                    <div className="p-4 text-center text-red-500">
                        Failed to load profile pictures. Please try again.
                    </div>
                ) : (
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                        {pictures.map((picture: string, index: number) => (
                            <div
                                key={index}
                                className={`aspect-square flex flex-col items-center justify-center cursor-pointer p-1 rounded-md transition-all will-change-transform ${selectedPicture === picture ?
                                    'bg-primary-100 ring-2 ring-highlight-secondary' :
                                    'bg-transparent'
                                    }`}
                                onClick={() => handleImageSelect(picture)}
                            >
                                <div className="relative w-full h-full">
                                    <img
                                        src={picture}
                                        alt={`Profile option ${index + 1}`}
                                        className="w-full h-full object-cover rounded-md shadow-md"
                                        loading="eager"
                                        decoding="sync"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </IonContent>
        </IonModal>
    );
};

export default DefaultProfilePicturesModal;
