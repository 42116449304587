import React, { useRef, useCallback, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { IonButton, IonIcon, IonSpinner, IonRefresher, IonRefresherContent } from '@ionic/react';
import { UserTrickSearchMetadata } from '../../../models/userTrickSearchMetadata';
import HomeLoading from '../HomeLoading/HomeLoading';
import HomeTrickCardContainer from '../HomeTrickCardContainer/HomeTrickCardContainer';
import { AppContext } from '../../../AppStateProvider';
import { arrowUpOutline } from 'ionicons/icons';
import { useAuth } from '../../../AuthProvider';
import { useAppState } from '../../../AppListenerProvider';
import { UserType } from '../../../models/usertype';
import AnnouncementsService from '../../../services/announcements.service';
import { Announcement } from '../../../models/announcements';
import RotatingBanner from '../RotatingBanner/RotatingBanner';
import useTVTricks from '../../../hooks/useTVTricks';
import GenericVirtualizedVideoList, { VirtualizedListRef } from '../GenericVirtualizedVideoList/GenericVirtualizedVideoList';
import { useQuery } from '@tanstack/react-query';
import { ElementSizeService } from '../../../services/database/elementsize.service';
import { getAdjustedTiming, QueryTiming } from '../../../hooks/useQueryConfig';

interface Props {
    externalTricks?: UserTrickSearchMetadata[];
    onDataFetched?: (tricks: UserTrickSearchMetadata[]) => void;
    onError?: (error: Error) => void;
    elementSizeService: ElementSizeService | null;
}

export interface ExploreTVVirtualizedListRef {
    scrollToTop: () => void;
    refreshTricks: () => void;
}

const ExploreTVVirtualizedList = forwardRef<ExploreTVVirtualizedListRef, Props>(({
    externalTricks,
    onDataFetched,
    onError,
    elementSizeService
}, ref) => {
    const { state } = React.useContext(AppContext);
    const { isAuthenticated, isLoading } = useAuth();
    const { isNative } = useAppState();

    const {
        tricks,
        isLoadingTVTricks,
        error,
        infiniteScrollDisabled,
        getMoreNewTricks,
        refreshTricks,
    } = useTVTricks(state.blockedUsers, state.blockingUsers, isAuthenticated, externalTricks);

    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    const genericListRef = useRef<VirtualizedListRef>(null);

    const { data: announcements, isLoading: isLoadingAnnoucements, isError: isErrorAnnoucements } = useQuery({
        queryKey: ['activeAnnouncements'],
        queryFn: AnnouncementsService.fetchActiveAnnouncements,
        // For daily updates
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
    });

    useImperativeHandle(ref, () => ({
        scrollToTop: () => {
            genericListRef.current?.scrollToTop();
        },
        refreshTricks: () => {
            refreshTricks();
        }
    }));

    useEffect(() => {
        if (onDataFetched) {
            onDataFetched(tricks);
        }
    }, [tricks, onDataFetched]);

    useEffect(() => {
        if (error && onError) {
            onError(error);
        }
    }, [error, onError]);

    useEffect(() => {
        if (isLoadingAnnoucements || isErrorAnnoucements) {
            return;
        }

        if (announcements.length === 0) {
            return;
        }

        if (announcements.length > 0) {
            window.prerenderReady = true;
        }

        const interval = setInterval(() => {
            setCurrentTextIndex((prevIndex) =>
                prevIndex === announcements.length - 1 ? 0 : prevIndex + 1
            );
        }, 4000);

        return () => {
            clearInterval(interval);
        };
    }, [announcements, isLoadingAnnoucements]);

    const renderItem = useCallback((trick: UserTrickSearchMetadata, isScrolling: boolean, scrollSpeed: number) => (
        <HomeTrickCardContainer
            trick={trick}
            isAuthed={isAuthenticated}
            isCapacitor={isNative}
            userId={state.user?.auth_id}
            publicId={state.user?.id}
            userType={UserType.USER}
            isScrolling={isScrolling}
            scrollSpeed={scrollSpeed}
        />
    ), [isAuthenticated, isNative, state.user]);

    const renderEmptyList = useCallback(() => {
        if (isLoadingTVTricks) {
            return <HomeLoading />;
        } else {
            return (
                <div className="h-screen flex flex-row justify-center">
                    <div className="font-bold m-4 text-center">
                        Looks like we don't have what you're looking for based on your filters set at the toolbar,
                        change them or change the game to see more!
                    </div>
                </div>
            );
        }
    }, [isLoadingTVTricks]);

    const renderHeader = useCallback(() => {
        return (
            <div className="mb-2">
                <RotatingBanner
                    initialTextArray={announcements}
                    currentTextIndex={currentTextIndex}
                    isError={isErrorAnnoucements}
                    isLoadingAnnoucements={isLoadingAnnoucements}
                />
            </div>
        );
    }, [announcements, currentTextIndex, refreshTricks]);

    const renderFooter = useCallback(() => {
        if (tricks.length > 0 && !infiniteScrollDisabled) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonSpinner color="theme-secondary"></IonSpinner>
                </div>
            );
        } else if (infiniteScrollDisabled) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonButton
                        fill="clear"
                        color="theme-secondary"
                        size="small"
                        className="pb-4"
                        onClick={() => genericListRef.current?.scrollToTop()}
                    >
                        <span className="text-zinc-300 text-lg">
                            Back to top
                        </span>
                        <IonIcon slot="end" icon={arrowUpOutline} />
                    </IonButton>
                </div>
            );
        } else {
            return null;
        }
    }, [tricks.length, infiniteScrollDisabled]);

    return (
        <GenericVirtualizedVideoList
            elementSizeService={elementSizeService}
            ref={genericListRef}
            items={tricks}
            isLoading={isLoadingTVTricks}
            isAuthenticated={isAuthenticated}
            infiniteScrollDisabled={infiniteScrollDisabled}
            loadMoreItems={getMoreNewTricks}
            refreshItems={refreshTricks}
            renderItem={renderItem}
            renderEmptyList={renderEmptyList}
            renderHeader={renderHeader}
            renderFooter={renderFooter}
            isNative={isNative}
        />
    );
});

export default ExploreTVVirtualizedList;