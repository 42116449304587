import React, { useEffect, useState } from 'react';
import { IonActionSheet, useIonToast } from "@ionic/react";
import { skullOutline } from "ionicons/icons";
import SettingsService from "../../../services/settings.service";
import UserService from "../../../services/user.service";
import { AppContext } from "../../../AppStateProvider";
import { useAnalytics } from '../../../AppAnalyticsProvider';

interface ReportReasonProps {
    text: string;
    usernameToReport: string; // Tailwind class for background color, optional
    reportedId: string; // Tailwind class for border color, optional
    reportingUsername: string; // Tailwind class for border color, optional
    reportingId: string; // Optional click handler
    objectIdToReport: string; // Optional image URL
    handleIsOpen: (isOpen: boolean) => void;
    isOpenParent: boolean;

}

const ReportReason: React.FC<ReportReasonProps> = ({
    text,
    usernameToReport,
    reportingUsername,
    objectIdToReport,
    reportingId,
    isOpenParent,
    handleIsOpen,
    reportedId
}) => {

    const { state, dispatch } = React.useContext(AppContext);
    const [isOpen, setIsOpen] = useState(isOpenParent);
    const [present] = useIonToast();
    const { trackEvent } = useAnalytics();

    useEffect(() => {
        if (isOpenParent) {
            trackEvent('report_sheet_open', {
                reporter_id: reportingId,
                reporter_username: reportingUsername,
                reported_id: reportedId,
                reported_username: usernameToReport,
                object_id: objectIdToReport
            });
        }
        setIsOpen(isOpenParent);
    }, [isOpenParent]);


    const handleActionSheet = async (e: CustomEvent) => {
        console.log("handleActionSheet: " + JSON.stringify(e.detail));
        if (e.detail.data) {
            if (e.detail.data.action === 'delete') {
                trackEvent('report_submit', {
                    reporter_id: reportingId,
                    reporter_username: reportingUsername,
                    reported_id: reportedId,
                    reported_username: usernameToReport,
                    object_id: objectIdToReport,
                    reason: e.detail.data.reason,
                    action_type: 'report'
                });
                await processReportUser(e.detail.data.reason);
            } else if (e.detail.data.action === 'block') {
                trackEvent('report_block_user', {
                    blocker_id: reportingId,
                    blocker_username: reportingUsername,
                    blocked_id: reportedId,
                    blocked_username: usernameToReport,
                    object_id: objectIdToReport,
                    reason: e.detail.data.reason
                });
                await processBlockUser(e.detail.data.reason);
            } else if (e.detail.data.action === 'cancel') {
                trackEvent('report_cancel', {
                    reporter_id: reportingId,
                    reported_username: usernameToReport,
                    object_id: objectIdToReport
                });
            }
        }

        setIsOpen(false);
        handleIsOpen(false);
    }

    const blockUser = async (blocked_id: string, block: boolean) => {
        try {
            await UserService.blockUser(state.user.id, blocked_id, block);
            const blockedUsers: string[] = await UserService.getBlockedUsers(state.user.id);
            dispatch({ type: 'setBlockedUsers', payload: blockedUsers });

            trackEvent('block_user_success', {
                blocker_id: state.user.id,
                blocked_id: blocked_id,
                action: block ? 'block' : 'unblock'
            });
        } catch (error) {
            trackEvent('block_user_error', {
                blocker_id: state.user.id,
                blocked_id: blocked_id,
            });
        }
    }


    const processReportUser = async (reason: string) => {
        trackEvent('report_processing', {
            reporter_id: reportingId,
            reported_username: usernameToReport,
            object_id: objectIdToReport,
            reason: reason
        });

        await present({
            message: "The Ecliptic team has been notified of this user and content and will take appropriate action.",
            duration: 4000,
            position: 'top',
            icon: skullOutline,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
        try {
            await SettingsService.sendFeedback(
                reportingId,
                "Username: " + reportingUsername + " wishes to report " + usernameToReport +
                " with the id : " + objectIdToReport + " with the comment: " + text +
                " for the reason: " + reason
            );

            trackEvent('report_submit_success', {
                reporter_id: reportingId,
                reported_username: usernameToReport,
                object_id: objectIdToReport,
                reason: reason
            });
        } catch (error) {
            trackEvent('report_submit_error', {
                reporter_id: reportingId,
                reported_username: usernameToReport,
                object_id: objectIdToReport,
                reason: reason,
            });
            console.error(error);
        }

        return;
    }

    const processBlockUser = async (reason: string) => {
        trackEvent('block_processing', {
            blocker_id: reportingId,
            blocked_username: usernameToReport,
            object_id: objectIdToReport,
            reason: reason
        });

        await present({
            message: "This user has been blocked and removed from your Ecliptic experience",
            duration: 4000,
            position: 'top',
            icon: skullOutline,
            buttons: [
                {
                    text: 'Unblock',
                    role: 'block',
                    handler: async () => {
                        await blockUser(reportedId, false);
                    },
                },
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });

        try {
            await blockUser(reportedId, true);
        } catch (error) {
            console.error(error);
        }

        return;
    }

    return (
        <>
            <IonActionSheet
                isOpen={isOpen}
                buttons={[
                    {
                        text: 'Too Much Gas',
                        role: 'destructive',
                        data: {
                            reason: 'Too Much Gas',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'My Significant Other is on Ecliptic',
                        role: 'destructive',
                        data: {
                            reason: 'My Significant Other is on Ecliptic',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'My Mom is on Ecliptic',
                        role: 'destructive',
                        data: {
                            reason: 'My Significant Other is on Ecliptic',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'My Dad is on Ecliptic',
                        role: 'destructive',
                        data: {
                            reason: 'My Significant Other is on Ecliptic',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'Too Cooked',
                        role: 'destructive',
                        data: {
                            reason: 'Too Cooked',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'Technical Overload',
                        role: 'destructive',
                        data: {
                            reason: 'Technical Overload',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'Hate/Abuse/Spam',
                        role: 'destructive',
                        data: {
                            reason: 'Hate/Abuse/Spam',
                            action: 'delete',
                        },
                    },
                    {
                        text: `Report ${usernameToReport}`,
                        role: 'destructive',
                        data: {
                            reason: `Report ${usernameToReport}`,
                            action: 'delete',
                        },
                    },
                    {
                        text: `Block ${usernameToReport}`,
                        role: 'destructive',
                        data: {
                            reason: 'block',
                            action: 'block',
                        },
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        data: {
                            reason: 'Cancel',
                            action: 'cancel',
                        },
                    },
                ]}
                onDidDismiss={(e) => handleActionSheet(e)}
            />
        </>
    );
};

export default ReportReason;