import { OverlayEventDetail } from "@ionic/core/components";
import {
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPopover,
    useIonAlert,
    useIonModal,
    useIonToast
} from "@ionic/react";
import {
    ellipsisHorizontalOutline,
    flagOutline,
    pencilOutline,
    personAddOutline,
    personRemoveOutline,
    skullOutline,
    trashOutline
} from "ionicons/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import UserService from "../../../services/user.service";
import InstagramShare from "../../ComponentsShare/InstagramShare/InstagramShare";
import TrickShare from "../../ComponentsShare/TrickShare/TrickShare";
import { UserTrickSearch } from "../../../models/user-trick-search";
import TricksService from "../../../services/tricks.service";
import TrickDownload from "../TrickDownload/TrickDownload";
import { AppContext } from "../../../AppStateProvider";
import { UserTrick } from "../../../models/user-trick";
import { LocationInput } from "../../../models/locationInput";
import EditTrick from "../../ComponentsVideo/EditTrick/EditTrick";
import { SubscriptionType } from "../../../models/subscriptionType";
import IDXButtonIcon from "../IDXButtonIcon/IDXButtonIcon";
import { useAnalytics } from "../../../AppAnalyticsProvider";
import { UserTrickSearchMetadata } from "../../../models/userTrickSearchMetadata";
import { FilmerInfo } from "../../../models/filmerInto";

interface ContainerProps {
    isCapacitor: boolean;
    trick: UserTrickSearchMetadata | UserTrick;
    isAuthed: boolean;
    userId: string,
    trickId: string;
    publicId: string;
    userType: string;
    displayTrickShare: boolean;
    displayTrickInstagramShare: boolean;
    displayTrickDownload: boolean;
    userOnDisplayUsername: string;
    size?: "default" | "small" | "large";
    displayEditTrick?: boolean;
    editTrick?: boolean;
    editTrickCallback?: (trick: UserTrick) => void;
    deleteTrickCallback?: () => void;
    onReportTrick?: () => void;
}

const TrickCardExtraPopover: React.FC<ContainerProps> = (props: ContainerProps) => {
    const { state, dispatch } = useContext(AppContext);
    const [present] = useIonToast();
    const { trackEvent } = useAnalytics();

    const shareSavePopover = useRef<HTMLIonPopoverElement>(null);
    const [shareSavePopoverOpen, setShareSavePopoverOpen] = useState(false);
    const [trickSocialUrl, setTrickSocialUrl] = React.useState(undefined);

    const [isFollowingUser, setIsFollowingUser] = useState<boolean>(false);
    const [displayFollowingUser, setDisplayFollowingUser] = useState<boolean>(false);

    const [presentAlert] = useIonAlert();
    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
    const [subscriptionLevel, setSubscriptionLevel] = useState<string>("");

    useEffect(() => {
        if (state.isSubscribed) {
            setIsSubscribed(true);
            setSubscriptionLevel(state.subscriptionLevel);
        } else {
            setIsSubscribed(false);
            setSubscriptionLevel(SubscriptionType.None);
        }
    }, [state.isSubscribed]);

    useEffect(() => {

    }, [props.editTrick]);

    useEffect(() => {
        if (props.trick?.video) {
            setTrickSocialUrl(props.trick.video);
        }

        if (props.trickId && props.trick?.user_id && props.isCapacitor) {
            getTrickSocialUrl(props.trickId, props.trick.user_id);
        }
    }, [props.trick, props.isCapacitor, props.trickId]);

    useEffect(() => {
        const getSocialTrickUrl = async () => {
            setTrickSocialUrl(props.trick.video);
            const social_url = await TricksService.getUserTrickSocialUrl(props.trick.trick_id, props.trick.user_id)
            if (social_url !== undefined) {
                setTrickSocialUrl(social_url['url']);
            }
        }

        if (props.trick?.video && props.trick?.user_id && props.trick?.trick_id) {
            getSocialTrickUrl();
        }

    }, [props.trick?.trick_id, props.trick?.video, props.trickId, props.trick?.user_id]);

    useEffect(() => {
        const checkToSeeIfUserIsBeingFollowed = async (loggedInUserId: string, userId: string) => {
            setIsFollowingUser(await UserService.checkIfUserFollowingOtherUser(loggedInUserId, userId));
        }

        // get if user is following the user with the trick or if the user is the same as the trick
        if (!props.isAuthed) {
            setIsFollowingUser(false);
            return;
        }

        if (props.trick?.user_id === props.publicId) {
            setDisplayFollowingUser(false);
        } else {
            setDisplayFollowingUser(true);
        }

        checkToSeeIfUserIsBeingFollowed(props.publicId, props.trick?.user_id);
    }, [props.publicId, props.trick, props.isAuthed, state.following]);

    const openShareSavePopover = (e: any) => {
        shareSavePopover.current!.event = e;
        setShareSavePopoverOpen(true);
    };

    const getTrickSocialUrl = async (trickId: string, userId: string) => {
        trackEvent('social_url_fetch', {
            trick_id: trickId,
            user_id: userId
        });

        const social_url = await TricksService.getUserTrickSocialUrl(trickId, userId)
        if (social_url !== undefined) {
            trackEvent('social_url_success', {
                trick_id: trickId,
                user_id: userId,
                url_type: 'social'
            });
            setTrickSocialUrl(social_url['url']);
        } else {
            trackEvent('social_url_fail', {
                trick_id: trickId,
                user_id: userId
            });
        }
    }

    const presentToast = async (message: string, duration: number, icon: string) => {
        trackEvent('toast_display', {
            message,
            duration,
            icon: icon.toString(),
            context: 'trick_interaction' // or whatever context is appropriate
        });

        await present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        trackEvent('toast_dismiss', {
                            message,
                            action: 'manual_dismiss'
                        });
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => {
                trackEvent('toast_dismiss', {
                    message,
                    action: e.detail.role,
                    auto_dismissed: e.detail.role === 'timeout'
                });
            }
        });
    };

    const reportUser = () => {
        props.onReportTrick();
    }

    const onFollowUserFromHome = async (userBeingFollowedId: string, followingUserState: boolean) => {
        const response = await UserService.followUserV2(props.publicId, userBeingFollowedId, !followingUserState);
        if (response) {
            return !followingUserState;
        }
    }

    const processFollowUser = async () => {
        if (!props.isAuthed) {
            trackEvent('follow_auth_prompt', {
                target_user_id: props.trick.user_id,
                target_username: props.userOnDisplayUsername
            });
            await presentToast("You must be logged in to follow a user!", 1500, skullOutline);
        } else {
            const previousFollowingUserState = isFollowingUser;
            const newFollowState = !previousFollowingUserState;

            trackEvent('follow_toggle_attempt', {
                follower_id: props.publicId,
                target_user_id: props.trick.user_id,
                target_username: props.userOnDisplayUsername,
                action: newFollowState ? 'follow' : 'unfollow'
            });

            if (isFollowingUser) {
                await presentToast("Bet", 3000, skullOutline);
                setIsFollowingUser(false);
            } else {
                await presentToast("You are now following " + props.userOnDisplayUsername, 3000, personAddOutline);
                setIsFollowingUser(true);
            }

            try {
                const response = await onFollowUserFromHome(props.trick.user_id, previousFollowingUserState);
                setIsFollowingUser(response);
                const followingCount = await UserService.getFollowCount(props.publicId, false);
                dispatch({ type: "setFollowing", payload: followingCount });

                trackEvent('follow_toggle_success', {
                    follower_id: props.publicId,
                    target_user_id: props.trick.user_id,
                    target_username: props.userOnDisplayUsername,
                    action: response ? 'follow' : 'unfollow',
                    following_count: followingCount
                });
            } catch (error) {
                trackEvent('follow_toggle_error', {
                    follower_id: props.publicId,
                    target_user_id: props.trick.user_id,
                    target_username: props.userOnDisplayUsername,
                });
            }
        }
    }

    const onEditTrickSubmit = async (trick: UserTrick, user_id: string, locationDetails: LocationInput, filmerInput: FilmerInfo) => {
        await UserService.updateUserTrick(trick, user_id, locationDetails, filmerInput);
        props.editTrickCallback(trick);
    }

    const [presentEditTrick, dismiss] = useIonModal(EditTrick, {
        onDismiss: (data: string, role: string) => dismiss(data, role),
        onEditTrickSubmit: onEditTrickSubmit,
        userTrick: { ...props.trick }
    });

    const onEditTrick = async (trick: UserTrick) => {
        presentEditTrick({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    };

    const onDeleteTrick = async () => {
        await presentAlert({
            header: 'Delete Clip',
            message: 'Are you want to delete this clip?',
            buttons: [
                'Cancel',
                {
                    text: 'Delete', handler: async () => {
                        props.deleteTrickCallback();
                    }
                }
            ]
        })
    };

    return (
        <div>
            <IonButton size={props.size ? props.size : "large"} fill="clear"
                onClick={openShareSavePopover}>
                <IDXButtonIcon icon={ellipsisHorizontalOutline}
                    size={props.size ? props.size === 'small' ? 'text-2xl' : "text-3xl" : "text-3xl"}
                    color="theme-alternative" />
            </IonButton>
            <IonPopover dismissOnSelect={true} size="auto" ref={shareSavePopover} isOpen={shareSavePopoverOpen}
                onDidDismiss={() => setShareSavePopoverOpen(false)} side="bottom" alignment="start">
                <IonContent>
                    <IonList>
                        {
                            props.displayTrickShare &&
                            <TrickShare isCapacitor={props.isCapacitor} trickId={props.trickId} trick={props.trick}
                                list={true}></TrickShare>
                        }
                        {/* {
                            props.isCapacitor && props.displayTrickInstagramShare &&
                            <InstagramShare backgroundVideoURL={trickSocialUrl ? trickSocialUrl : props.trick?.video}
                                stickerImageURL={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                                size="sm" list={true} />
                        } */}
                        {
                            props.displayTrickDownload &&
                            <TrickDownload isCapacitor={props.isCapacitor} trickId={props.trickId} trick={props.trick}
                                list={true} isSubscribed={isSubscribed}
                                subscriptionLevel={subscriptionLevel} />
                        }
                        {
                            displayFollowingUser &&
                            <IonItem button={true} detail={false} onClick={() => processFollowUser()}>
                                <IonIcon aria-hidden="true"
                                    icon={isFollowingUser ? personRemoveOutline : personAddOutline}
                                    slot="start"></IonIcon>
                                <IonLabel>{isFollowingUser ? "Unfollow" : "Follow"}</IonLabel>
                            </IonItem>
                        }
                        {
                            displayFollowingUser &&
                            <IonItem button={true} detail={false} onClick={() => reportUser()}>
                                <IonIcon aria-hidden="true" icon={flagOutline} slot="start"></IonIcon>
                                <IonLabel>Report</IonLabel>
                            </IonItem>
                        }
                        {
                            props.displayEditTrick && props.editTrick &&
                            <IonItem button={true} detail={false} onClick={() => onEditTrick(props.trick)}>
                                <IonIcon aria-hidden="true" icon={pencilOutline} slot="start"></IonIcon>
                                <IonLabel>Edit Trick</IonLabel>
                            </IonItem>
                        }

                        {
                            props.displayEditTrick && props.editTrick &&
                            <IonItem button={true} detail={false} onClick={() => onDeleteTrick()}>
                                <IonIcon aria-hidden="true" icon={trashOutline} slot="start"></IonIcon>
                                <IonLabel>Delete Trick</IonLabel>
                            </IonItem>
                        }
                    </IonList>
                </IonContent>
            </IonPopover>
        </div>
    );
};

export default TrickCardExtraPopover;