import { IonButton, IonHeader, IonIcon, IonToolbar, useIonToast } from "@ionic/react";
import { cogOutline, notificationsOutline, searchOutline, storefrontOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useAuthService from "../../../hooks/useAuthService";
import PreferencesService from "../../../services/preferences.service";
import IDXButtonIcon from "../IDXButtonIcon/IDXButtonIcon";
import IDXLogo from "../../../assets/icons/system/ECLIPTIC_NEW_BLACK_PNG_NO_PADDING_BETA.svg";

interface ToolbarSearchBarExploreProps {
    handleSegmentChangeNew: (value: string) => void;
    isAuthenticated: boolean;
}

const ToolbarSearchBarExplore: React.FC<ToolbarSearchBarExploreProps> = ({
    handleSegmentChangeNew,
    isAuthenticated
}) => {
    const history = useHistory();
    const { login } = useAuthService()
    const [present] = useIonToast();

    const [segmentValue, setSegmentValue] = useState<string>("explore");

    const openSettings = () => {
        history.push("/settings");
    }
    const openSearch = () => {
        history.push("/search");
    }

    const options = [
        { value: 'explore', label: 'TV' },
        { value: 'following', label: 'Following' },
        { value: 'new', label: 'New' },
        { value: 'hot', label: 'Hot' }
    ];

    const presentToast = (message: string, duration: number, icon: string) => {
        present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    // TODO: animate placeholder for search bar when clicked on but nothing typed in

    const handleSmokeShack = () => {
        history.push("/shop");
    }

    const handleSegmentChangeNewChild = async (selectedValue: string) => {
        setSegmentValue(selectedValue);
        await PreferencesService.setExploreTab(selectedValue);
        handleSegmentChangeNew(selectedValue);
    }

    useEffect(() => {
        const getSegmentPreferences = async () => {
            const result = await PreferencesService.getExploreTab();
            if (result) {
                setSegmentValue(result);
                handleSegmentChangeNew(result);
            } else {
                setSegmentValue(isAuthenticated ? "new" : "explore");
                handleSegmentChangeNew(isAuthenticated ? "new" : "explore");
            }
        }

        getSegmentPreferences();
    }, [isAuthenticated]);

    return (
        <>
            <IonHeader className="bg-grey">
                <IonToolbar>
                    <div className="flex flex-row justify-between items-center mx-2">
                        <div className="flex order-first gap-x-4">
                            <div>
                                <IonButton fill="clear" size="small" color="dark" onClick={openSearch}>
                                    <IDXButtonIcon size="text-2xl" icon={searchOutline} />
                                </IonButton>
                            </div>
                            <div>
                                <IonButton fill="clear" size="small" color="dark" onClick={() => handleSmokeShack()}>
                                    <IDXButtonIcon size="text-2xl" icon={storefrontOutline} />
                                </IonButton>
                            </div>
                        </div>
                        <div className="flex items-center justify-center pl-4"> {/* Adjust h-12 as needed */}
                            <IonIcon
                                icon={IDXLogo}
                                className="w-20 h-12" // This will make the icon fill the height of its container
                                onClick={() => history.push('/home')}
                            />
                        </div>
                        <div className="flex order-last gap-x-4">
                            <div className="opacity-0">
                                <IonButton fill="clear" size="small" color="dark"
                                    onClick={() => presentToast("Keeping track of alerts is almost here!", 3500, notificationsOutline)}>
                                    <IDXButtonIcon size="text-2xl" icon={notificationsOutline} />
                                </IonButton>
                            </div>
                            <div>
                                <IonButton fill="clear" size="small" color="dark"
                                    onClick={() => isAuthenticated ? openSettings() : login()}>
                                    <IDXButtonIcon size="text-2xl" icon={cogOutline} />
                                </IonButton>
                            </div>
                        </div>
                    </div>
                </IonToolbar>
                <div>
                    <div className="flex min-h-12">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className={`flex-1 py-3 px-4 text-center text-sm uppercase ${segmentValue === option.value ? 'bg-[#000000] text-white border-b-2 border-white font-bold' : 'bg-[#000000] text-[#9c9c9c]'}`}
                                onClick={(e) => {
                                    handleSegmentChangeNewChild(option.value)
                                }}
                            >
                                <span className="flex flex-row items-center justify-center">
                                    {option.label}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </IonHeader>
        </>
    );
};

export default ToolbarSearchBarExplore;
