import { useInfiniteQuery } from "@tanstack/react-query";
import BrandsService from "../services/brand.service";
import { BoardType } from "../models/boardType";
import { getAdjustedTiming, QueryTiming } from "./useQueryConfig";

interface UseBoardEventResultsProps {
    boardId?: string;
    boardType?: string;
    sort?: string;
}

export const useBoardEventResults = ({ boardId, boardType, sort }: UseBoardEventResultsProps) => {
    const resultsPerPage = 20;

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        error,
        refetch
    } = useInfiniteQuery({
        queryKey: ['boardEventResults', boardId, boardType, sort],
        queryFn: async ({ pageParam = 1 }) => {
            if (!boardId || !boardType || !sort) {
                return [];
            }
            if (boardType != BoardType.IDX_INFO) {
                return BrandsService.getBoardEventResults({
                    board_id: boardId,
                    board_type: boardType,
                    sort: sort,
                    limit: resultsPerPage,
                    page: pageParam
                });
            } else {
                return BrandsService.getBoardEventInfoResults({
                    board_id: boardId,
                    sort: sort,
                    limit: resultsPerPage,
                    page: pageParam
                });
            }
        },
        // Since boards update daily
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
        structuralSharing: true,    // Enable structural sharing
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.length === resultsPerPage ? allPages.length + 1 : undefined;
        },
        enabled: Boolean(boardId && boardType && sort),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    return {
        results: data?.pages.flat() || [],
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        error,
        fetchNextPage,
        refetch
    };
};