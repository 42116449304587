import { UserTrickSearchMetadata } from '../models/userTrickSearchMetadata';
import { filterTricksForBlockedIds, getDateNowUTCISO } from '../services/utils';
import UserService from '../services/user.service';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useAnalytics } from '../AppAnalyticsProvider';
import { getAdjustedTiming, QueryTiming } from './useQueryConfig';

interface NewTricksPage {
    tricks: UserTrickSearchMetadata[];
    nextCursor: string | undefined;
    isLastPage: boolean;
}

const useNewTricks = (
    blockedUsers: string[],
    blockingUsers: string[],
    isAuthed: boolean,
    externalTricks?: UserTrickSearchMetadata[],
    numberOfTricksToRetrieve: number = 6
) => {
    const queryClient = useQueryClient();
    const { trackEvent } = useAnalytics();

    const deduplicateTricks = (tricks: UserTrickSearchMetadata[]): UserTrickSearchMetadata[] => {
        const seen = new Set<string>();
        return tricks.filter(trick => {
            if (seen.has(trick.trick_id)) {
                return false; // Skip duplicates
            }
            seen.add(trick.trick_id);
            return true; // Keep unique tricks
        });
    };

    const processNewTricksForBlockedUsers = async (initialTricks: UserTrickSearchMetadata[]): Promise<UserTrickSearchMetadata[]> => {
        let currentTricks = initialTricks;
        let processedTricks: UserTrickSearchMetadata[] = [];

        while (currentTricks.length > 0) {
            processedTricks = filterTricksForBlockedIds(currentTricks, blockedUsers, blockingUsers);

            if (processedTricks.length > 0) {
                break;
            }

            const lastTrickDate = currentTricks[currentTricks.length - 1]?.date_uploaded;
            if (!lastTrickDate) {
                break;
            }

            try {
                currentTricks = await UserService.getNewUserTricks(
                    numberOfTricksToRetrieve,
                    lastTrickDate
                );
            } catch (error) {
                console.error('Error fetching new user tricks:', error);
                break;
            }

            if (currentTricks.length === 0) {
                break;
            }
        }

        return processedTricks;
    };

    const fetchNewTricks = async ({ pageParam = getDateNowUTCISO() }: { pageParam?: string }): Promise<NewTricksPage> => {
        trackEvent('new_tricks_fetch', {
            cursor: pageParam,
            batch_size: numberOfTricksToRetrieve
        });

        try {
            let newTricks = await UserService.getNewUserTricks(numberOfTricksToRetrieve, pageParam);
            newTricks = await processNewTricksForBlockedUsers(newTricks);

            if (newTricks.length === 0) {
                // If no new user tricks, fetch competition tricks
                trackEvent('new_tricks_fetch_competition', {
                    cursor: pageParam,
                    batch_size: numberOfTricksToRetrieve
                });

                newTricks = await UserService.getNewCompetitionTricks(numberOfTricksToRetrieve, pageParam);
                newTricks = await processNewTricksForBlockedUsers(newTricks);
            }

            newTricks = deduplicateTricks(newTricks);

            trackEvent('new_tricks_fetch_success', {
                cursor: pageParam,
                tricks_count: newTricks.length,
            });

            return {
                tricks: newTricks,
                nextCursor: newTricks.length > 0 ? newTricks[newTricks.length - 1].date_uploaded : undefined,
                isLastPage: newTricks.length === 0
            };
        } catch (error) {
            trackEvent('new_tricks_fetch_error', {
                error_message: error instanceof Error ? error.message : 'Unknown error',
                cursor: pageParam
            });
            throw error;
        }
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        error,
        refetch
    } = useInfiniteQuery({
        queryKey: ['newTricks'],
        queryFn: fetchNewTricks,
        initialPageParam: getDateNowUTCISO(),
        getNextPageParam: (lastPage: NewTricksPage) => lastPage.nextCursor,
        enabled: isAuthed && !externalTricks,
        ...getAdjustedTiming(QueryTiming.MEDIUM_HIGH_FREQUENCY),
        refetchOnWindowFocus: false,
    });

    const tricks = deduplicateTricks(externalTricks || data?.pages.flatMap(page => page.tricks) || []);

    const getMoreNewTricks = () => {
        if (hasNextPage && !isFetchingNextPage) {
            trackEvent('new_tricks_load_more', {
                current_count: tricks.length
            });

            fetchNextPage();
        }
    };

    const invalidateQueries = async () => {
        trackEvent('new_tricks_refresh');
        await queryClient.resetQueries({ queryKey: ['newTricks'] });
        return refetch();
    };

    return {
        tricks,
        isLoadingNewTricks: isLoading,
        error,
        infiniteScrollDisabled: !hasNextPage,
        getMoreNewTricks,
        refreshTricks: invalidateQueries,
    };
};

export default useNewTricks;