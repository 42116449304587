import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonIcon, IonInput } from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import { isEmail } from 'validator';
import { useAppState } from "../../../AppListenerProvider";
import { useAuth } from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";
import '../Welcome.css'
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";
import { Keyboard } from "@capacitor/keyboard";
import { useAnalytics } from '../../../AppAnalyticsProvider';

interface WelcomeLoginUsernameProps {
    title: string,
    showBackButton: boolean,
    showSkipButton: boolean,
    onBack: () => void,
    onNext: () => void,
    onForgotPassword: () => void
}

const WelcomeLoginUsername: React.FC<WelcomeLoginUsernameProps> = ({
    title,
    showBackButton,
    showSkipButton,
    onBack,
    onNext,
    onForgotPassword
}) => {
    const [emailOrUsername, setEmailOrUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const { isActive, isNative, platform } = useAppState();
    const { user, isLoading, isAuthenticated, firebaseApp, auth } = useAuth();
    const authService = AuthService({ user, isLoading, isAuthenticated, firebaseApp, auth }, {
        isActive,
        isNative,
        platform
    });

    const userInput = useRef<HTMLIonInputElement>(null);
    const passwordInput = useRef<HTMLIonInputElement>(null);
    const { trackEvent } = useAnalytics();

    const signInWithEmailPasswordHandler = async (email: string, password: string) => {
        try {
            const res = await authService.loginWithEmailAndPassword(email, password);
            if (res?.isAuthenticated) {
                onNext();
            }
        } catch (error) {
            console.error("Error signing in with Google: ", error);
            setError("Invalid email or password. Please try again or text us at 1-855-MAX-BISK (1-855-629-2475), reach out on IG @ecliptic.day, or email mia@idx.style if you think this was an error and we will get this sorted ASAP!");
        }
    }

    const signInWithUsernamePassword = async (username: string, password: string) => {
        try {
            const res = await authService.loginWithUsernameAndPassword(username, password);
            if (res?.isAuthenticated) {
                onNext();
            }
        } catch (error) {
            console.error("Error signing in with Google: ", error);
            setError("Invalid username or password. Please try again or text us at 1-855-MAX-BISK (1-855-629-2475), reach out on IG @ecliptic.day, or email mia@idx.style if you think this was an error and we will get this sorted ASAP!");
        }
    }

    const handleLogin = async () => {
        trackEvent('login_attempt', {
            login_type: isEmail(emailOrUsername) ? 'email' : 'username',
            platform: platform
        });

        // remove focus from input fields
        userInput.current?.blur();
        passwordInput.current?.blur();

        if (!emailOrUsername) {
            setError('Email is required');
            return;
        }
        if (!password) {
            setError('Password is required');
            return;
        }
        try {
            if (isEmail(emailOrUsername)) {
                console.log('Logging in with email:', emailOrUsername);
                const res = await signInWithEmailPasswordHandler(emailOrUsername, password);
                trackEvent('login_success', {
                    method: 'email',
                    platform: platform
                });
            } else {
                console.log('Logging in with username:', emailOrUsername);
                const res = await signInWithUsernamePassword(emailOrUsername, password);
                trackEvent('login_success', {
                    method: 'username',
                    platform: platform
                });
            }
        } catch (error) {
            trackEvent('login_error', {
                error_type: 'invalid_credentials',
                login_type: isEmail(emailOrUsername) ? 'email' : 'username',
                platform: platform
            });
            setError("Invalid credentials...");
        }
    };

    const setFocusForInput = async () => {
        setTimeout(async () => {
            await userInput.current?.setFocus();
            if (isNative) {
                await Keyboard.show();
            }
        }, 300);
    };

    useEffect(() => {
        console.log("Loading Login In Flow Page");
        setError(null);
        setEmailOrUsername('');
        setPassword('');
        setShowPassword(false);

        // Focus the userInput and show keyboard
        setFocusForInput();
    }, [isNative]);

    const onKeyPress = async (e: any) => {
        if (e.key === 'Enter') {
            await handleLogin();
        }
    }

    const handleSetEmailOrUsername = (emailOrUsername: string) => {
        if (error) setError(null);
        setEmailOrUsername(emailOrUsername);
    }

    const handleSetPassword = (password: string) => {
        if (error) setError(null);
        setPassword(password);
    }

    const onForgotPasswordClick = () => {
        trackEvent('forgot_password_clicked', {
            platform: platform
        });
        onForgotPassword();
    };

    return (
        <OnboardingPageWrapper title={title}
            showBackButton={showBackButton}
            showSkipButton={showSkipButton}
            onBack={onBack}>
            <div className="w-full max-w-md space-y-4 flex flex-col items-center font-bold">
                <div className="w-full relative">
                    <IonInput
                        ref={userInput}
                        type="text"
                        color="light"
                        placeholder="Email or Username"
                        value={emailOrUsername}
                        onIonInput={e => handleSetEmailOrUsername(e.detail.value!)}
                        className="border-b border-primary-secondary p-2 rounded w-full bg-transparent"
                    />
                </div>
                <div className="w-full relative">
                    <IonInput
                        ref={passwordInput}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        color="light"
                        value={password}
                        onIonInput={e => handleSetPassword(e.detail.value!)}
                        className="border-b border-primary-secondary p-2 rounded w-full bg-transparent"
                    />
                    <IonIcon
                        icon={showPassword ? eyeOutline : eyeOffOutline}
                        className="absolute right-3 top-3.5 cursor-pointer z-50 text-primary-secondary"
                        onClick={() => setShowPassword(!showPassword)}
                    />
                </div>
                <div className="text-sm font-bold text-primary-secondary text-center"
                    onClick={onForgotPasswordClick}>
                    Forgot password?
                </div>
                {error && <div className="text-highlight-danger font-bold text-center">{error}</div>}
                <IonButton
                    expand="block"
                    className="neon-button w-full normal-case font-bold"
                    disabled={emailOrUsername === "" || password === ""}
                    onClick={handleLogin}>
                    <span className="text-lg normal-case font-bold">Continue to Ecliptic</span>
                </IonButton>
            </div>
        </OnboardingPageWrapper>
    );
};

export default WelcomeLoginUsername;