import {
    IonContent,
    IonPage,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../AppStateProvider";
import { SubscriptionType } from "../models/subscriptionType";
import ShopRedirect from "../components/ComponentsPurchases/ShopRedirect/ShopRedirect";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import ToolbarSearchBarCommunity from "../components/ComponentsUI/ToolbarSearchBarCommunity/ToolbarSearchBarCommunity";
import useResizeObserverErrorHandler from "../hooks/useResizeObserverHandler";
import { useAppState } from "../AppListenerProvider";
import BiskTransactionList from "../components/ComponentsBisk/BiskTransactionList/BiskTransactionList";
import BoardResultsList from "../components/ComponentsBoard/BoardResultsList/BoardResultsList";
import EventListContainer from "../components/ComponentsEvent/EventListContainer/EventListContainer";
import CrewListContainer from "../components/ComponentsCrew/CrewListContainer/CrewListContainer";
import { useBlur } from "../hooks/useBlur";

const Community: React.FC = () => {
    const history = useHistory();
    useResizeObserverErrorHandler();

    const [segmentValue, setSegmentValue] = useState(undefined);

    const { blurred, blurredClass } = useBlur();

    const handleSegmentChange = async (value: string) => {
        setSegmentValue(value);
    }

    const onShopRedirect = () => {
        history.push('/shop');
    }

    return (
        <IonPage>
            <CustomHelmet title={"Ecliptic // Community"}
                description={"View the Ecliptic community and see all the different park crews around the world"}
                image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                url={`https://ecliptic.day/community`} />
            <ToolbarSearchBarCommunity handleSegmentChange={handleSegmentChange}></ToolbarSearchBarCommunity>
            <IonContent>
                {
                    segmentValue === 'events' ?
                        <>
                            <EventListContainer />
                        </>
                        :
                        segmentValue === 'crews' ?
                            <>
                                <CrewListContainer />
                            </>
                            :
                            segmentValue === 'bisk' ?
                                <>
                                    <BiskTransactionList
                                        blurred={blurred}
                                        onShopRedirect={onShopRedirect} />
                                </>
                                : segmentValue === 'board' ?
                                    <>
                                        <BoardResultsList
                                            blurred={blurred}
                                            onShopRedirect={onShopRedirect} />
                                    </> :
                                    <>
                                        {
                                            <IonContent scrollY={false}>
                                                {
                                                    blurred ?
                                                        <div className="flex flex-col align-middle items-center my-1 mx-4">
                                                            <ShopRedirect
                                                                title={"Head to the Smoke Shack and Sign Up for the Tow Rope Pass to View the Leaderboards!"}
                                                                shopRedirect={onShopRedirect} />
                                                        </div> : <div></div>
                                                }
                                                <div className={blurredClass + " flex flex-row justify-center m"}>
                                                    Coming soon!
                                                </div>
                                            </IonContent>
                                        }
                                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default Community;