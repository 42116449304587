import React, { useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Capacitor } from "@capacitor/core";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonPage,
} from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import WorldWeb from '../components/ComponentsMap/WorldWeb/WorldWeb';
import { LocationTrick } from "../models/locationTrick";
import { UserTrickSearch } from "../models/user-trick-search";
import LocationService from "../services/location.service";
import UserService from "../services/user.service";
import TricksService from "../services/tricks.service";
import { UserType } from "../models/usertype";
import { User } from "../models/user";
import LoginButton from "../components/ComponentsLogin/LoginButton/LoginButton";
import { AppContext } from "../AppStateProvider";
import BiskService from "../services/bisk.service";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import { useAuth } from "../AuthProvider";
import PreferencesService from "../services/preferences.service";
import IonSpinnerMainContent from "../components/ComponentsUI/IonSpinnerMainContent/IonSpinnerMainContent";
import { useAppState } from "../AppListenerProvider";
import { getAdjustedTiming, QueryTiming } from "../hooks/useQueryConfig";

const MapPage: React.FC = () => {
    const { state } = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();
    const { isAuthenticated, isLoading: authLoading } = useAuth();
    const { isNative } = useAppState();
    const queryClient = useQueryClient();

    const [baseLat] = useState<number>(40.6461);
    const [baseLong] = useState<number>(-111.498);
    const [baseZoom] = useState<number>(9);
    const [blurredMap, setBlurredMap] = useState<boolean | undefined>(undefined);
    const [flyTo, setFlyTo] = useState<boolean>(false);
    const [flyToCoords, setFlyToCoords] = useState<number[] | undefined>(undefined);
    const [mapBounds, setMapBounds] = useState<{
        ne_lat: number;
        ne_long: number;
        sw_lat: number;
        sw_long: number;
    } | null>(null);

    const query = new URLSearchParams(location.search);

    const handleBoundsChange = (bounds: {
        ne_lat: number;
        ne_long: number;
        sw_lat: number;
        sw_long: number;
    }) => {
        setMapBounds(bounds);
    };

    const { data: isSubscribed, isLoading: subscriptionLoading } = useQuery({
        queryKey: ['isSubscribed'],
        queryFn: async () => {
            const auth_id = await PreferencesService.getUserUid();
            if (auth_id) {
                try {
                    const user: User = await UserService.getUserByAuthId(auth_id);
                    return await BiskService.getIsSubscribedById(user.id);
                } catch (e) {
                    return false;
                }
            }
            return false;
        },
        enabled: isAuthenticated,
    });

    const { data: authInfo } = useQuery({
        queryKey: ['authInfo', isSubscribed],
        queryFn: async () => {
            const auth_id = await PreferencesService.getUserUid();
            if (auth_id) {
                const userType = await UserService.getUserType(auth_id);
                if (userType === UserType.USER) {
                    const response: User = await UserService.getUserByAuthId(auth_id);
                    if (response) {
                        const userTricks = await TricksService.getUserTricks(response.id);
                        const isUserGod = (await UserService.getIsUserGod(auth_id)).result;
                        return { isUserGod, userTricksCount: userTricks.length };
                    }
                }
                return { userType };
            }
            return null;
        },
        enabled: isAuthenticated && !authLoading,
    });

    // Your existing query modified to use bounds
    const { data: geoJson, isLoading: isGeoJsonLoading, refetch: refetchGeoJson } = useQuery({
        queryKey: ['mapTricks', mapBounds, isSubscribed], // Add bounds to cache key
        queryFn: async () => {
            let res: LocationTrick[];

            if (mapBounds) {
                // Use bounds if available
                res = await LocationService.getAllLocationTricksWithinBound(
                    mapBounds.ne_lat,
                    mapBounds.ne_long,
                    mapBounds.sw_lat,
                    mapBounds.sw_long
                );
            } else {
                // Fallback to getting all locations
                res = await LocationService.getAllLocationTricks();
            }

            const resGeoJsonHolder: any = {
                'type': 'FeatureCollection',
                'features': []
            };

            if (res) {
                const trick_ids = res.map((trick) => trick.trick_id);
                const user_tricks: UserTrickSearch[] = await UserService.getTricksFromIds(trick_ids);

                resGeoJsonHolder.features = res.map(trickLocation => {
                    const trick = user_tricks.find((t) => t.trick_id === trickLocation.trick_id);
                    if (trick) {
                        let long = isSubscribed ? (trickLocation.long || trickLocation.google_long) : trickLocation.google_long;
                        let lat = isSubscribed ? (trickLocation.lat || trickLocation.google_lat) : trickLocation.google_lat;

                        const mapThumbUrl = trick.thumbnail.replace("d3btq01zzysv3d.cloudfront.net", "d2xrbrtg3kyq6g.cloudfront.net");

                        return {
                            'type': 'Feature',
                            'properties': {
                                'iconSize': [10, 10],
                                'creation_date': trick.date,
                                'image': mapThumbUrl,
                                'trick': trick,
                                'id': trick.id,
                            },
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [long, lat]
                            }
                        };
                    }
                    return null;
                }).filter(Boolean);
            }

            return resGeoJsonHolder;
        },
        enabled: isAuthenticated && !authLoading && isSubscribed !== undefined,
        ...getAdjustedTiming(QueryTiming.MEDIUM_FREQUENCY),
    });

    useEffect(() => {
        if (authInfo) {
            if (authInfo.userType === UserType.BRAND || authInfo.isUserGod || isSubscribed || authInfo.userTricksCount > 0) {
                setBlurredMap(false);
            } else {
                setBlurredMap(true);
            }
        } else if (isAuthenticated === false) {
            setBlurredMap(true);
        }
    }, [authInfo, isAuthenticated, isSubscribed]);

    useEffect(() => {
        if (query.has("lat") && query.has("long")) {
            const lat = query.get("lat")
            const long = query.get("long")
            if (lat && long) {
                setFlyTo(true);
                setFlyToCoords([parseFloat(long), parseFloat(lat)]);
            }
            // Clean up URL parameters
            query.delete('lat');
            query.delete('long');
            history.replace({
                pathname: '/map',
                search: query.toString()
            });
        } else if (flyTo) {
            // Only reset if we were previously flying
            setFlyTo(false);
            setFlyToCoords(undefined);
        }
    }, [location.search, flyTo]);

    useEffect(() => {
        if (isSubscribed !== undefined && isSubscribed !== state.isSubscribed) {
            refetchGeoJson();
        }
    }, [isSubscribed, state.isSubscribed, refetchGeoJson]);

    const onUploadTrickClick = () => {
        history.push('/upload')
    }

    const onGoToShop = () => {
        history.push('/shop')
    }

    const onTrickButtonClick = (trick_id: string, user_id: string) => {
        history.push('/clip/' + user_id + "/" + trick_id);
    }

    const reloadAuthInfo = () => {
        queryClient.invalidateQueries({ queryKey: ['authInfo'] });
    }

    const isMapLoading = authLoading || subscriptionLoading || isGeoJsonLoading;

    useEffect(() => {
        if (!isMapLoading) {
            window.prerenderReady = true;
        }
    }, [isMapLoading]);

    if (isMapLoading) {
        <IonPage>
            <div className="flex h-screen justify-center">
                <IonSpinnerMainContent className={"py-20"}
                    size="medium" />
            </div>
        </IonPage>
    }

    return (
        <IonPage>
            <CustomHelmet title={"Ecliptic // Map"}
                description={"Explore Ecliptic, Spots, and Skiers and Snowboarders Sending It Around The WorldWeb"}
                image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                url={`https://ecliptic.day/map`} />
            <IonHeader>
            </IonHeader>
            <IonContent fullscreen className="ion-padding-0">
                <WorldWeb lat={baseLat} long={baseLong} zoom={baseZoom} geojson={geoJson}
                    onClick={onTrickButtonClick} blurred={blurredMap} flyTo={flyTo}
                    flyToCoords={flyToCoords}
                    onBoundsChange={handleBoundsChange}
                    isNative={isNative} isSubscribed={isSubscribed} />
                {isAuthenticated && blurredMap &&
                    <div className="z-100 fixed top-1/4 left-1/2 transform -translate-x-1/2 w-11/12 max-w-2xl mx-auto" style={{ zIndex: 100 }}>
                        <IonCard className="mx-4">
                            <IonCardHeader className="px-6 py-4">
                                <IonCardTitle className="text-center text-xl">
                                    You must be subscribed to the backcountry or tow rope pass to view the map and view spots from around the world. Go to the shop to purchase! Upload for access too!
                                </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className="px-6 py-4">
                                <div className="flex flex-col gap-3 max-w-md mx-auto">
                                    <IonButton expand="block" size="large" color="favorite" onClick={onGoToShop}>
                                        Visit Shop
                                    </IonButton>
                                    <IonButton expand="block" size="large" color="secondary" onClick={reloadAuthInfo}>
                                        Reload
                                    </IonButton>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </div>
                }
                {!isAuthenticated && blurredMap &&
                    <div className="z-100 fixed top-1/4 left-1/2 transform -translate-x-1/2 w-11/12 max-w-2xl mx-auto" style={{ zIndex: 100 }}>
                        <IonCard className="mx-4">
                            <IonCardHeader className="px-6 py-4">
                                <IonCardTitle className="text-center text-xl">
                                    You must be subscribed to the backcountry or tow rope pass to view the map and view spots from around the world. Go to the shop to purchase! Upload for access too!
                                </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className="px-6 py-4">
                                <div className="flex flex-col gap-3 max-w-md mx-auto">
                                    <IonButton expand="block" size="large" color="favorite" onClick={onGoToShop}>
                                        Visit Shop
                                    </IonButton>
                                    <IonButton expand="block" size="large" color="secondary" onClick={reloadAuthInfo}>
                                        Reload
                                    </IonButton>
                                </div>
                            </IonCardContent>
                        </IonCard>
                    </div>
                }
            </IonContent>
        </IonPage>
    );
};

export default MapPage;