import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useState, useCallback } from 'react';
import { BiskTransactionView } from '../models/biskTransactionView';
import BiskService from '../services/bisk.service';
import { getDateNowUTCISO, getSlangTerm } from '../services/utils';
import { getAdjustedTiming, QueryTiming } from './useQueryConfig';

export const useBiskTransactions = () => {
    const [biskFilter, setBiskFilter] = useState<string>("");
    const numberOfBiskTransactions = 10;
    const queryClient = useQueryClient();

    const fetchBiskTransactions = async ({ pageParam = getDateNowUTCISO() }) => {
        let transactions: BiskTransactionView[];
        if (biskFilter !== "") {
            transactions = await BiskService.getBiskTransactionsByType(biskFilter, numberOfBiskTransactions, pageParam);
        } else {
            transactions = await BiskService.getBiskTransactionsAll(numberOfBiskTransactions, pageParam);
        }
        return transactions;
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        error,
        refetch
    } = useInfiniteQuery({
        queryKey: ['biskTransactions', biskFilter],
        queryFn: fetchBiskTransactions,
        getNextPageParam: (lastPage) => {
            if (lastPage.length < numberOfBiskTransactions) {
                return undefined; // No more pages
            }
            return lastPage[lastPage.length - 1].date;
        },
        initialPageParam: getDateNowUTCISO(),
        ...getAdjustedTiming(QueryTiming.MEDIUM_HIGH_FREQUENCY),
    });

    const biskTransactions = data?.pages.flatMap(page => page) || [];

    const getMoreBiskTransactions = useCallback(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    const resetBiskTransactions = useCallback(async () => {
        await queryClient.resetQueries({ queryKey: ['biskTransactions', biskFilter] });
        setBiskFilter("");
        await refetch();
    }, [refetch]);

    const handleBiskFilterChange = useCallback(async (newBiskFilter: string) => {
        console.log("Bisk Filter Changed: " + newBiskFilter);
        setBiskFilter(newBiskFilter);
        queryClient.resetQueries({ queryKey: ['biskTransactions', newBiskFilter] });
        await refetch();
    }, [queryClient]);

    return {
        biskTransactions,
        hasNextPage,
        biskTransactionsLoading: isFetching,
        biskFilter,
        getMoreBiskTransactions,
        resetBiskTransactions,
        handleBiskFilterChange,
        isFetchingNextPage,
        status,
        error
    };
};