import { Browser } from "@capacitor/browser";
import React from 'react';
import { Announcement } from "../../../models/announcements";
import { useAnalytics } from "../../../AppAnalyticsProvider";

interface RotatingBannerProps {
    initialTextArray: Announcement[];
    currentTextIndex: number;
    isLoadingAnnoucements: boolean;
    isError: boolean;
}

const RotatingBanner: React.FC<RotatingBannerProps> = ({ initialTextArray, currentTextIndex, isLoadingAnnoucements, isError }) => {
    const textLinkColors = ['text-green-300', 'text-blue-300', 'text-pink-300', 'text-yellow-300']
    const { trackEvent } = useAnalytics();

    const handleLinkClick = async (url: string, announcement: Announcement) => {
        console.log('Opening browser to', url);
        try {
            await trackEvent('announcement_click', {
                announcement_id: announcement.id,
                announcement_text: announcement.text,
                announcement_url: url,
                position: currentTextIndex + 1,
                total_announcements: initialTextArray.length
            });

            await Browser.open({
                url,
                presentationStyle: 'popover'
            });
        } catch (error) {
            console.error('Error opening the browser', error);
        }
    };

    if (isError) {
        return null;
    }

    if (isLoadingAnnoucements) {
        return (
            <div className='h-6 bg-neutral-900 rounded-b-lg'>
                <div className='text-center text-white bg-neutral-900 rounded-b-lg py-2 font-bold'>
                    Ecliptic Annoucements
                </div>
            </div>
        );
    }

    return (
        <div className='h-6 bg-neutral-900 rounded-b-lg'>
            {initialTextArray.length > 0 ? (
                <div className="relative">
                    {initialTextArray.map((text, index) => (
                        <div
                            key={index}
                            className={`text-center font-bold text-white bg-neutral-900 rounded-b-lg py-2 absolute top-0 left-0 w-full transition-opacity ${index === currentTextIndex ? 'opacity-100' : 'opacity-0'
                                }`}
                        >
                            {initialTextArray[currentTextIndex].link !== null ? (
                                <a
                                    className={`cursor-pointer ${textLinkColors[index % textLinkColors.length]}`}
                                    onClick={() => handleLinkClick(initialTextArray[currentTextIndex].link, initialTextArray[currentTextIndex])}
                                >
                                    {initialTextArray[currentTextIndex].text}
                                </a>
                            ) : (
                                <span>{initialTextArray[currentTextIndex].text}</span>
                            )}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default RotatingBanner;