import { Preferences } from "@capacitor/preferences";
import axios from "axios";
import UserService from "./user.service";
import { SubscriptionType } from "../models/subscriptionType";
import { pl } from "date-fns/locale";
import { UserStreak } from "../models/userstreak";

const BiskService = {
    getBiskForUser: async (userId: string) => {
        const randomParams = {
            user_id: userId,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/bisk/user`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    },

    sendBiskToUserForObject: async (userId: string, userRecipientId: string, biskAmount: number, objectId: string, typeOfObject: string, note: string) => {
        const biskSendParams = {
            origin_id: userId,
            target_id: userRecipientId,
            amount: biskAmount,
            object_id: objectId,
            object_type: typeOfObject,
            notes: note
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/bisk/user/send`, null, { params: biskSendParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    },

    // note: page number starts from 0
    sendBiskToUser: async (userId: string, userRecipientId: string, biskAmount: number, note: string) => {
        const randomParams = {
            origin_id: userId,
            target_id: userRecipientId,
            amount: biskAmount,
            notes: note
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/bisk/user/send`, null, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    },

    withdrawBiskFromUser: async (userId: string, biskAmount: number, platform: string, platformId: string) => {
        const randomParams = {
            user_id: userId,
            amount: biskAmount,
            platform: platform,
            platform_id: platformId
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/bisk/user/withdraw`, null, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    },

    sendBiskWithdrawalAlert: async (name: string, username: string, email: string, amount: number,
        platform: string, platformId: string, transactionId: string) => {

        const params = {
            name: name,
            username: username,
            email: email,
            amount: amount,
            platform: platform,
            platform_id: platformId,
            transaction_id: transactionId
        };

        return await axios.post(`${process.env.REACT_APP_DISCORD_BOT_SERVER}/bisk/withdraw`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null
            });
    },

    redeemBiskForUserForObject: async (userId: string, userRecipientId: string, biskAmount: number, objectId: string, typeOfObject: string, note: string) => {
        const biskSendParams = {
            origin_id: userId,
            target_id: userRecipientId,
            amount: biskAmount,
            object_id: objectId,
            object_type: typeOfObject,
            notes: note
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/bisk/user/redeem`, null, { params: biskSendParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    },

    redeemBiskForUser: async (userId: string, userRecipientId: string, biskAmount: number, note: string) => {
        const randomParams = {
            origin_id: userId,
            target_id: userRecipientId,
            amount: biskAmount,
            notes: note
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/bisk/user/redeem`, null, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    },

    sendBiskRedeemObjectAlert: async (name: string, username: string, email: string, amount: number,
        notes: string, product: string, productId: string,
        recipientUsername: string, recipientEmail: string,
        transactionId: string) => {

        const params = {
            name: name,
            username: username,
            email: email,
            amount: amount,
            notes: notes,
            product_name: product,
            product_id: productId,
            recipient_username: recipientUsername,
            recipient_email: recipientEmail,
            transaction_id: transactionId
        };

        return await axios.post(`${process.env.REACT_APP_DISCORD_BOT_SERVER}/bisk/redeem/object`, null, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null
            });
    },

    getBiskTransactionsAll: async (numberOfTransactions: number, date: string) => {
        const randomParams = {
            number_of_transactions: numberOfTransactions,
            date: date
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/bisk/transactions`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getBiskTransactionsByType: async (transactionType: string, numberOfTransactions: number, date: string) => {
        const randomParams = {
            transaction_type: transactionType,
            number_of_transactions: numberOfTransactions,
            date: date
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/bisk/transactions`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    },

    getUserBiskActivity: async (userId: string, numberOfTransactions: number, date: string) => {
        const randomParams = {
            user_id: userId,
            number_transactions: numberOfTransactions,
            date: date
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/bisk/user/transactions`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    },

    getUserBiskActivityByType: async (userId: string, numberOfTransactions: number, date: string, transactionType: string) => {
        const randomParams = {
            user_id: userId,
            number_transactions: numberOfTransactions,
            date: date,
            transaction_type: transactionType,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/bisk/user/transactions`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    },

    getShopItemById: async (shopItemId: string) => {
        const randomParams = {
            product_id: shopItemId,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/store/product`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                throw error;
            });
    },

    getIsSubscribedById: async (userId: string) => {
        const randomParams = {
            user_id: userId,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/subscription`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return false;
            });
    },

    getIsSubscribedByUsername: async (username: string) => {
        const randomParams = {
            username: username,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/subscription`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return false;
            });
    },

    getSubscriptionLevelById: async (userId: string) => {
        const randomParams = {
            user_id: userId,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/subscription/level`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return SubscriptionType.None;
            });
    },

    getSubscriptionLevelByUsername: async (username: string) => {
        const randomParams = {
            username: username,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/subscription/level`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return SubscriptionType.None;
            });
    },

    getBoards: async () => {
        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/boards`)
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getBoardResult: async (boardName: string, period: string, sort: string, limit: number, page: number) => {
        const randomParams = {
            board_name: boardName,
            period: period,
            sort: sort,
            limit: limit,
            page: page
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/board/result`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getStoreProducts: async (active: boolean, platform: string, userId: string, type: string) => {
        let storeProductParams: any = {}

        if (active !== null && active !== undefined) {
            storeProductParams['active'] = active
        }

        if (platform !== null && platform !== undefined) {
            if (platform === "ios") {
                storeProductParams['ios'] = true
                storeProductParams['android'] = false
                storeProductParams['web'] = false
            }

            if (platform === "android") {
                storeProductParams['ios'] = false
                storeProductParams['android'] = true
                storeProductParams['web'] = false
            }

            if (platform === "web") {
                storeProductParams['ios'] = false
                storeProductParams['android'] = false
                storeProductParams['web'] = true
            }
        }

        if (userId !== null && userId !== undefined) {
            storeProductParams['user_id'] = userId
        }

        if (type !== null && type !== undefined) {
            storeProductParams['product_type'] = type
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/store/products`, { params: storeProductParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getUserStreak: async (userId: string): Promise<UserStreak | null> => {
        const params = {
            infd_id: userId
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/streak`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },
}

export default BiskService;
