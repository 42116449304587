import {OverlayEventDetail} from "@ionic/core/components";
import {useIonModal, useIonToast} from "@ionic/react";
import {alertCircleOutline} from "ionicons/icons";
import React, {useEffect} from "react";
import IDXChip from "../../ComponentsUI/IDXChip/IDXChip";
import useAuthService from "../../../hooks/useAuthService";
import CommentModal from "../CommentModal/CommentModal";
import { useAnalytics } from '../../../AppAnalyticsProvider';

interface ContainerProps {
    trickId: string
    isAuthed: boolean,
    userType: string,
    publicId: string,
    onClickResult: (username: string) => void,
    count: number
}

const TrickCommentCount: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [trickCommentCount, setTricksCommentCount] = React.useState<number>(0);

    const {login} = useAuthService();
    const [present] = useIonToast();
    const { trackEvent } = useAnalytics();

    useEffect(() => {
    }, [props.isAuthed, props.trickId]);

    const [presentComments, dismissComments] = useIonModal(CommentModal, {
        onDismiss: (data: string, role: string) => dismissComments(data, role),
        trickId: props.trickId,
        isAuthed: props.isAuthed,
        userType: props.userType,
        publicId: props.publicId,
        onClickResult: props.onClickResult,
        setTrickCommentsCount: setTricksCommentCount
    })

    useEffect(() => {
        if (props.count !== null && props.count !== undefined) {
            setTricksCommentCount(props.count);
        }
    }, [props.count]);

    const presentToastLoginHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            position: 'top',
            icon: alertCircleOutline,
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    const openTrickCommentModal = async () => {
        if (props.isAuthed) {
            trackEvent('trick_comments_open', {
                trick_id: props.trickId,
                user_id: props.publicId,
                comments_count: trickCommentCount
            });
            
            presentComments({
                onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                    trackEvent('trick_comments_close', {
                        trick_id: props.trickId,
                        user_id: props.publicId
                    });
                },
            });
        } else {
            trackEvent('trick_comments_auth_prompt', {
                trick_id: props.trickId
            });
            await presentToastLoginHandler("You must be logged in to view comments!")
        }
    }

    return (
        <>
            <div>
                <IDXChip bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                         onClickChip={(tag) => openTrickCommentModal()}
                         borderColor="font-bold border-2 border-primary-secondary" text={trickCommentCount}/>
            </div>
        </>
    );
};

export default TrickCommentCount;
