import { IonImg } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { BrandEvent } from "../../../models/brandEvent";
import IDXChip from "../../ComponentsUI/IDXChip/IDXChip";
import CountdownTimer from "../CountdownTimer/CountdownTimer";

interface ContainerProps {
    brandEvent: BrandEvent;
}

const EventList: React.FC<ContainerProps> = (props: ContainerProps) => {
    const history = useHistory();

    const onClickCard = (event_id: string) => {
        history.push('/event/' + event_id);
    }

    return (
        <>
            <div className="flex flex-col gap-y-0.5 py-4">
                <div key={props.brandEvent.id} className="flex flex-row mx-4 items-center justify-between gap-x-2"
                    onClick={() => onClickCard(props.brandEvent.id)}>
                    <div className="basis-1/4">
                        <IonImg
                            src={props.brandEvent.cover_pic ? props.brandEvent.cover_pic : "https://ionicframework.com/docs/img/demos/card-media.png"}
                            alt={props.brandEvent.cover_pic ? `${props.brandEvent.name}` : "Silhouette of mountains"}
                            className="w-32 h-32 rounded-lg" />
                    </div>
                    <div className="flex flex-col items-center justify-center basis-3/4 gap-y-2">
                        <div className="font-bold text-center text-xl text-wrap">
                            {props.brandEvent.name}
                        </div>
                        <div className="flex flex-row items-center justify-center gap-x-2">
                            {new Date(new Date().toDateString()).toISOString() >= props.brandEvent.start_date && new Date(new Date().toDateString()).toISOString() <= props.brandEvent.end_date ?
                                <>
                                    <IDXChip
                                        bgColor="bg-highlight-secondary/50 hover:bg-highlight-secondary/95 click:bg-highlight-secondary/95"
                                        borderColor="border-2 border-highlight-secondary" text={"Live"} />
                                    <CountdownTimer endDate={props.brandEvent.end_date} />
                                </>
                                : <IDXChip
                                    bgColor="bg-highlight-danger/50 hover:bg-highlight-danger/95 click:bg-highlight-danger/95"
                                    borderColor="border-2 border-highlight-danger" text={"Closed"} />}
                        </div>
                        <div className="flex flex-row items-center justify-center gap-x-2">
                            {props.brandEvent.sports?.map((tag, index) => (
                                <IDXChip key={index} bgColor="hover:bg-highlight-alt/95 click:bg-highlight-alt/95"
                                    borderColor="border-2 border-highlight-alt" text={tag === "Skiing" ? "⛷️" : tag === "Snowboarding" ? "🏂" : tag}
                                />))}
                            {props.brandEvent.features.length <= 1 && props.brandEvent.features?.map((tag, index) => (
                                <IDXChip key={index} bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                    borderColor="border-2 border-highlight"
                                    text={tag} />))}
                            {props.brandEvent.event_tricks?.map((trick, index) => (
                                <IDXChip key={index} text={trick.join(" ")}
                                    bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                    borderColor="border-2 border-highlight" />))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default EventList;