import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { IonAvatar, IonIcon, IonImg, IonSpinner, useIonToast } from "@ionic/react";
import UserService from "../../../services/user.service";
import { User } from "../../../models/user";
import { motion } from 'framer-motion';
import biskLogo from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import biskSvg from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import UsernameDisplay from "../../ComponentsUI/Username/UsernameDisplay";
import { AppContext } from "../../../AppStateProvider";
import { formatAmount, isUUID } from "../../../services/utils";
import useAuthService from "../../../hooks/useAuthService";
import BoardUserBiskCardSkeleton from "../BoardUserBiskCardSkeleton/BoardUserBiskCardSkeleton";
import { BoardEventResult } from "../../../models/eventBoardResults";
import { BoardType } from "../../../models/boardType";
import { useAnalytics } from "../../../AppAnalyticsProvider";


interface ContainerProps {
    result: BoardEventResult;
    blurred?: boolean;
    isAuthed?: boolean;
    type: BoardType;
}

const EventBoardUserBiskCard: React.FC<ContainerProps> = ({ result, blurred, isAuthed, type }) => {
    const history = useHistory();
    const { trackEvent } = useAnalytics();
    const { state } = useContext(AppContext);
    const { login } = useAuthService();

    const [originUsername, setOriginUsername] = useState<string>('');
    const [originProfilePic, setOriginProfilePic] = useState<string>('/assets/photos/defaultProPic.png');
    const [amount, setTransactionAmount] = useState<number>(0);
    const [error, setError] = useState<boolean>(false);

    const [blurredClass, setBlurredClass] = useState<string>('blur-lg');
    const [present] = useIonToast();

    const getUserInfoOrigin = async (userId: string) => {
        try {
            let user: User = undefined;

            if (isUUID(userId)) {
                user = await UserService.getUserPublicById(userId);
            } else {
                user = await UserService.getUserByUsername(userId);
            }

            if (user.username === undefined) {
                setError(true);
                return;
            }
            setOriginUsername(user.username);
            if (user.profile.profile_pic !== null && user.profile.profile_pic !== undefined && user.profile.profile_pic !== '') {
                setOriginProfilePic(user.profile.profile_pic);
            }
        } catch (error) {
            console.log("Error: " + error);
            setError(true);
        }
    }

    useEffect(() => {
        // Function to reset state
        const resetState = () => {
            setOriginUsername('');
            setOriginProfilePic('/assets/photos/defaultProPic.png');
            setTransactionAmount(0);
        };

        // Reset the state
        resetState();

        if (result) {
            if (type === BoardType.USER_INFO) {
                setTransactionAmount(result.event_board_score);
            } else if (type === BoardType.USER_RANK) {
                setTransactionAmount(result.event_board_rank);
            } else {
                setTransactionAmount(result.event_board_value);
            }
            getUserInfoOrigin(result.user_id);
        }
    }, [result, result.event_board_value, result.user_id]);

    useEffect(() => {
        if (blurred) {
            setBlurredClass('blur-lg');
        } else {
            setBlurredClass('');
        }
    }, [blurred]);

    const presentToastLoginHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            position: 'top',
            icon: biskSvg,
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    const onClickUsername = async (username: string) => {
        trackEvent('board_user_profile_click', {
            clicked_user_id: result.user_id,
            clicked_username: username,
            board_type: type,
            is_authed: isAuthed,
            score: type === BoardType.USER_INFO ? result.event_board_score : result.event_board_rank
        });

        if (!isAuthed) {
            await presentToastLoginHandler("You must be logged in to view profiles!");
            return;
        }
        history.push('/profile/' + username);
    };

    return (
        originUsername !== '' ?
            <div className={"flex flex-col border-b border-gray-900 " + blurredClass}>
                <div className="flex items-center justify-between px-4 py-3">
                    <div className="flex items-center space-x-3 cursor-pointer">
                        <IonAvatar slot="start" className="mx-0.5"
                            onClick={() => onClickUsername(originUsername)}>
                            <IonImg className="shrink w-12 h-12 flex-none rounded-full" src={originProfilePic}></IonImg>
                        </IonAvatar>
                        <div>
                            <UsernameDisplay className="font-semibold" username={result.username} userId={result.user_id}
                                onUsernameClickHandler={onClickUsername} loggedInId={state.user.id} />
                        </div>
                    </div>
                    <div className="text-xl font-bold">
                        {amount}
                    </div>
                </div>
            </div> :
            <div className={"flex flex-col border-b border-gray-900 " + blurredClass}>
                <BoardUserBiskCardSkeleton />
            </div>
    )
};

export default EventBoardUserBiskCard;