import { IonButton, IonHeader, IonIcon, IonToolbar, useIonToast } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import React from "react";
import { useHistory, useLocation } from "react-router";
import IDXButtonIcon from "../IDXButtonIcon/IDXButtonIcon";
import IDXLogo from "../../../assets/icons/system/ECLIPTIC_NEW_BLACK_PNG_NO_PADDING_BETA.svg";
import EventShare from "../../ComponentsShare/EventShare/EventShare";
import { BrandEvent } from "../../../models/brandEvent";

interface ContainerProps {
    event: BrandEvent;
}

const ToolbarEventBar: React.FC<ContainerProps> = ({ event }) => {
    const history = useHistory();
    const location = useLocation();

    return (
        <>
            <IonHeader className="bg-grey">
                <IonToolbar>
                    <div className="flex flex-row justify-between items-center mx-2">
                        <div className="flex order-first gap-x-4">
                            <div>
                                <IonButton fill="clear" size="default" onClick={
                                    () => {
                                        if (location.key) {
                                            history.go(-1);
                                        } else {
                                            history.push('/community')
                                        }
                                    }}>
                                    <IDXButtonIcon size="text-xl" icon={arrowBack} color="theme-alternative" />
                                </IonButton>
                            </div>
                        </div>
                        <div className="flex items-center font-bold justify-center"> {/* Adjust h-12 as needed */}
                            Event
                        </div>
                        <div className="flex order-last gap-x-4">
                            <div>
                                <EventShare event={event} />
                            </div>
                        </div>
                    </div>
                </IonToolbar>
            </IonHeader>
        </>
    );
};

export default ToolbarEventBar;
