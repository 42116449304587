import React, { useEffect, useState } from 'react';
import IDXChip from '../../ComponentsUI/IDXChip/IDXChip';
import { timerOutline } from 'ionicons/icons';

interface CountdownTimerProps {
    endDate: string;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ endDate }) => {
    const [timeLeft, setTimeLeft] = useState<string>("");

    useEffect(() => {
        const calculateTimeLeft = () => {
            const difference = new Date(endDate).getTime() - new Date().getTime();

            if (difference <= 0) {
                setTimeLeft("Ended");
                return;
            }

            const days = Math.ceil(difference / (1000 * 60 * 60 * 24));
            setTimeLeft(`${days} day${days > 1 ? "s" : ""}`);
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 3600000); // Update every hour

        return () => clearInterval(timer);
    }, [endDate]);

    return (
        <IDXChip
            bgColor="bg-highlight/50 hover:bg-highlight/95"
            borderColor="border-2 border-highlight"
            text={`${timeLeft}`}
            icon={timerOutline}
        />
    );
};

export default CountdownTimer;