import React, { useEffect, useState } from "react";
import { BoardEventInfoResult } from "../../../models/eventBoardInfoResult";

interface ContainerProps {
    result: BoardEventInfoResult;
    blurred?: boolean;
}

const EventBoardIDXInfoCard: React.FC<ContainerProps> = ({ result, blurred }) => {
    const [blurredClass, setBlurredClass] = useState<string>('blur-lg');

    useEffect(() => {
        if (blurred) {
            setBlurredClass('blur-lg');
        } else {
            setBlurredClass('');
        }
    }, [blurred]);

    return (
        <div className={"flex flex-col border-b border-gray-900 " + blurredClass}>
            <div className="flex items-center justify-between px-4 py-3">
                <div className="font-bold text-xl">
                    {result.event_board_info_name}
                </div>
                <div className="font-bold text-xl">
                    {result.event_board_info_value}
                </div>
            </div>
        </div>
    )
};

export default EventBoardIDXInfoCard;