import React from 'react';
import { IonContent, IonImg, IonPage } from '@ionic/react';

const LoadingSplash: React.FC = () => {
    const gradientStyle = {
        background: 'radial-gradient(circle at center, #1a1a1a 0%, #0D0D0D 50%, #000000 100%)',
    };

    // BASICALLY HOPELESS TO FIX - Tried to get it to match the splash screen as best as I could


    return (
        <IonPage>
            <IonContent>
                <div className="flex flex-col items-center justify-center h-full" style={gradientStyle}>
                    <div
                        className="mb-18 w-52 sm:w-52 md:w-60 lg:w-80 pl-1 sm:pl-1 md:pl-3 lg:pl-7 animate-pulse"> {/* Adjusted widths */}
                        <IonImg
                            src="/assets/logo/ECLIPTIC_NEW_WHITE_PNG_NO_PADDING.png"
                            className="w-full h-auto max-w-[300px]"
                        />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default LoadingSplash;