import { OverlayEventDetail } from "@ionic/core/components";
import { IonAvatar, IonButton, IonContent, IonItem, IonLabel, IonPage, IonSpinner, useIonModal } from "@ionic/react";
import { removeCircleOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Brand } from "../../../models/brand";
import { User } from "../../../models/user";
import { UserProfileSearch } from "../../../models/user-profile-search";
import { UserType } from "../../../models/usertype";
import UserService from "../../../services/user.service";
import ProfileSearch from "../../ComponentsProfile/ProfileSearch/ProfileSearch";
import StandardSearchBar from "../../ComponentsSearch/StandardSearchBar/StandardSearchBar";
import IDXButtonIcon from "../../ComponentsUI/IDXButtonIcon/IDXButtonIcon";
import IDXModalHeader from "../../ComponentsUI/IDXModalHeader/IDXModalHeader";
import { useHistory } from "react-router";
import { set } from "lodash";
import SearchProfilesLoading from "../../ComponentsUI/SearchProfilesLoading/SearchProfilesLoading";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    userProfile: Brand,
    editBrand: boolean,
    onClickSubmitTeamRiders: (user_ids: string[]) => void,
}

//change ProfileSocial to obtain all the profiles from the user_id that is stored in the following array, which is stored in the userProfileFollowing.user_interactions.following
//call UserServices.getUserProfile() to get the user profile in ProfileFollowing useEffect
const CrewRiders: React.FC<ContainerProps> = ({
    onDismiss,
    userProfile,
    editBrand,
    onClickSubmitTeamRiders
}) => {
    const history = useHistory();

    //create a const where I can store the user profiles that are stored in the following array
    const [crewRiders, setCrewRiders] = React.useState<User[]>([]);
    const [potentialCrewRiders, setPotentialCrewRiders] = React.useState<any>([]);

    const [userSearch, setUserSearch] = useState(undefined);
    const [profileSearch, setProfileSearch] = useState(undefined);

    const [loading, setLoading] = React.useState(false);

    const getProfiles = async (profiles: string[]) => {
        setLoading(true);
        const usersInteractionProfiles = await UserService.getUserInteractionProfiles(profiles, [UserType.USER]);
        setCrewRiders(usersInteractionProfiles[UserType.USER]);
        setPotentialCrewRiders(usersInteractionProfiles[UserType.USER]);

        setLoading(false);
    }

    // search
    const onClickPerson = async (user: UserProfileSearch) => {
        potentialCrewRiders.push(user);
    }

    function openProfileSearchModal() {
        present({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onProfileSearchSubmit = async (search: string) => {
        // Open Modal
        setProfileSearch(search);
        openProfileSearchModal();
    };

    const [present, dismiss] = useIonModal(ProfileSearch, {
        onDismiss: (data: string, role: string) => dismiss(data, role),
        profileSearchValue: profileSearch,
        onClickResult: onClickPerson,
    });

    useEffect(() => {
        if (userProfile) {
            if (userProfile.user_interactions) {
                if (userProfile.user_interactions.members) {
                    getProfiles(userProfile.user_interactions.members);
                }
            }
        }
    }, [userProfile, editBrand]);

    const onClickPersonFollow = (username: string) => {
        onDismiss();
        setTimeout(() => {
            onClickPersonUserInteractions(username, UserType.USER);
        }, 100);
    }

    const removeFromPotentialCrewRiders = (profile: User | UserProfileSearch) => {
        potentialCrewRiders.forEach((item: User | UserProfileSearch, index: number) => {
            if (item.id === profile.id) potentialCrewRiders.splice(index, 1);
        });
        setPotentialCrewRiders([...potentialCrewRiders]);
    }

    const onClickPersonUserInteractions = async (usernameCrew: string, userType: string) => {
        if (userType === UserType.USER) {
            history.push(`/profile/${usernameCrew}`);
            return;
        } else if (userType === UserType.BRAND) {
            history.push(`/crews/${usernameCrew}`);
            return;
        } else {
            history.push(`/profile/${usernameCrew}`);
            return;
        }
    }

    const onClickSubmitTeamRidersModal = () => {
        const user_ids = potentialCrewRiders.map((profile: User | UserProfileSearch) => profile.id);
        onClickSubmitTeamRiders(user_ids);
        onDismiss();
    }

    //return the new array of user profiles
    return (!editBrand ?
        <IonPage>
            <IDXModalHeader leftButtonAction={() => onDismiss(null, 'cancel')}
                titleText={userProfile.name + " Riders"} rightButtonText={'Close'}
                rightButtonAction={() => onDismiss(null, 'cancel')} />
            <IonContent>
                {loading ?
                    <SearchProfilesLoading></SearchProfilesLoading> :
                    crewRiders && crewRiders.length > 0 ?
                        crewRiders.map((profile, index) => (
                            <IonItem className="cursor-pointer" key={index}
                                onClick={() => onClickPersonFollow(profile.username)}>
                                {/* <IonAvatar slot="start">
                        <img src={profile.profile_pic}/>
                    </IonAvatar> */}
                                <IonLabel>
                                    <div className="text-xl">{profile.username}</div>
                                    <div>{profile.name ? profile.name : ""}</div>
                                </IonLabel>
                                {/* add profile IonAvatar */}
                                <IonAvatar slot="start" className="ml-2">
                                    <img className="shrink w-8 h-8 rounded-full"
                                        src={profile?.profile ? profile?.profile?.profile_pic ? profile.profile.profile_pic : '/assets/photos/defaultProPic.png' : '/assets/photos/defaultProPic.png'}></img>
                                </IonAvatar>

                            </IonItem>
                        )) :
                        <div className="flex flex-col mt-4 text-center">
                            <div>It appears that {userProfile.name} has no riders yet. Check back to when the squad
                                is assembled!
                            </div>
                        </div>
                }
            </IonContent>
        </IonPage> :
        <IonPage>
            <IDXModalHeader leftButtonText={'Cancel'} leftButtonAction={() => onDismiss(null, 'cancel')}
                titleText={userProfile.name + " Riders"} rightButtonText={'Submit'}
                rightButtonAction={() => onClickSubmitTeamRidersModal()} />
            <IonContent>
                {loading ?
                    <IonItem className="items-center space-x-4">
                        <div>Loading</div>
                        <IonSpinner name="dots" color="favorite"></IonSpinner>
                    </IonItem> :
                    <>
                        <StandardSearchBar placeholder='Search for people'
                            webPlaceholder='Search for people and accounts' onSearch={setUserSearch}
                            onSubmit={onProfileSearchSubmit}
                            searchPlaceholder={userSearch}></StandardSearchBar>
                        {potentialCrewRiders.map((profile: User | UserProfileSearch, index: number) => (
                            <IonItem className="cursor-pointer" key={index}>
                                {/* <IonAvatar slot="start">
            <img src={profile.profile_pic}/>
        </IonAvatar> */}
                                <IonLabel>
                                    <div className="text-xl">{profile.username}</div>
                                    <div>{profile.name ? profile.name : ""}</div>
                                </IonLabel>
                                {/* add profile IonAvatar */}
                                <IonButton className="ml-2" onClick={() => removeFromPotentialCrewRiders(profile)}
                                    color="danger">
                                    <IDXButtonIcon size="text-2xl" icon={removeCircleOutline} />
                                </IonButton>
                            </IonItem>
                        ))}
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default CrewRiders;