import {
    IonCheckbox,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonPage
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ClipType } from "../../../models/clipType";
import { LocationInput } from "../../../models/locationInput";
import { SelectOptions } from "../../../models/profileSelectOptions";
import { UserTrick } from "../../../models/user-trick";
import LocationService from "../../../services/location.service";
import { FOLLOWERS_ONLY, UNLISTED_CONST } from "../../../services/utils";
import { AppContext } from "../../../AppStateProvider";
import Dropdown from "../../ComponentsUI/Dropdown/Dropdown";
import EditTrickSelect from "../EditTrickSelect/EditTrickSelect";
import IonInputWithLabel from "../../ComponentsUI/IonInputWithLabel/IonInputWithLabel";
import IonLongInputWithLabel from "../../ComponentsUI/IonLongInputWithLabel/IonLongInputWithLabel";
import TagSelectOnUpload from "../../ComponentsUI/TagSelectOnUpload/TagSelectOnUpload";
import { LocationTrick } from "../../../models/locationTrick";
import IDXModalHeader from "../../ComponentsUI/IDXModalHeader/IDXModalHeader";
import { UserProfileSearch } from "../../../models/user-profile-search";
import { FilmerInfo } from "../../../models/filmerInto";
import { film } from "ionicons/icons";
import SearchService from "../../../services/search.service";
import UserService from "../../../services/user.service";
import { useQuery } from "@tanstack/react-query";
import { getAdjustedTiming, QueryTiming } from "../../../hooks/useQueryConfig";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    userTrick: UserTrick,
    onEditTrickSubmit: (userTrick: UserTrick, auth_id: string, location_details: LocationInput, filmerInfo: FilmerInfo) => void
}


interface TrickAttribute {
    name: string,
    lowercase: string,
    transitions: string,
}

const sportOptions: SelectOptions[] = [
    {
        id: 1,
        name: 'Skiing',
    },
    {
        id: 2,
        name: 'Snowboarding',
    },
    {
        id: 3,
        name: 'Other',
    }
];

const features: SelectOptions[] = [
    {
        id: 1,
        name: 'Jump',
    },
    {
        id: 2,
        name: 'Rail/Jib',
    },
    {
        id: 3,
        name: 'Halfpipe',
    },
    {
        id: 3,
        name: 'Freeride',
    },
    {
        id: 4,
        name: 'Off Mountain',
    }
];

const otherFeatures: SelectOptions[] = [];

const clipOptions: SelectOptions[] = [
    {
        id: 1,
        name: ClipType.CLIP,
    },
    {
        id: 2,
        name: ClipType.LINE,
    },
    {
        id: 3,
        name: ClipType.EDIT,
    }
];

const EditTrick: React.FC<ContainerProps> = ({ onDismiss, userTrick, onEditTrickSubmit }) => {
    const [editedTrick, setEditedTrick] = React.useState(userTrick);
    const { dispatch, state } = useContext(AppContext);

    const [trickName, setTrickName] = React.useState(userTrick.trick_name);
    const [location, setLocation] = React.useState(userTrick.location);
    const [sport, setSport] = React.useState(userTrick.sport);
    const [feature, setFeature] = React.useState(userTrick.terrain);
    const [dropdown, setShowDropdown] = React.useState(false);
    const [filmDate, setFilmDate] = React.useState(userTrick.date); // Cannot edit date for some reason
    const [tags, setTags] = React.useState(userTrick.tags);
    const [caption, setCaption] = React.useState(userTrick.description);

    const [unlisted, setUnlisted] = React.useState<boolean>(false);
    const [clipType, setClipType] = React.useState<string>(ClipType.CLIP);

    const [locationInput, setLocationInput] = useState<LocationInput>(null);
    const [locationDropdownOptions, setLocationDropdownOptions] = useState<LocationInput[]>([]);
    const [locationDropdown, setLocationDropdown] = useState(false);
    const [googlePlacesSessionToken, setGooglePlacesSessionToken] = useState(null);

    const [filmerInfo, setFilmerInfo] = useState<FilmerInfo | null>(null);
    const [filmerSearchText, setFilmerSearchText] = useState("");
    const [filmerDropdownOptions, setFilmerDropdownOptions] = useState<UserProfileSearch[]>([]);
    const [filmerDropdown, setFilmerDropdown] = useState(false);

    const { data: taggedUser, isLoading: taggedUserLoading } = useQuery({
        queryKey: ['taggedUsers', userTrick?.trick_id],
        queryFn: () => UserService.getUserTaggedForVideo(userTrick?.trick_id),
        enabled: !!userTrick?.trick_id,
        ...getAdjustedTiming(QueryTiming.LOW_FREQUENCY),
    });

    // Location Dropdown
    const closeDropdown = useRef(null);
    // Filmer Dropdown
    const closeFilmerDropdown = useRef(null);

    const closeOpenMenus = (e: any) => {
        // Handle filmer dropdown
        if (filmerDropdown && !closeFilmerDropdown.current?.contains(e.target)) {
            const filmerContainer = closeFilmerDropdown.current?.closest('.filmer-container');
            if (!filmerContainer?.contains(e.target)) {
                setFilmerDropdown(false);
                // Reset search text if no selection was made during this search
                if (filmerSearchText !== filmerInfo?.username) {
                    setFilmerSearchText(filmerInfo?.username || "");
                }
            }
        }

        if (locationDropdown && !closeDropdown.current?.contains(e.target)) {
            const locationContainer = closeDropdown.current?.closest('.location-container');
            if (!locationContainer?.contains(e.target)) {
                setLocationDropdown(false);
            }
        }

        // Handle Enter key
        if (e.key === 'Enter') {
            if (filmerDropdown) {
                setFilmerDropdown(false);
                // Reset search text if no selection was made during this search
                if (filmerSearchText !== filmerInfo?.username) {
                    setFilmerSearchText(filmerInfo?.username || "");
                }
            }
            if (locationDropdown) {
                setLocationDropdown(false);
            }
        }
    }

    const setTrickLocation = async (locationVal: string, ignoreSameLocation: boolean = false) => {
        if (locationVal === location && !ignoreSameLocation) {
            setLocationDropdown(false);
            return;
        }

        let currGooglePlacesToken = googlePlacesSessionToken;

        if (locationVal === '' || locationVal === null) {
            currGooglePlacesToken = uuidv4();
            setGooglePlacesSessionToken(currGooglePlacesToken);
        }

        setLocation(locationVal);
        await queryTrickLocation(locationVal, currGooglePlacesToken);
    }

    const queryFilmer = async (filmerQuery: string) => {
        const res = await SearchService.searchUserProfiles(filmerQuery, 5, 0, userTrick?.user_id);
        const filmerOptions: UserProfileSearch[] = res?.map((profile: any) => ({
            username: profile.username,
            user_id: profile.id,
            profile_picture_url: profile.profile_pic
        })) || [];

        setFilmerDropdownOptions(filmerOptions);
        if (filmerOptions.length > 0) {
            setFilmerDropdown(true);
        }
    };

    const handleFilmerSearch = async (searchText: string) => {
        setFilmerSearchText(searchText);
        await queryFilmer(searchText);
    };

    const addFilmerItem = (selectedFilmer: any) => {
        setFilmerInfo({
            username: selectedFilmer.username,
            user_id: selectedFilmer.user_id
        });
        setFilmerSearchText(selectedFilmer.username);
        setFilmerDropdown(false);
    };

    const queryTrickLocation = async (location: string, currGooglePlacesToken: string) => {
        const res = await LocationService.getLocationFromPlacesAPI(location, currGooglePlacesToken);
        const locationDropdownOptions: LocationInput[] = res.map((location: any) => {
            const locationInput: LocationInput = {
                description: location.description,
                main_text: location.structured_formatting.main_text,
                place_id: location.place_id,
            }
            return locationInput;
        });
        setLocationDropdownOptions(locationDropdownOptions);
        if (locationDropdownOptions.length > 0) {
            setLocationDropdown(true);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", closeOpenMenus);
        document.addEventListener('keypress', closeOpenMenus);

        return () => {
            document.removeEventListener("mousedown", closeOpenMenus);
            document.removeEventListener('keypress', closeOpenMenus);
        }
    }, [locationDropdown, filmerDropdown, filmerInfo]); // Add all dependencies

    useEffect(() => {
        const setInitialLocationDetails = async () => {
            if (userTrick?.location && userTrick?.location !== '' && userTrick?.trick_id) {
                const locationForTrick: LocationTrick = await LocationService.getTrickLocation(userTrick.trick_id)
                if (locationForTrick) {
                    setLocationInput({
                        description: locationForTrick.description,
                        main_text: locationForTrick.main_text,
                        place_id: locationForTrick.place_id,
                    });
                }
            }
        }

        console.log("rendering edit trick");
        setEditedTrick(editedTrick);
        setTrickName(userTrick?.trick_name);
        setLocation(userTrick?.location);
        setSport(userTrick?.sport);
        setFeature(userTrick?.terrain);
        setFilmDate(userTrick?.date);
        setTags(userTrick?.tags);
        setCaption(userTrick?.description);

        // Location
        setLocationDropdown(false);
        setLocationDropdownOptions([]);
        setGooglePlacesSessionToken(uuidv4());
        setInitialLocationDetails();

        // Analyze Tags
        setUnlisted(userTrick?.tags?.includes(UNLISTED_CONST));

        if (userTrick?.clip_type === null) {
            setClipType(ClipType.CLIP);
        } else {
            setClipType(userTrick?.clip_type);
        }
    }, [userTrick]);

    useEffect(() => {
        if (taggedUser && !filmerInfo) {
            console.log("taggedUser", taggedUser);
            if (taggedUser.length === 1) {
                setFilmerInfo({
                    username: taggedUser[0].video_user_tags_username,
                    user_id: taggedUser[0].video_user_tags_user_id
                });
                setFilmerSearchText(taggedUser[0].video_user_tags_username);
            }
        }
    }, [taggedUser]);

    // pause video when open modal
    const currentDate = new Date(new Date().toDateString()).toISOString();

    // adds new item to multiselect

    const addTag = (item: any) => {
        setTags(tags.concat(item));
        // const filtered = options.filter((option: any) => option !== item);
        // setOptions(filtered);
        if (item === UNLISTED_CONST) {
            setUnlisted(true);
        }

        setShowDropdown(false);
    };

    // removes item from multiselect
    const removeTag = (item: any) => {
        const filtered = tags.filter((e: any) => e !== item);
        setTags(filtered);

        if (item === UNLISTED_CONST) {
            setUnlisted(false);
        }
        // // only add tag back to options if it's in the trick name and not user generated
        // if (trickName.split(" ").includes(item)) {
        //   setTagOptions(tagOptions.concat(item));
        // }
    }

    const editTrickHandler = (editedTrick: UserTrick, auth_id: string) => {
        editedTrick.tags = tags;
        editedTrick.terrain = feature;
        editedTrick.sport = sport;
        editedTrick.location = location;
        editedTrick.trick_name = trickName;
        editedTrick.date = filmDate;
        editedTrick.description = caption;
        editedTrick.clip_type = clipType;

        onEditTrickSubmit(editedTrick, auth_id, locationInput, filmerInfo);
        onDismiss();
    }

    const addLocationItem = (locationInput: LocationInput) => {
        setLocationInput(locationInput);
        setLocation(locationInput.main_text);
        setLocationDropdown(false);
    }

    const onSetUnlisted = (checkedValue: boolean) => {
        if (checkedValue) {
            setTags(tags.concat(UNLISTED_CONST));
        } else {
            const filtered = tags.filter((e: any) => e !== UNLISTED_CONST);
            setTags(filtered);
        }
        setUnlisted(checkedValue);
    }

    return (
        <IonPage>
            <IDXModalHeader leftButtonText={'Cancel'} leftButtonAction={() => onDismiss(null, 'cancel')}
                titleText={'Edit Clip'}
                rightButtonText={'Submit'}
                rightButtonAction={() => editTrickHandler(editedTrick, state.user.auth_id)} />
            <IonContent>
                <IonContent className="ion-padding mx-2 space-y-4">
                    <IonInputWithLabel value={trickName} label={"Name"} placeholder={"Name"}
                        parentCallback={setTrickName}></IonInputWithLabel>
                    <div ref={closeDropdown} className="location-container">
                        <IonItem>
                            <IonLabel position="stacked">{"Location"}</IonLabel>
                            <IonInput type="text" placeholder={"Location"} value={location}
                                onIonInput={(e) => setTrickLocation(e.detail.value as string, false)} />
                        </IonItem>
                        {locationDropdown ?
                            <Dropdown list={locationDropdownOptions} addItem={addLocationItem} accessProperty={true}
                                accessPropertyString={'description'}></Dropdown> : null}
                    </div>
                    <div ref={closeFilmerDropdown} className="filmer-container">
                        <IonItem>
                            <IonLabel position="stacked">{"Filmer"}</IonLabel>
                            <IonInput
                                type="text"
                                placeholder={taggedUserLoading ? "Loading..." : "Search for filmer"}
                                value={filmerSearchText}
                                onIonInput={(e) => handleFilmerSearch(e.detail.value)}
                                disabled={taggedUserLoading}
                            />
                        </IonItem>
                        {filmerDropdown && (
                            <Dropdown
                                list={filmerDropdownOptions}
                                addItem={addFilmerItem}
                                accessProperty={true}
                                accessPropertyString={'username'}
                            />
                        )}
                    </div>
                    <EditTrickSelect parentCallback={setClipType} options={clipOptions} value={clipType}
                        label="Clip Type"></EditTrickSelect>
                    <EditTrickSelect parentCallback={setSport} options={sportOptions} value={sport}
                        label="Sport"></EditTrickSelect>
                    {((clipType !== ClipType.EDIT) && (clipType !== ClipType.LINE)) &&
                        <EditTrickSelect parentCallback={setFeature}
                            options={sport === "Other" ? otherFeatures : features} value={feature}
                            label="Feature"></EditTrickSelect>}
                    <TagSelectOnUpload sport={[sport]} feature={[feature]} parentAddTag={addTag}
                        parentRemoveTag={removeTag} currentTags={tags} tagsCombinedToOne={true} />
                    <IonLongInputWithLabel value={caption} label={"Caption"} placeholder={"Caption"}
                        parentCallback={setCaption}></IonLongInputWithLabel>
                    <IonCheckbox className="mx-3" labelPlacement="start"
                        onIonChange={(ev) => onSetUnlisted(ev.detail.checked)} checked={unlisted}>
                        {FOLLOWERS_ONLY}
                    </IonCheckbox>
                </IonContent>
            </IonContent>
        </IonPage>
    );
};

export default EditTrick;