import React from 'react';
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";
import AuthenticationButtons from "../AuthenticationButtons/AuthenticationButtons";
import { useAppState } from "../../../AppListenerProvider";
import { useAuth } from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";
import WelcomeHelp from '../WelcomeHelp/WelcomeHelp';
import { useAnalytics } from '../../../AppAnalyticsProvider';

interface WelcomeSignUpAuthProps {
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    onBack: () => void;
    handleAuthedStep: () => void;
    handleUserPasswordAuthedStep: () => void;
    handlePhoneAuthStep?: () => void;
}

const WelcomeSignUpAuth: React.FC<WelcomeSignUpAuthProps> = ({
    showSkipButton,
    showBackButton,
    title,
    onBack,
    handleAuthedStep,
    handleUserPasswordAuthedStep,
    handlePhoneAuthStep
}) => {
    const { trackEvent } = useAnalytics();
    const { isActive, isNative, platform } = useAppState();
    const { user, isLoading, isAuthenticated, firebaseApp, auth } = useAuth();
    const authService = AuthService({ user, isLoading, isAuthenticated, firebaseApp, auth }, {
        isActive,
        isNative,
        platform
    });

    const handleAuthMethodSelection = (method: string) => {
        trackEvent('login_method_selected', {
            method: method,
            platform: platform,
            is_native: isNative
        });
    };

    return (
        <OnboardingPageWrapper title={title} showBackButton={showBackButton} showSkipButton={showSkipButton}
            onBack={() => {
                trackEvent('login_auth_back', {
                    platform: platform
                });
                onBack();
            }}>
            <AuthenticationButtons
                login={true}
                authService={authService}
                platform={platform}
                handleAuthedStep={() => {
                    trackEvent('login_auth_success', {
                        platform: platform
                    });
                    handleAuthedStep();
                }}
                handlePhoneAuthStep={() => {
                    handleAuthMethodSelection('phone');
                    handlePhoneAuthStep?.();
                }}
                handleUserPasswordAuthedStep={() => {
                    handleAuthMethodSelection('email_password');
                    handleUserPasswordAuthedStep();
                }}
            />
            <WelcomeHelp />
        </OnboardingPageWrapper>
    );
};

export default WelcomeSignUpAuth;